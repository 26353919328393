import { FLOW, ROUTE_NAMES } from "@/util/routes/route-names";

export const FILE_DIRECT_ROUTES = [
  {
    path: "/tax-return-preparation/do-it-yourself",
    name: ROUTE_NAMES.doItYourself,
    component: () => import("@/views/diy/landing.vue"),
    meta: {
      title: "DIY Tax Extension Filing – Meet the October 15th Deadline with Ease",
      description:
        "Don’t miss the October 15th tax extension deadline! File your federal and state taxes easily with expert guidance. Transparent pricing, no hidden fees—get started today!",
      flow: FLOW.diyReturn,
      layout: "full",
      canonical: "tax-return-preparation/do-it-yourself"
    }
  },
  {
    path: "/tax-return-preparation/done-for-you",
    name: ROUTE_NAMES.doneForYou,
    component: () => import("@/views/dfy/landing.vue"),
    meta: {
      title: "Professional Tax Filing Services – Personal & Business Plans",
      description:
        "Let FileDirect’s expert CPAs handle your taxes for you. Choose from personal or business plans, save money, and file with confidence—get started today!",
      layout: "full",
      container: "big",
      flow: FLOW.dfyReturn,
      canonical: "tax-return-preparation/done-for-you"
    }
  },
  {
    path: "/tax-return-preparation/done-for-you-v2",
    name: ROUTE_NAMES.doneForYouV2,
    component: () => import("@/views/dfy/landing-v2.vue"),
    meta: {
      title: "Affordable Tax Return Preparation Services | FileDirect",
      description:
        "Get your taxes done right with FileDirect’s expert tax preparation services. Avoid penalties, meet deadlines, and save money—start today!",
      flow: FLOW.dfyReturn,
      layout: "full",
      canonical: "tax-return-preparation/done-for-you-v2"
    }
  },
  {
    path: "/tax-return-preparation/5-common-mistakes",
    name: ROUTE_NAMES.commonMistakes,
    component: () => import("@/views/common-mistakes.vue"),
    meta: {
      title: "5 Common Tax Filing Mistakes to Avoid – Expert Tips & Tools",
      description:
        "Don’t let common tax filing mistakes cost you. Learn how to file confidently with expert guidance, IRS protection, and year-round support. Get started today!",
      flow: FLOW.diyReturn,
      layout: "full",
      canonical: "tax-return-preparation/5-common-mistakes"
    }
  },
  {
    path: "/tax-extension-personal",
    name: ROUTE_NAMES.taxExtensionPersonal,
    component: () => import("@/views/landing.vue"),
    meta: {
      title: "Tax Deadline Assistance & Extension Services – File by April 15",
      description:
        "File your federal and state taxes or extend your deadline easily. Get expert advice, IRS-approved e-filing, and year-round support. Start today with http://filedirect.net!",
      layout: "full",
      canonical: "tax-extension-personal"
    }
  },
  {
    path: "/faq",
    name: ROUTE_NAMES.faq,
    component: () => import("@/views/faq.vue"),
    meta: {
      title: "Tax Extension & Beneficial Owner Information Report FAQs",
      description:
        "Find answers to FAQs about tax extensions and Beneficial Owner Information Reports (BOIR), including filing requirements, deadlines, and more. Simplify tax season!",
      canonical: "faq"
    }
  }
];
