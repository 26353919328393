/* tslint:disable */
/* eslint-disable */
/**
 * Extensions project backend API
 * Extensions project backend API
 *
 * The version of the OpenAPI document: 4.0
 * Contact: dmitrygavrovsky@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AppControllerBoirForm
 */
export interface AppControllerBoirForm {
    /**
     * 
     * @type {string}
     * @memberof AppControllerBoirForm
     */
    'business_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppControllerBoirForm
     */
    'completion_status'?: AppControllerBoirFormCompletionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AppControllerBoirForm
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppControllerBoirForm
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppControllerBoirForm
     */
    'is_editable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppControllerBoirForm
     */
    'is_expired'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppControllerBoirForm
     */
    'is_locked'?: boolean;
    /**
     * 
     * @type {Array<BoirSubmissionError>}
     * @memberof AppControllerBoirForm
     */
    'last_submission_error'?: Array<BoirSubmissionError>;
    /**
     * 
     * @type {string}
     * @memberof AppControllerBoirForm
     */
    'payment_status'?: AppControllerBoirFormPaymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AppControllerBoirForm
     */
    'submission_status'?: AppControllerBoirFormSubmissionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AppControllerBoirForm
     */
    'updated_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppControllerBoirForm
     */
    'was_ever_submitted'?: boolean;
}

export const AppControllerBoirFormCompletionStatusEnum = {
    Created: 'Created',
    InProgress: 'In Progress',
    AwaitingOwner: 'Awaiting Owner',
    Completed: 'Completed',
    Failed: 'Failed',
    Unknown: 'Unknown'
} as const;

export type AppControllerBoirFormCompletionStatusEnum = typeof AppControllerBoirFormCompletionStatusEnum[keyof typeof AppControllerBoirFormCompletionStatusEnum];
export const AppControllerBoirFormPaymentStatusEnum = {
    NotPaid: 'Not Paid',
    Paid: 'Paid',
    Refunded: 'Refunded',
    Abandoned: 'Abandoned',
    Unknown: 'Unknown'
} as const;

export type AppControllerBoirFormPaymentStatusEnum = typeof AppControllerBoirFormPaymentStatusEnum[keyof typeof AppControllerBoirFormPaymentStatusEnum];
export const AppControllerBoirFormSubmissionStatusEnum = {
    Created: 'Created',
    Initiated: 'Initiated',
    Processing: 'Processing',
    Accepted: 'Accepted',
    Rejected: 'Rejected',
    Failed: 'Failed',
    Timeout: 'Timeout',
    Unknown: 'Unknown'
} as const;

export type AppControllerBoirFormSubmissionStatusEnum = typeof AppControllerBoirFormSubmissionStatusEnum[keyof typeof AppControllerBoirFormSubmissionStatusEnum];

/**
 * 
 * @export
 * @interface BoirAvailableBoirFormCountResponse
 */
export interface BoirAvailableBoirFormCountResponse {
    /**
     * 
     * @type {number}
     * @memberof BoirAvailableBoirFormCountResponse
     */
    'available_form_count'?: number;
}
/**
 * 
 * @export
 * @interface BoirSubmissionDataError
 */
export interface BoirSubmissionDataError {
    /**
     * 
     * @type {string}
     * @memberof BoirSubmissionDataError
     */
    'error_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoirSubmissionDataError
     */
    'error_context_text'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoirSubmissionDataError
     */
    'error_element_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoirSubmissionDataError
     */
    'error_level_text'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoirSubmissionDataError
     */
    'error_text'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoirSubmissionDataError
     */
    'error_type_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoirSubmissionDataError
     */
    'recommended_response'?: string;
}
/**
 * 
 * @export
 * @interface BoirSubmissionError
 */
export interface BoirSubmissionError {
    /**
     * 
     * @type {BoirSubmissionDataError}
     * @memberof BoirSubmissionError
     */
    'data_error'?: BoirSubmissionDataError;
    /**
     * 
     * @type {BoirSubmissionInternalError}
     * @memberof BoirSubmissionError
     */
    'internal_error'?: BoirSubmissionInternalError;
    /**
     * 
     * @type {BoirSubmissionErrorType}
     * @memberof BoirSubmissionError
     */
    'type'?: BoirSubmissionErrorType;
    /**
     * 
     * @type {BoirSubmissionValidationError}
     * @memberof BoirSubmissionError
     */
    'validation_error'?: BoirSubmissionValidationError;
}


/**
 * 
 * @export
 * @enum {number}
 */

export const BoirSubmissionErrorType = {
    SubmissionErrorType_SUBMISSION_ERROR_TYPE_UNKNOWN: 0,
    SubmissionErrorType_SUBMISSION_ERROR_TYPE_FAILED_XSD: 1,
    SubmissionErrorType_SUBMISSION_ERROR_TYPE_INVALID_XML: 2,
    SubmissionErrorType_SUBMISSION_ERROR_TYPE_INVALID_DATA: 3,
    SubmissionErrorType_SUBMISSION_ERROR_TYPE_INTERNAL: 4
} as const;

export type BoirSubmissionErrorType = typeof BoirSubmissionErrorType[keyof typeof BoirSubmissionErrorType];


/**
 * 
 * @export
 * @interface BoirSubmissionInternalError
 */
export interface BoirSubmissionInternalError {
    /**
     * 
     * @type {string}
     * @memberof BoirSubmissionInternalError
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface BoirSubmissionValidationError
 */
export interface BoirSubmissionValidationError {
    /**
     * 
     * @type {string}
     * @memberof BoirSubmissionValidationError
     */
    'error_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoirSubmissionValidationError
     */
    'location'?: string;
}
/**
 * 
 * @export
 * @interface ColumntaxClientHint
 */
export interface ColumntaxClientHint {
    /**
     * 
     * @type {string}
     * @memberof ColumntaxClientHint
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ColumntaxClientHint
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ColumntaxClientHint
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ColumntaxClientHint
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface ColumntaxConsentRequest
 */
export interface ColumntaxConsentRequest {
    /**
     * ConsentValue is the value of the consent to disclose. True if user EXPLICITLY agrees to disclose their information False if user EXPLICITLY does not agree to disclose their information
     * @type {boolean}
     * @memberof ColumntaxConsentRequest
     */
    'consent_value'?: boolean;
    /**
     * DeviceID is the device ID of the user
     * @type {string}
     * @memberof ColumntaxConsentRequest
     */
    'device_id': string;
    /**
     * Signature is the signature of the taxpayer
     * @type {string}
     * @memberof ColumntaxConsentRequest
     */
    'signature'?: string;
    /**
     * SignedAt is the time the consent was signed
     * @type {string}
     * @memberof ColumntaxConsentRequest
     */
    'signed_at'?: string;
    /**
     * TaxpayerName is the name of the taxpayer
     * @type {string}
     * @memberof ColumntaxConsentRequest
     */
    'taxpayer_name'?: string;
}
/**
 * 
 * @export
 * @interface ColumntaxConsentResponse
 */
export interface ColumntaxConsentResponse {
    /**
     * 
     * @type {string}
     * @memberof ColumntaxConsentResponse
     */
    'consent_id': string;
}
/**
 * 
 * @export
 * @interface ColumntaxDisclosureParty
 */
export interface ColumntaxDisclosureParty {
    /**
     * IsForeign indicates if the company is foreign. True - company is foreign, false - company is domestic.
     * @type {boolean}
     * @memberof ColumntaxDisclosureParty
     */
    'is_foreign'?: boolean;
    /**
     * Name is the name of the company.
     * @type {string}
     * @memberof ColumntaxDisclosureParty
     */
    'name'?: string;
}
/**
 * Request structure for starting a new column tax filing. This request creates
 * @export
 * @interface ColumntaxNewColumnTaxFilingRequest
 */
export interface ColumntaxNewColumnTaxFilingRequest {
    /**
     * ConsentToDiscloseID is the ID of the consent to disclose. Please check settings if that parameter is required for your account. If user didn\'t interact with the consent page, pass null. If page is shown frontend needs to issue consent call with true/false value for consent value.
     * @type {string}
     * @memberof ColumntaxNewColumnTaxFilingRequest
     */
    'consent_to_disclose_id'?: string;
    /**
     * ConsentToUseID is the ID of the consent to use. Please check settings if that parameter is required for your account. If user didn\'t interact with the consent page, pass null. If page is shown frontend needs to issue consent call with true/false value for consent value.
     * @type {string}
     * @memberof ColumntaxNewColumnTaxFilingRequest
     */
    'consent_to_use_id'?: string;
    /**
     * Email of the taxpayer. Try to use the same as user account email if it\'s the first filing for this tax year.
     * @type {string}
     * @memberof ColumntaxNewColumnTaxFilingRequest
     */
    'email': string;
    /**
     * First name of the taxpayer. Try to use the same as user account first name if it\'s the first filing for this tax year.
     * @type {string}
     * @memberof ColumntaxNewColumnTaxFilingRequest
     */
    'first_name': string;
    /**
     * Last name of the taxpayer. Try to use the same as user account last name if it\'s the first filing for this tax year.
     * @type {string}
     * @memberof ColumntaxNewColumnTaxFilingRequest
     */
    'last_name': string;
    /**
     * Phone number is e164 format i.e. +11231231231
     * @type {string}
     * @memberof ColumntaxNewColumnTaxFilingRequest
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface ColumntaxReturnURLResponse
 */
export interface ColumntaxReturnURLResponse {
    /**
     * 
     * @type {string}
     * @memberof ColumntaxReturnURLResponse
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface ColumntaxSettings
 */
export interface ColumntaxSettings {
    /**
     * ClientHint - is suggested value for the client first,last name and email for the new filing creation.
     * @type {ColumntaxClientHint}
     * @memberof ColumntaxSettings
     */
    'client_hint'?: ColumntaxClientHint;
    /**
     * DisclosureToCompanies is a list of companies to which user provides his disclosure to.
     * @type {Array<ColumntaxDisclosureParty>}
     * @memberof ColumntaxSettings
     */
    'disclosure_to_companies'?: Array<ColumntaxDisclosureParty>;
    /**
     * IsConsentToDisclosureRequired indicates if user should read and sign `Consent To Disclosure` agreement.
     * @type {boolean}
     * @memberof ColumntaxSettings
     */
    'is_consent_to_disclosure_required'?: boolean;
    /**
     * IsConsentToUseRequired indicates if user should read and sign `Consent To Use` agreement.
     * @type {boolean}
     * @memberof ColumntaxSettings
     */
    'is_consent_to_use_required'?: boolean;
    /**
     * IsNewAvailable indicates if new user is eligible to create new filing. If true, new filings are available. If false user should purchase either a filesmart subscription either a DIY filing.
     * @type {boolean}
     * @memberof ColumntaxSettings
     */
    'is_new_available'?: boolean;
}
/**
 * 
 * @export
 * @interface ColumntaxTaxReturn
 */
export interface ColumntaxTaxReturn {
    /**
     * 
     * @type {string}
     * @memberof ColumntaxTaxReturn
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ColumntaxTaxReturn
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ColumntaxTaxReturn
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ColumntaxTaxReturn
     */
    'is_ct_created': boolean;
    /**
     * 
     * @type {string}
     * @memberof ColumntaxTaxReturn
     */
    'jurisdiction': string;
    /**
     * 
     * @type {string}
     * @memberof ColumntaxTaxReturn
     */
    'jurisdiction_type': ColumntaxTaxReturnJurisdictionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ColumntaxTaxReturn
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof ColumntaxTaxReturn
     */
    'redirect_url'?: string;
    /**
     * 
     * @type {ModelsColumntaxSubmissionStatus}
     * @memberof ColumntaxTaxReturn
     */
    'submission_status': ModelsColumntaxSubmissionStatus;
    /**
     * 
     * @type {number}
     * @memberof ColumntaxTaxReturn
     */
    'tax_year': number;
    /**
     * 
     * @type {string}
     * @memberof ColumntaxTaxReturn
     */
    'updated_at': string;
    /**
     * 
     * @type {ModelsColumntaxUserStatus}
     * @memberof ColumntaxTaxReturn
     */
    'user_status': ModelsColumntaxUserStatus;
}

export const ColumntaxTaxReturnJurisdictionTypeEnum = {
    Fed: 'fed',
    State: 'state'
} as const;

export type ColumntaxTaxReturnJurisdictionTypeEnum = typeof ColumntaxTaxReturnJurisdictionTypeEnum[keyof typeof ColumntaxTaxReturnJurisdictionTypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const CommonAccessScopes = {
    AccessScopesConsent: 'consent',
    AccessScopesClient: 'client',
    AccessScopesTriage: 'triage',
    AccessScopesAdmin: 'admin'
} as const;

export type CommonAccessScopes = typeof CommonAccessScopes[keyof typeof CommonAccessScopes];


/**
 * 
 * @export
 * @enum {string}
 */

export const CommonAuthMode = {
    AuthModePassword: 'password',
    AuthModeFacebook: 'facebook',
    AuthModeGoogle: 'google'
} as const;

export type CommonAuthMode = typeof CommonAuthMode[keyof typeof CommonAuthMode];


/**
 * 
 * @export
 * @enum {string}
 */

export const CommonBrand = {
    BrandEFileTaxNet: 'e.file-tax.net',
    BrandStartEfiletaxOnline: 'start.efiletax.online',
    BrandFileDirectNet: 'filedirect.net',
    BrandFilesmartTax: 'filesmart.tax'
} as const;

export type CommonBrand = typeof CommonBrand[keyof typeof CommonBrand];


/**
 * 
 * @export
 * @enum {string}
 */

export const CommonBusinessFormName = {
    BusinessFormNameForm1040: 'Form1040',
    BusinessFormNameForm1065: 'Form1065',
    BusinessFormNameForm1120: 'Form1120',
    BusinessFormNameForm1120S: 'Form1120S',
    BusinessFormNameForm1120F: 'Form1120F',
    BusinessFormNameForm1041: 'Form1041'
} as const;

export type CommonBusinessFormName = typeof CommonBusinessFormName[keyof typeof CommonBusinessFormName];


/**
 * 
 * @export
 * @enum {string}
 */

export const CommonBusinessFormShortTaxYearReason = {
    BusinessFormShortTaxYearReasonNone: 'None',
    BusinessFormShortTaxYearReasonInitialReturn: 'InitialReturn',
    BusinessFormShortTaxYearReasonFinalReturn: 'FinalReturn',
    BusinessFormShortTaxYearReasonChangeInAccPeriod: 'ChangeInAccPeriod',
    BusinessFormShortTaxYearReasonConsolidateReturnTBF: 'ConsolidateReturnTBF',
    BusinessFormShortTaxYearReasonOther: 'Other'
} as const;

export type CommonBusinessFormShortTaxYearReason = typeof CommonBusinessFormShortTaxYearReason[keyof typeof CommonBusinessFormShortTaxYearReason];


/**
 * 
 * @export
 * @enum {string}
 */

export const CommonBusinessType = {
    BusinessTypeSingleMemberLLC: 'SingleMemberLLC',
    BusinessTypePartnershipOrMultiMemberLLC: 'PartnershipOrMultiMemberLLC',
    BusinessTypeCCorporation: 'CCorporation',
    BusinessTypeSCorporation: 'SCorporation',
    BusinessTypeForeignCorp: 'ForeignCorp',
    BusinessTypeTrustOrEstate: 'TrustOrEstate'
} as const;

export type CommonBusinessType = typeof CommonBusinessType[keyof typeof CommonBusinessType];


/**
 * 
 * @export
 * @enum {string}
 */

export const CommonFormFilingType = {
    FormFilingTypeSingle: 'single',
    FormFilingTypeJointly: 'jointly'
} as const;

export type CommonFormFilingType = typeof CommonFormFilingType[keyof typeof CommonFormFilingType];


/**
 * 
 * @export
 * @enum {string}
 */

export const CommonFormStatus = {
    FormStatusNew: 'new',
    FormStatusStarted: 'started',
    FormStatusComplete: 'complete',
    FormStatusQueued: 'queued',
    FormStatusPreparing: 'preparing',
    FormStatusAwaiting: 'awaiting',
    FormStatusAckReady: 'ack-ready'
} as const;

export type CommonFormStatus = typeof CommonFormStatus[keyof typeof CommonFormStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const CommonFormType = {
    FormTypePersonal: 'personal',
    FormTypeBusiness: 'business'
} as const;

export type CommonFormType = typeof CommonFormType[keyof typeof CommonFormType];


/**
 * 
 * @export
 * @enum {string}
 */

export const CommonOfferPage = {
    OfferPagePreOffer: 'pre-offer',
    OfferPageMainOffer: 'main-offer',
    OfferPagePrePurchase: 'pre-purchase',
    OfferPageCheckout: 'checkout',
    OfferPagePostUpsell1: 'post-upsell-1',
    OfferPagePostUpsell2: 'post-upsell-2',
    OfferPageConfirmation: 'confirmation'
} as const;

export type CommonOfferPage = typeof CommonOfferPage[keyof typeof CommonOfferPage];


/**
 * 
 * @export
 * @enum {string}
 */

export const CommonPaymentStatus = {
    PaymentStatusNone: 'none',
    PaymentStatusFailed: 'failed',
    PaymentStatusSuccess: 'success',
    PaymentStatusRefund: 'refund'
} as const;

export type CommonPaymentStatus = typeof CommonPaymentStatus[keyof typeof CommonPaymentStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const CommonProductFocus = {
    ProductFocusExtension: 'extension',
    ProductFocusReturn: 'return',
    ProductFocusDFY: 'dfy',
    ProductFocusNone: 'none'
} as const;

export type CommonProductFocus = typeof CommonProductFocus[keyof typeof CommonProductFocus];


/**
 * 
 * @export
 * @enum {string}
 */

export const CommonProductTypes = {
    ProductTypesPersonal: 'personal',
    ProductTypesBusiness: 'business',
    ProductTypesShield: 'shield',
    ProductTypesPriority: 'priority',
    ProductTypesAutoExtend: 'auto-extend',
    ProductTypesBundle: 'bundle',
    ProductTypesPrint: 'print',
    ProductTypesDfyReturn: 'dfy-return',
    ProductTypesDiyReturn: 'diy-return',
    ProductTypesCourse: 'course',
    ProductTypesCoupon: 'coupon',
    ProductTypeSupport: 'support',
    ProductTypeReport: 'report',
    ProductTypeResourceVault: 'vault',
    ProductTypesBoir: 'boir'
} as const;

export type CommonProductTypes = typeof CommonProductTypes[keyof typeof CommonProductTypes];


/**
 * 
 * @export
 * @enum {string}
 */

export const CommonSubmissionStatus = {
    SubmissionStatusNone: 'none',
    SubmissionStatusQueued: 'queued',
    SubmissionStatusSubmitted: 'submitted',
    SubmissionStatusAccepted: 'accepted',
    SubmissionStatusSubmissionFailure: 'submission-failure',
    SubmissionStatusAckRecoverableFailure: 'ack-recoverable-failure',
    SubmissionStatusAckNonrecoverableFailure: 'ack-nonrecoverable-failure',
    SubmissionStatusRetrying: 'retrying',
    SubmissionStatusSuccess: 'success'
} as const;

export type CommonSubmissionStatus = typeof CommonSubmissionStatus[keyof typeof CommonSubmissionStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const CommonSubscriptionStatus = {
    SubscriptionStatusYes: 'yes',
    SubscriptionStatusNo: 'no',
    SubscriptionStatusCancelled: 'cancelled',
    SubscriptionStatusPaymentFailed: 'payment-failed',
    SubscriptionStatusExpired: 'expired',
    SubscriptionStatusRefunded: 'refunded',
    SubscriptionStatusMatured: 'matured'
} as const;

export type CommonSubscriptionStatus = typeof CommonSubscriptionStatus[keyof typeof CommonSubscriptionStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const CommonTaxPlannerEvent = {
    TaxPlannerEventNoneAction: 'none',
    TaxPlannerEventStep1Reached: 'step1-reached',
    TaxPlannerEventStep2Reached: 'step2-reached',
    TaxPlannerEventStep3Reached: 'step3-reached',
    TaxPlannerEventChecklistPrinted: 'checklist-printed',
    TaxPlannerEventFileReturn: 'return',
    TaxPlannerEventDiyReturn: 'diy-return',
    TaxPlannerEventDfyReturn: 'dfy-return'
} as const;

export type CommonTaxPlannerEvent = typeof CommonTaxPlannerEvent[keyof typeof CommonTaxPlannerEvent];


/**
 * 
 * @export
 * @interface ControllerErrorResponse
 */
export interface ControllerErrorResponse {
    /**
     * 
     * @type {number}
     * @memberof ControllerErrorResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ControllerErrorResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ControllerErrorResponse
     */
    'msg'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DfyEngagementLetterStatus = {
    EngagementLetterStatusCompleted: 'completed',
    EngagementLetterStatusIncomplete: 'incomplete'
} as const;

export type DfyEngagementLetterStatus = typeof DfyEngagementLetterStatus[keyof typeof DfyEngagementLetterStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const DfyPaymentStatus = {
    PaymentStatusUnpaid: 'unpaid',
    PaymentStatusPartial: 'partial',
    PaymentStatusPaid: 'paid'
} as const;

export type DfyPaymentStatus = typeof DfyPaymentStatus[keyof typeof DfyPaymentStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const DfyProcessStatus = {
    ProcessStatusUnstarted: 'unstarted',
    ProcessStatusInProgress: 'in-progress',
    ProcessStatusCompleted: 'completed'
} as const;

export type DfyProcessStatus = typeof DfyProcessStatus[keyof typeof DfyProcessStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const DfyReturnType = {
    ReturnTypePersonal: 'personal',
    ReturnTypeBusiness: 'business'
} as const;

export type DfyReturnType = typeof DfyReturnType[keyof typeof DfyReturnType];


/**
 * 
 * @export
 * @interface DfySignReturnRequest
 */
export interface DfySignReturnRequest {
    /**
     * 
     * @type {string}
     * @memberof DfySignReturnRequest
     */
    'business_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DfySignReturnRequest
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof DfySignReturnRequest
     */
    'ein': string;
    /**
     * 
     * @type {string}
     * @memberof DfySignReturnRequest
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DfySignReturnRequest
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DfySignReturnRequest
     */
    'signature': string;
}
/**
 * 
 * @export
 * @interface DfyTaxReturn
 */
export interface DfyTaxReturn {
    /**
     * Business name
     * @type {string}
     * @memberof DfyTaxReturn
     */
    'business_name'?: string;
    /**
     * Creation timestamp required: true
     * @type {string}
     * @memberof DfyTaxReturn
     */
    'created_at': string;
    /**
     * First name
     * @type {string}
     * @memberof DfyTaxReturn
     */
    'first_name'?: string;
    /**
     * ID of the tax return record required: true
     * @type {string}
     * @memberof DfyTaxReturn
     */
    'id': string;
    /**
     * Last name
     * @type {string}
     * @memberof DfyTaxReturn
     */
    'last_name'?: string;
    /**
     * enum: completed,incomplete
     * @type {DfyEngagementLetterStatus}
     * @memberof DfyTaxReturn
     */
    'letter_status': DfyEngagementLetterStatus;
    /**
     * Payment status required: true enum: unpaid,partial,paid
     * @type {DfyPaymentStatus}
     * @memberof DfyTaxReturn
     */
    'payment_status': DfyPaymentStatus;
    /**
     * Planning completed flag required: true
     * @type {boolean}
     * @memberof DfyTaxReturn
     */
    'planning_completed': boolean;
    /**
     * Product SKUs associated with the tax return
     * @type {Array<string>}
     * @memberof DfyTaxReturn
     */
    'product_skus'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DfyTaxReturn
     */
    'purchase_ids': Array<string>;
    /**
     * Return type required: true enum: personal,business
     * @type {DfyReturnType}
     * @memberof DfyTaxReturn
     */
    'return_type': DfyReturnType;
    /**
     * Process status required: true enum: unstarted,in-progress,completed
     * @type {DfyProcessStatus}
     * @memberof DfyTaxReturn
     */
    'status': DfyProcessStatus;
    /**
     * Tax year required: true
     * @type {number}
     * @memberof DfyTaxReturn
     */
    'tax_year': number;
    /**
     * Taxpayer identification number
     * @type {string}
     * @memberof DfyTaxReturn
     */
    'taxpayer_identification_number'?: string;
    /**
     * Time signed
     * @type {string}
     * @memberof DfyTaxReturn
     */
    'time_signed'?: string;
    /**
     * Update timestamp required: true
     * @type {string}
     * @memberof DfyTaxReturn
     */
    'updated_at': string;
}


/**
 * 
 * @export
 * @interface DtoAckError
 */
export interface DtoAckError {
    /**
     * 
     * @type {string}
     * @memberof DtoAckError
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoAckError
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoAckError
     */
    'irs_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoAckError
     */
    'rule'?: string;
}
/**
 * Physical address information
 * @export
 * @interface DtoBoirAddress
 */
export interface DtoBoirAddress {
    /**
     * 
     * @type {string}
     * @memberof DtoBoirAddress
     */
    'address_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirAddress
     */
    'address_2'?: string;
    /**
     * 
     * @type {DtoBoirAddressType}
     * @memberof DtoBoirAddress
     */
    'address_type'?: DtoBoirAddressType;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirAddress
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirAddress
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirAddress
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirAddress
     */
    'zip'?: string;
}


/**
 * Types of physical addresses
 * @export
 * @enum {string}
 */

export const DtoBoirAddressType = {
    BoirAddressTypeUndefined: 'ADDRESS_TYPE_UNDEFINED',
    BoirAddressTypeBusiness: 'ADDRESS_TYPE_BUSINESS',
    BoirAddressTypeResidential: 'ADDRESS_TYPE_RESIDENTIAL'
} as const;

export type DtoBoirAddressType = typeof DtoBoirAddressType[keyof typeof DtoBoirAddressType];


/**
 * Information about a form applicant
 * @export
 * @interface DtoBoirApplicant
 */
export interface DtoBoirApplicant {
    /**
     * 
     * @type {DtoBoirApplicantType}
     * @memberof DtoBoirApplicant
     */
    'applicant_type'?: DtoBoirApplicantType;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirApplicant
     */
    'beneficial_owner_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirApplicant
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirApplicant
     */
    'form_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoBoirApplicant
     */
    'is_applicant_beneficial_owner'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoBoirApplicant
     */
    'is_document_attached'?: boolean;
    /**
     * 
     * @type {DtoBoirPersonDetails}
     * @memberof DtoBoirApplicant
     */
    'person_details'?: DtoBoirPersonDetails;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirApplicant
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirApplicant
     */
    'user_id'?: string;
}


/**
 * Types of relationships between applicants and the business
 * @export
 * @enum {string}
 */

export const DtoBoirApplicantType = {
    BoirApplicantTypeUndefined: 'APPLICANT_TYPE_UNDEFINED',
    BoirApplicantTypeOwnerAndApplicant: 'APPLICANT_TYPE_OWNER_AND_APPLICANT',
    BoirApplicantTypeOwnerNotApplicant: 'APPLICANT_TYPE_OWNER_NOT_APPLICANT',
    BoirApplicantTypeNotOwnerApplicant: 'APPLICANT_TYPE_NOT_OWNER_APPLICANT',
    BoirApplicantTypeAgent: 'APPLICANT_TYPE_AGENT'
} as const;

export type DtoBoirApplicantType = typeof DtoBoirApplicantType[keyof typeof DtoBoirApplicantType];


/**
 * Information about a beneficial owner
 * @export
 * @interface DtoBoirBeneficialOwner
 */
export interface DtoBoirBeneficialOwner {
    /**
     * 
     * @type {string}
     * @memberof DtoBoirBeneficialOwner
     */
    'beneficial_owner_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirBeneficialOwner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirBeneficialOwner
     */
    'form_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoBoirBeneficialOwner
     */
    'is_document_attached'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoBoirBeneficialOwner
     */
    'is_minor_or_child'?: boolean;
    /**
     * 
     * @type {DtoBoirPersonDetails}
     * @memberof DtoBoirBeneficialOwner
     */
    'person_details'?: DtoBoirPersonDetails;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirBeneficialOwner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirBeneficialOwner
     */
    'user_id'?: string;
}
/**
 * Possible statuses for form completion
 * @export
 * @enum {string}
 */

export const DtoBoirCompletionStatus = {
    BoirCompletionStatusUndefined: 'COMPLETION_STATUS_UNDEFINED',
    BoirCompletionStatusCreated: 'COMPLETION_STATUS_CREATED',
    BoirCompletionStatusInProgress: 'COMPLETION_STATUS_IN_PROGRESS',
    BoirCompletionStatusAwaitingOwner: 'COMPLETION_STATUS_AWAITING_OWNER',
    BoirCompletionStatusCompleted: 'COMPLETION_STATUS_COMPLETED',
    BoirCompletionStatusFailed: 'COMPLETION_STATUS_FAILED'
} as const;

export type DtoBoirCompletionStatus = typeof DtoBoirCompletionStatus[keyof typeof DtoBoirCompletionStatus];


/**
 * Details about data-related errors
 * @export
 * @interface DtoBoirDataError
 */
export interface DtoBoirDataError {
    /**
     * 
     * @type {string}
     * @memberof DtoBoirDataError
     */
    'error_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirDataError
     */
    'error_context_text'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirDataError
     */
    'error_element_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirDataError
     */
    'error_level_text'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirDataError
     */
    'error_text'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirDataError
     */
    'error_type_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirDataError
     */
    'recommended_response'?: string;
}
/**
 * Information about a form filer
 * @export
 * @interface DtoBoirFiler
 */
export interface DtoBoirFiler {
    /**
     * 
     * @type {string}
     * @memberof DtoBoirFiler
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirFiler
     */
    'form_id'?: string;
    /**
     * 
     * @type {DtoBoirPersonDetails}
     * @memberof DtoBoirFiler
     */
    'person_details'?: DtoBoirPersonDetails;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirFiler
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirFiler
     */
    'user_id'?: string;
}
/**
 * Types of foreign tax identification numbers
 * @export
 * @enum {string}
 */

export const DtoBoirForeignTaxIDType = {
    BoirForeignTaxIDTypeUndefined: 'FOREIGN_TAX_ID_TYPE_UNDEFINED',
    BoirForeignTaxIDTypeUSTaxIDEIN: 'FOREIGN_TAX_ID_TYPE_US_TAX_ID_EIN',
    BoirForeignTaxIDTypeForeignTaxID: 'FOREIGN_TAX_ID_TYPE_FOREIGN_TAX_ID'
} as const;

export type DtoBoirForeignTaxIDType = typeof DtoBoirForeignTaxIDType[keyof typeof DtoBoirForeignTaxIDType];


/**
 * Detailed information about a BOIR form
 * @export
 * @interface DtoBoirForm
 */
export interface DtoBoirForm {
    /**
     * 
     * @type {DtoBoirAddress}
     * @memberof DtoBoirForm
     */
    'biz_address'?: DtoBoirAddress;
    /**
     * 
     * @type {Array<string>}
     * @memberof DtoBoirForm
     */
    'business_alt_names'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirForm
     */
    'business_name'?: string;
    /**
     * 
     * @type {DtoBoirCompletionStatus}
     * @memberof DtoBoirForm
     */
    'completion_status'?: DtoBoirCompletionStatus;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirForm
     */
    'country_formed'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirForm
     */
    'country_tax_id_issued'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirForm
     */
    'created_at'?: string;
    /**
     * 
     * @type {DtoBoirTaxIDType}
     * @memberof DtoBoirForm
     */
    'domestic_tax_id_type'?: DtoBoirTaxIDType;
    /**
     * 
     * @type {Array<DtoBoirSubmissionError>}
     * @memberof DtoBoirForm
     */
    'errors'?: Array<DtoBoirSubmissionError>;
    /**
     * 
     * @type {DtoBoirForeignTaxIDType}
     * @memberof DtoBoirForm
     */
    'foreign_tax_id_type'?: DtoBoirForeignTaxIDType;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirForm
     */
    'form_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirForm
     */
    'intl_jurisdiction_formed'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoBoirForm
     */
    'is_applicant_beneficial_owner'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoBoirForm
     */
    'is_created_bf_jan2024'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoBoirForm
     */
    'is_editable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoBoirForm
     */
    'is_expired'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoBoirForm
     */
    'is_foreign_pooled_investment_vehicle'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoBoirForm
     */
    'is_locked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoBoirForm
     */
    'is_us_created_based'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DtoBoirForm
     */
    'last_step'?: number;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof DtoBoirForm
     */
    'metadata'?: { [key: string]: any; };
    /**
     * 
     * @type {DtoBoirPaymentStatus}
     * @memberof DtoBoirForm
     */
    'payment_status'?: DtoBoirPaymentStatus;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirForm
     */
    'state_formed'?: string;
    /**
     * 
     * @type {DtoBoirSubmissionStatus}
     * @memberof DtoBoirForm
     */
    'submission_status'?: DtoBoirSubmissionStatus;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirForm
     */
    'tax_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirForm
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirForm
     */
    'user_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoBoirForm
     */
    'was_ever_submitted'?: boolean;
}


/**
 * Details about internal system errors
 * @export
 * @interface DtoBoirInternalError
 */
export interface DtoBoirInternalError {
    /**
     * 
     * @type {string}
     * @memberof DtoBoirInternalError
     */
    'error_message'?: string;
}
/**
 * Possible statuses for form payments
 * @export
 * @enum {string}
 */

export const DtoBoirPaymentStatus = {
    BoirPaymentStatusUndefined: 'PAYMENT_STATUS_UNDEFINED',
    BoirPaymentStatusNone: 'PAYMENT_STATUS_NONE',
    BoirPaymentStatusFailed: 'PAYMENT_STATUS_FAILED',
    BoirPaymentStatusSuccess: 'PAYMENT_STATUS_SUCCESS',
    BoirPaymentStatusRefund: 'PAYMENT_STATUS_REFUND',
    BoirPaymentStatusAbandoned: 'PAYMENT_STATUS_ABANDONED'
} as const;

export type DtoBoirPaymentStatus = typeof DtoBoirPaymentStatus[keyof typeof DtoBoirPaymentStatus];


/**
 * Personal information details
 * @export
 * @interface DtoBoirPersonDetails
 */
export interface DtoBoirPersonDetails {
    /**
     * 
     * @type {DtoBoirAddress}
     * @memberof DtoBoirPersonDetails
     */
    'address'?: DtoBoirAddress;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirPersonDetails
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirPersonDetails
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirPersonDetails
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirPersonDetails
     */
    'middle_name'?: string;
}
/**
 * Record of a form submission
 * @export
 * @interface DtoBoirSubmission
 */
export interface DtoBoirSubmission {
    /**
     * 
     * @type {string}
     * @memberof DtoBoirSubmission
     */
    'created_at'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof DtoBoirSubmission
     */
    'errors'?: { [key: string]: any; };
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof DtoBoirSubmission
     */
    'fincen_response'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof DtoBoirSubmission
     */
    'form_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirSubmission
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirSubmission
     */
    'process_id'?: string;
    /**
     * 
     * @type {DtoBoirSubmissionStatus}
     * @memberof DtoBoirSubmission
     */
    'submission_status'?: DtoBoirSubmissionStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof DtoBoirSubmission
     */
    'upload_ids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirSubmission
     */
    'xml_blob'?: string;
}


/**
 * Detailed information about a submission error
 * @export
 * @interface DtoBoirSubmissionError
 */
export interface DtoBoirSubmissionError {
    /**
     * 
     * @type {DtoBoirDataError}
     * @memberof DtoBoirSubmissionError
     */
    'data_error'?: DtoBoirDataError;
    /**
     * 
     * @type {DtoBoirInternalError}
     * @memberof DtoBoirSubmissionError
     */
    'internal_error'?: DtoBoirInternalError;
    /**
     * 
     * @type {DtoBoirSubmissionErrorType}
     * @memberof DtoBoirSubmissionError
     */
    'type'?: DtoBoirSubmissionErrorType;
    /**
     * 
     * @type {DtoBoirValidationError}
     * @memberof DtoBoirSubmissionError
     */
    'validation_error'?: DtoBoirValidationError;
}


/**
 * Types of errors that can occur during submission
 * @export
 * @enum {string}
 */

export const DtoBoirSubmissionErrorType = {
    BoirSubmissionErrorTypeUnknown: 'SUBMISSION_ERROR_TYPE_UNKNOWN',
    BoirSubmissionErrorTypeFailedXSD: 'SUBMISSION_ERROR_TYPE_FAILED_XSD',
    BoirSubmissionErrorTypeInvalidXML: 'SUBMISSION_ERROR_TYPE_INVALID_XML',
    BoirSubmissionErrorTypeInvalidData: 'SUBMISSION_ERROR_TYPE_INVALID_DATA',
    BoirSubmissionErrorTypeInternal: 'SUBMISSION_ERROR_TYPE_INTERNAL'
} as const;

export type DtoBoirSubmissionErrorType = typeof DtoBoirSubmissionErrorType[keyof typeof DtoBoirSubmissionErrorType];


/**
 * Possible statuses for form submissions
 * @export
 * @enum {string}
 */

export const DtoBoirSubmissionStatus = {
    BoirSubmissionStatusUndefined: 'SUBMISSION_STATUS_UNDEFINED',
    BoirSubmissionStatusInitiated: 'SUBMISSION_STATUS_INITIATED',
    BoirSubmissionStatusProcessing: 'SUBMISSION_STATUS_PROCESSING',
    BoirSubmissionStatusAccepted: 'SUBMISSION_STATUS_ACCEPTED',
    BoirSubmissionStatusRejected: 'SUBMISSION_STATUS_REJECTED',
    BoirSubmissionStatusFailed: 'SUBMISSION_STATUS_FAILED',
    BoirSubmissionStatusNone: 'SUBMISSION_STATUS_NONE',
    BoirSubmissionStatusTimeout: 'SUBMISSION_STATUS_TIMEOUT'
} as const;

export type DtoBoirSubmissionStatus = typeof DtoBoirSubmissionStatus[keyof typeof DtoBoirSubmissionStatus];


/**
 * Types of tax identification numbers
 * @export
 * @enum {string}
 */

export const DtoBoirTaxIDType = {
    BoirTaxIDTypeUndefined: 'TAX_ID_TYPE_UNDEFINED',
    BoirTaxIDTypeEIN: 'TAX_ID_TYPE_EIN',
    BoirTaxIDTypeSSNITIN: 'TAX_ID_TYPE_SSN_ITIN'
} as const;

export type DtoBoirTaxIDType = typeof DtoBoirTaxIDType[keyof typeof DtoBoirTaxIDType];


/**
 * Details about XML validation errors
 * @export
 * @interface DtoBoirValidationError
 */
export interface DtoBoirValidationError {
    /**
     * 
     * @type {string}
     * @memberof DtoBoirValidationError
     */
    'error_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoBoirValidationError
     */
    'location'?: string;
}
/**
 * 
 * @export
 * @interface DtoBusinessCodeResponse
 */
export interface DtoBusinessCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof DtoBusinessCodeResponse
     */
    'business_code': string;
}
/**
 * 
 * @export
 * @interface DtoCart
 */
export interface DtoCart {
    /**
     * 
     * @type {string}
     * @memberof DtoCart
     */
    'invoice_id': string;
    /**
     * 
     * @type {Array<DtoCartItem>}
     * @memberof DtoCart
     */
    'purchases': Array<DtoCartItem>;
}
/**
 * 
 * @export
 * @interface DtoCartItem
 */
export interface DtoCartItem {
    /**
     * 
     * @type {string}
     * @memberof DtoCartItem
     */
    'purchase_id': string;
    /**
     * 
     * @type {string}
     * @memberof DtoCartItem
     */
    'sku': string;
}
/**
 * 
 * @export
 * @interface DtoChangeEmailIntentRequest
 */
export interface DtoChangeEmailIntentRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoChangeEmailIntentRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof DtoChangeEmailIntentRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface DtoChangeEmailRequest
 */
export interface DtoChangeEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoChangeEmailRequest
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoChangeEmailRequest
     */
    'email_id': string;
    /**
     * 
     * @type {string}
     * @memberof DtoChangeEmailRequest
     */
    'password'?: string;
    /**
     * 
     * @type {CommonProductFocus}
     * @memberof DtoChangeEmailRequest
     */
    'product_focus'?: CommonProductFocus;
    /**
     * 
     * @type {string}
     * @memberof DtoChangeEmailRequest
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoChangeEmailRequest
     */
    'token': string;
}


/**
 * 
 * @export
 * @interface DtoChangePasswordRequest
 */
export interface DtoChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoChangePasswordRequest
     */
    'new_password': string;
    /**
     * 
     * @type {string}
     * @memberof DtoChangePasswordRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface DtoChangePhoneRequest
 */
export interface DtoChangePhoneRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoChangePhoneRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof DtoChangePhoneRequest
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface DtoChangeUsernameRequest
 */
export interface DtoChangeUsernameRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoChangeUsernameRequest
     */
    'new_user_name': string;
    /**
     * 
     * @type {string}
     * @memberof DtoChangeUsernameRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface DtoClientSideTokenizationError
 */
export interface DtoClientSideTokenizationError {
    /**
     * 
     * @type {DtoCart}
     * @memberof DtoClientSideTokenizationError
     */
    'cart': DtoCart;
    /**
     * 
     * @type {string}
     * @memberof DtoClientSideTokenizationError
     */
    'device_id': string;
    /**
     * 
     * @type {string}
     * @memberof DtoClientSideTokenizationError
     */
    'merchant_request': string;
    /**
     * 
     * @type {string}
     * @memberof DtoClientSideTokenizationError
     */
    'merchant_response': string;
}
/**
 * 
 * @export
 * @interface DtoConsentRequest
 */
export interface DtoConsentRequest {
    /**
     * 
     * @type {boolean}
     * @memberof DtoConsentRequest
     */
    'consent_checked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoConsentRequest
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof DtoConsentRequest
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof DtoConsentRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoConsentRequest
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface DtoCouponDetails
 */
export interface DtoCouponDetails {
    /**
     * 
     * @type {string}
     * @memberof DtoCouponDetails
     */
    'coupon_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DtoCouponDetails
     */
    'discount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DtoCouponDetails
     */
    'fixed_price'?: number;
    /**
     * 
     * @type {Array<CommonProductTypes>}
     * @memberof DtoCouponDetails
     */
    'scopes'?: Array<CommonProductTypes>;
}
/**
 * 
 * @export
 * @interface DtoDeadline
 */
export interface DtoDeadline {
    /**
     * 
     * @type {string}
     * @memberof DtoDeadline
     */
    'extended_filing_deadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoDeadline
     */
    'filing_deadline'?: string;
}
/**
 * 
 * @export
 * @interface DtoDeadlineResponse
 */
export interface DtoDeadlineResponse {
    /**
     * 
     * @type {string}
     * @memberof DtoDeadlineResponse
     */
    'form1041Deadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoDeadlineResponse
     */
    'form1041Extension'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoDeadlineResponse
     */
    'form1065Deadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoDeadlineResponse
     */
    'form1065Extension'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoDeadlineResponse
     */
    'form1120Deadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoDeadlineResponse
     */
    'form1120Extension'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoDeadlineResponse
     */
    'form1120FDeadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoDeadlineResponse
     */
    'form1120FExtension'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoDeadlineResponse
     */
    'form1120SDeadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoDeadlineResponse
     */
    'form1120SExtension'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoDeadlineResponse
     */
    'form4868Deadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoDeadlineResponse
     */
    'form4868Extension'?: string;
}
/**
 * Complete response containing all beneficial owner related data
 * @export
 * @interface DtoDeskproResponse
 */
export interface DtoDeskproResponse {
    /**
     * 
     * @type {Array<DtoBoirApplicant>}
     * @memberof DtoDeskproResponse
     */
    'applicants'?: Array<DtoBoirApplicant>;
    /**
     * 
     * @type {Array<DtoBoirBeneficialOwner>}
     * @memberof DtoDeskproResponse
     */
    'beneficial_owners'?: Array<DtoBoirBeneficialOwner>;
    /**
     * 
     * @type {Array<DtoBoirFiler>}
     * @memberof DtoDeskproResponse
     */
    'filers'?: Array<DtoBoirFiler>;
    /**
     * 
     * @type {Array<DtoBoirForm>}
     * @memberof DtoDeskproResponse
     */
    'forms'?: Array<DtoBoirForm>;
    /**
     * 
     * @type {Array<DtoBoirSubmission>}
     * @memberof DtoDeskproResponse
     */
    'submissions'?: Array<DtoBoirSubmission>;
}
/**
 * 
 * @export
 * @interface DtoEditSubscriptionRequest
 */
export interface DtoEditSubscriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoEditSubscriptionRequest
     */
    'action_url': string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoEditSubscriptionRequest
     */
    'enable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoEditSubscriptionRequest
     */
    'product_sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoEditSubscriptionRequest
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof DtoEditSubscriptionRequest
     */
    'subscription_id'?: string;
}
/**
 * 
 * @export
 * @interface DtoEmailValidationRequest
 */
export interface DtoEmailValidationRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoEmailValidationRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface DtoF4868CreateRequest
 */
export interface DtoF4868CreateRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoF4868CreateRequest
     */
    'device_id': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868CreateRequest
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868CreateRequest
     */
    'form_choice': DtoF4868CreateRequestFormChoiceEnum;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868CreateRequest
     */
    'last_name': string;
    /**
     * 
     * @type {number}
     * @memberof DtoF4868CreateRequest
     */
    'year': number;
}

export const DtoF4868CreateRequestFormChoiceEnum = {
    Personal: 'personal',
    Business: 'business',
    Both: 'both'
} as const;

export type DtoF4868CreateRequestFormChoiceEnum = typeof DtoF4868CreateRequestFormChoiceEnum[keyof typeof DtoF4868CreateRequestFormChoiceEnum];

/**
 * 
 * @export
 * @interface DtoF4868CreateResponse
 */
export interface DtoF4868CreateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DtoF4868CreateResponse
     */
    'fills_both': boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868CreateResponse
     */
    'form_id': string;
}
/**
 * 
 * @export
 * @interface DtoF4868FullUpdateRequest
 */
export interface DtoF4868FullUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoF4868FullUpdateRequest
     */
    'address_line_1': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868FullUpdateRequest
     */
    'address_line_2'?: string;
    /**
     * 
     * @type {number}
     * @memberof DtoF4868FullUpdateRequest
     */
    'amount_owed'?: number;
    /**
     * 
     * @type {number}
     * @memberof DtoF4868FullUpdateRequest
     */
    'amount_payed'?: number;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868FullUpdateRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868FullUpdateRequest
     */
    'device_id': string;
    /**
     * 
     * @type {CommonFormFilingType}
     * @memberof DtoF4868FullUpdateRequest
     */
    'filing_type': CommonFormFilingType;
    /**
     * 
     * @type {boolean}
     * @memberof DtoF4868FullUpdateRequest
     */
    'fills_both'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868FullUpdateRequest
     */
    'first_name': string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoF4868FullUpdateRequest
     */
    'form_1040'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoF4868FullUpdateRequest
     */
    'is_out_of_country'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868FullUpdateRequest
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868FullUpdateRequest
     */
    'spouse_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868FullUpdateRequest
     */
    'spouse_last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868FullUpdateRequest
     */
    'spouse_ssn'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868FullUpdateRequest
     */
    'ssn': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868FullUpdateRequest
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868FullUpdateRequest
     */
    'zip': string;
}


/**
 * 
 * @export
 * @interface DtoF4868FullUpdateResponse
 */
export interface DtoF4868FullUpdateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DtoF4868FullUpdateResponse
     */
    'needs_payment'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoF4868FullUpdateResponse
     */
    'status'?: boolean;
}
/**
 * 
 * @export
 * @interface DtoF4868Step2Request
 */
export interface DtoF4868Step2Request {
    /**
     * 
     * @type {string}
     * @memberof DtoF4868Step2Request
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868Step2Request
     */
    'last_name': string;
}
/**
 * 
 * @export
 * @interface DtoF4868Step3Request
 */
export interface DtoF4868Step3Request {
    /**
     * 
     * @type {string}
     * @memberof DtoF4868Step3Request
     */
    'address_line_1': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868Step3Request
     */
    'address_line_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868Step3Request
     */
    'city': string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoF4868Step3Request
     */
    'force_military'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoF4868Step3Request
     */
    'force_zip'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868Step3Request
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868Step3Request
     */
    'zip': string;
}
/**
 * 
 * @export
 * @interface DtoF4868Step4Request
 */
export interface DtoF4868Step4Request {
    /**
     * 
     * @type {CommonFormFilingType}
     * @memberof DtoF4868Step4Request
     */
    'filing_type': CommonFormFilingType;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868Step4Request
     */
    'spouse_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868Step4Request
     */
    'spouse_last_name'?: string;
}


/**
 * 
 * @export
 * @interface DtoF4868Step5Request
 */
export interface DtoF4868Step5Request {
    /**
     * 
     * @type {boolean}
     * @memberof DtoF4868Step5Request
     */
    'form_1040'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoF4868Step5Request
     */
    'is_out_of_country'?: boolean;
}
/**
 * 
 * @export
 * @interface DtoF4868Step6Request
 */
export interface DtoF4868Step6Request {
    /**
     * 
     * @type {number}
     * @memberof DtoF4868Step6Request
     */
    'amount_owed'?: number;
    /**
     * 
     * @type {number}
     * @memberof DtoF4868Step6Request
     */
    'amount_payed'?: number;
}
/**
 * 
 * @export
 * @interface DtoF4868Step7Request
 */
export interface DtoF4868Step7Request {
    /**
     * 
     * @type {string}
     * @memberof DtoF4868Step7Request
     */
    'spouse_ssn'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoF4868Step7Request
     */
    'ssn': string;
}
/**
 * 
 * @export
 * @interface DtoF7004CreateRequest
 */
export interface DtoF7004CreateRequest {
    /**
     * 
     * @type {CommonBusinessType}
     * @memberof DtoF7004CreateRequest
     */
    'business_type': CommonBusinessType;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004CreateRequest
     */
    'device_id': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004CreateRequest
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004CreateRequest
     */
    'form_choice': DtoF7004CreateRequestFormChoiceEnum;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004CreateRequest
     */
    'form_code'?: DtoF7004CreateRequestFormCodeEnum;
    /**
     * 
     * @type {CommonBusinessFormName}
     * @memberof DtoF7004CreateRequest
     */
    'form_name': CommonBusinessFormName;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004CreateRequest
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004CreateRequest
     */
    'surname_of_deceased'?: string;
    /**
     * 
     * @type {number}
     * @memberof DtoF7004CreateRequest
     */
    'year': number;
}

export const DtoF7004CreateRequestFormChoiceEnum = {
    Personal: 'personal',
    Business: 'business',
    Both: 'both'
} as const;

export type DtoF7004CreateRequestFormChoiceEnum = typeof DtoF7004CreateRequestFormChoiceEnum[keyof typeof DtoF7004CreateRequestFormChoiceEnum];
export const DtoF7004CreateRequestFormCodeEnum = {
    _09: '09',
    _12: '12',
    _25: '25',
    _15: '15',
    _05: '05',
    _04: '04',
    _03: '03'
} as const;

export type DtoF7004CreateRequestFormCodeEnum = typeof DtoF7004CreateRequestFormCodeEnum[keyof typeof DtoF7004CreateRequestFormCodeEnum];

/**
 * 
 * @export
 * @interface DtoF7004CreateResponse
 */
export interface DtoF7004CreateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DtoF7004CreateResponse
     */
    'fills_both': boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004CreateResponse
     */
    'form_id': string;
}
/**
 * 
 * @export
 * @interface DtoF7004Step2Request
 */
export interface DtoF7004Step2Request {
    /**
     * 
     * @type {CommonBusinessType}
     * @memberof DtoF7004Step2Request
     */
    'business_type': CommonBusinessType;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004Step2Request
     */
    'form_code'?: DtoF7004Step2RequestFormCodeEnum;
    /**
     * 
     * @type {CommonBusinessFormName}
     * @memberof DtoF7004Step2Request
     */
    'form_name': CommonBusinessFormName;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004Step2Request
     */
    'surname_of_deceased'?: string;
}

export const DtoF7004Step2RequestFormCodeEnum = {
    _09: '09',
    _12: '12',
    _25: '25',
    _15: '15',
    _05: '05',
    _04: '04',
    _03: '03'
} as const;

export type DtoF7004Step2RequestFormCodeEnum = typeof DtoF7004Step2RequestFormCodeEnum[keyof typeof DtoF7004Step2RequestFormCodeEnum];

/**
 * 
 * @export
 * @interface DtoF7004Step3Request
 */
export interface DtoF7004Step3Request {
    /**
     * 
     * @type {string}
     * @memberof DtoF7004Step3Request
     */
    'company_code': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004Step3Request
     */
    'company_name': string;
}
/**
 * 
 * @export
 * @interface DtoF7004Step4Request
 */
export interface DtoF7004Step4Request {
    /**
     * 
     * @type {string}
     * @memberof DtoF7004Step4Request
     */
    'address_line_1': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004Step4Request
     */
    'address_line_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004Step4Request
     */
    'city': string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoF7004Step4Request
     */
    'force_military'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoF7004Step4Request
     */
    'force_zip'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004Step4Request
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004Step4Request
     */
    'zip': string;
}
/**
 * 
 * @export
 * @interface DtoF7004Step5Request
 */
export interface DtoF7004Step5Request {
    /**
     * 
     * @type {boolean}
     * @memberof DtoF7004Step5Request
     */
    'no_office_in_us': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoF7004Step5Request
     */
    'parent_of_group': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoF7004Step5Request
     */
    'reg_160815': boolean;
}
/**
 * 
 * @export
 * @interface DtoF7004Step6Request
 */
export interface DtoF7004Step6Request {
    /**
     * 
     * @type {boolean}
     * @memberof DtoF7004Step6Request
     */
    'follows_fiscal_year': boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004Step6Request
     */
    'year_ends_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004Step6Request
     */
    'year_starts_at'?: string;
}
/**
 * 
 * @export
 * @interface DtoF7004Step7Request
 */
export interface DtoF7004Step7Request {
    /**
     * 
     * @type {boolean}
     * @memberof DtoF7004Step7Request
     */
    'short_tax_year': boolean;
    /**
     * 
     * @type {CommonBusinessFormShortTaxYearReason}
     * @memberof DtoF7004Step7Request
     */
    'short_tax_year_reason'?: CommonBusinessFormShortTaxYearReason;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004Step7Request
     */
    'short_tax_year_text'?: string;
}


/**
 * 
 * @export
 * @interface DtoF7004Step8Request
 */
export interface DtoF7004Step8Request {
    /**
     * 
     * @type {number}
     * @memberof DtoF7004Step8Request
     */
    'tentative_total_tax'?: number;
    /**
     * 
     * @type {number}
     * @memberof DtoF7004Step8Request
     */
    'total_payments'?: number;
}
/**
 * 
 * @export
 * @interface DtoF7004Step9Request
 */
export interface DtoF7004Step9Request {
    /**
     * 
     * @type {string}
     * @memberof DtoF7004Step9Request
     */
    'business_tin': string;
}
/**
 * 
 * @export
 * @interface DtoF7004UpdateFormRequest
 */
export interface DtoF7004UpdateFormRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoF7004UpdateFormRequest
     */
    'address_line_1': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004UpdateFormRequest
     */
    'address_line_2'?: string;
    /**
     * Step8
     * @type {string}
     * @memberof DtoF7004UpdateFormRequest
     */
    'business_tin': string;
    /**
     * Step1
     * @type {CommonBusinessType}
     * @memberof DtoF7004UpdateFormRequest
     */
    'business_type': CommonBusinessType;
    /**
     * Step3
     * @type {string}
     * @memberof DtoF7004UpdateFormRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004UpdateFormRequest
     */
    'company_code': string;
    /**
     * Step2
     * @type {string}
     * @memberof DtoF7004UpdateFormRequest
     */
    'company_name': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004UpdateFormRequest
     */
    'device_id'?: string;
    /**
     * Step5
     * @type {boolean}
     * @memberof DtoF7004UpdateFormRequest
     */
    'follows_fiscal_year': boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004UpdateFormRequest
     */
    'form_code'?: DtoF7004UpdateFormRequestFormCodeEnum;
    /**
     * 
     * @type {CommonBusinessFormName}
     * @memberof DtoF7004UpdateFormRequest
     */
    'form_name': CommonBusinessFormName;
    /**
     * Step4
     * @type {boolean}
     * @memberof DtoF7004UpdateFormRequest
     */
    'no_office_in_us': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoF7004UpdateFormRequest
     */
    'parent_of_group': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoF7004UpdateFormRequest
     */
    'reg_160815': boolean;
    /**
     * Step6
     * @type {boolean}
     * @memberof DtoF7004UpdateFormRequest
     */
    'short_tax_year': boolean;
    /**
     * 
     * @type {CommonBusinessFormShortTaxYearReason}
     * @memberof DtoF7004UpdateFormRequest
     */
    'short_tax_year_reason'?: CommonBusinessFormShortTaxYearReason;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004UpdateFormRequest
     */
    'short_tax_year_text'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004UpdateFormRequest
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004UpdateFormRequest
     */
    'surname_of_deceased'?: string;
    /**
     * Step7
     * @type {number}
     * @memberof DtoF7004UpdateFormRequest
     */
    'tentative_total_tax'?: number;
    /**
     * 
     * @type {number}
     * @memberof DtoF7004UpdateFormRequest
     */
    'total_payments'?: number;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004UpdateFormRequest
     */
    'year_ends_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004UpdateFormRequest
     */
    'year_starts_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoF7004UpdateFormRequest
     */
    'zip': string;
}

export const DtoF7004UpdateFormRequestFormCodeEnum = {
    _09: '09',
    _12: '12',
    _25: '25',
    _15: '15',
    _05: '05',
    _04: '04',
    _03: '03'
} as const;

export type DtoF7004UpdateFormRequestFormCodeEnum = typeof DtoF7004UpdateFormRequestFormCodeEnum[keyof typeof DtoF7004UpdateFormRequestFormCodeEnum];

/**
 * 
 * @export
 * @interface DtoF7004UpdateFormResponse
 */
export interface DtoF7004UpdateFormResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DtoF7004UpdateFormResponse
     */
    'needs_payment'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoF7004UpdateFormResponse
     */
    'status'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DtoFilingCardAction = {
    FilingCardActionRedirect: 'redirect',
    FilingCardActionNone: 'none',
    FilingCardActionShowOffer: 'show-offer',
    FilingCardActionToExtension: 'to-extension',
    FilingCardActionDisabled: 'disabled',
    FilingCardActionEnableCard: 'enable-card'
} as const;

export type DtoFilingCardAction = typeof DtoFilingCardAction[keyof typeof DtoFilingCardAction];


/**
 * 
 * @export
 * @interface DtoFilingCardConfig
 */
export interface DtoFilingCardConfig {
    /**
     * 
     * @type {string}
     * @memberof DtoFilingCardConfig
     */
    'fetch_source'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoFilingCardConfig
     */
    'matched_rule'?: string;
    /**
     * 
     * @type {DtoFilingCardAction}
     * @memberof DtoFilingCardConfig
     */
    'primary_action'?: DtoFilingCardAction;
    /**
     * 
     * @type {string}
     * @memberof DtoFilingCardConfig
     */
    'primary_action_value'?: string;
    /**
     * 
     * @type {DtoFilingCardAction}
     * @memberof DtoFilingCardConfig
     */
    'secondary_action'?: DtoFilingCardAction;
    /**
     * 
     * @type {DtoFilingCardState}
     * @memberof DtoFilingCardConfig
     */
    'state'?: DtoFilingCardState;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DtoFilingCardState = {
    FilingCardStateEnabled: 'enabled',
    FilingCardStateAlert: 'alert',
    FilingCardStatePremium: 'premium',
    FilingCardStateHidden: 'hidden'
} as const;

export type DtoFilingCardState = typeof DtoFilingCardState[keyof typeof DtoFilingCardState];


/**
 * 
 * @export
 * @interface DtoForgotPasswordIntent
 */
export interface DtoForgotPasswordIntent {
    /**
     * 
     * @type {string}
     * @memberof DtoForgotPasswordIntent
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface DtoForgotPasswordRequest
 */
export interface DtoForgotPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoForgotPasswordRequest
     */
    'funnel'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoForgotPasswordRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof DtoForgotPasswordRequest
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof DtoForgotPasswordRequest
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface DtoForm4868
 */
export interface DtoForm4868 {
    /**
     * 
     * @type {Array<DtoAckError>}
     * @memberof DtoForm4868
     */
    'ack_errors': Array<DtoAckError>;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'address_line_1': string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'address_line_2': string;
    /**
     * 
     * @type {number}
     * @memberof DtoForm4868
     */
    'amount_owed': number;
    /**
     * 
     * @type {number}
     * @memberof DtoForm4868
     */
    'amount_payed': number;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'create_at': string;
    /**
     * 
     * @type {DtoDeadline}
     * @memberof DtoForm4868
     */
    'deadline'?: DtoDeadline;
    /**
     * 
     * @type {CommonFormFilingType}
     * @memberof DtoForm4868
     */
    'filing_type': CommonFormFilingType;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm4868
     */
    'fills_both'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm4868
     */
    'finished': boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'first_name': string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm4868
     */
    'force_military'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm4868
     */
    'force_zip'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm4868
     */
    'form_1040': boolean;
    /**
     * 
     * @type {CommonFormStatus}
     * @memberof DtoForm4868
     */
    'form_status': CommonFormStatus;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'form_type': string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm4868
     */
    'high_priority': boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm4868
     */
    'is_accepted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm4868
     */
    'is_acknowledged': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm4868
     */
    'is_form_complete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm4868
     */
    'is_locked': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm4868
     */
    'is_out_of_country': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm4868
     */
    'is_payed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm4868
     */
    'is_queued': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm4868
     */
    'is_submitted': boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'last_name': string;
    /**
     * 
     * @type {CommonPaymentStatus}
     * @memberof DtoForm4868
     */
    'payment_status': CommonPaymentStatus;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'postmark': string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'recreate_target_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'short_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'spouse_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'spouse_last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'spouse_ssn'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'spouse_ssn_last4'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'ssn': string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'ssn_last_4'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'submission_created_at'?: string;
    /**
     * 
     * @type {CommonSubmissionStatus}
     * @memberof DtoForm4868
     */
    'submission_status': CommonSubmissionStatus;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'timestamp': string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof DtoForm4868
     */
    'version': number;
    /**
     * 
     * @type {number}
     * @memberof DtoForm4868
     */
    'year': number;
    /**
     * 
     * @type {string}
     * @memberof DtoForm4868
     */
    'zip': string;
}


/**
 * 
 * @export
 * @interface DtoForm7004
 */
export interface DtoForm7004 {
    /**
     * 
     * @type {Array<DtoAckError>}
     * @memberof DtoForm7004
     */
    'ack_errors': Array<DtoAckError>;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'address_line_1': string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'address_line_2': string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'business_tin': string;
    /**
     * 
     * @type {CommonBusinessType}
     * @memberof DtoForm7004
     */
    'business_type'?: CommonBusinessType;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'company_code': string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'company_name': string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'create_at': string;
    /**
     * 
     * @type {DtoDeadline}
     * @memberof DtoForm7004
     */
    'deadline'?: DtoDeadline;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm7004
     */
    'finished': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm7004
     */
    'follows_fiscal_year': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm7004
     */
    'force_military'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm7004
     */
    'force_zip'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'form_code': string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'form_name': string;
    /**
     * 
     * @type {CommonFormStatus}
     * @memberof DtoForm7004
     */
    'form_status': CommonFormStatus;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'form_type': string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm7004
     */
    'high_priority': boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm7004
     */
    'is_accepted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm7004
     */
    'is_acknowledged': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm7004
     */
    'is_form_complete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm7004
     */
    'is_locked': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm7004
     */
    'is_payed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm7004
     */
    'is_queued': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm7004
     */
    'is_submitted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm7004
     */
    'no_office_in_us': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm7004
     */
    'parent_of_group': boolean;
    /**
     * 
     * @type {CommonPaymentStatus}
     * @memberof DtoForm7004
     */
    'payment_status': CommonPaymentStatus;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'postmark'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'recreate_target_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm7004
     */
    'reg_160815': boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'short_code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoForm7004
     */
    'short_tax_year': boolean;
    /**
     * 
     * @type {CommonBusinessFormShortTaxYearReason}
     * @memberof DtoForm7004
     */
    'short_tax_year_reason'?: CommonBusinessFormShortTaxYearReason;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'short_tax_year_text'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'submission_created_at'?: string;
    /**
     * 
     * @type {CommonSubmissionStatus}
     * @memberof DtoForm7004
     */
    'submission_status': CommonSubmissionStatus;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'surname_of_deceased'?: string;
    /**
     * 
     * @type {number}
     * @memberof DtoForm7004
     */
    'tentative_total_tax': number;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'timestamp'?: string;
    /**
     * 
     * @type {number}
     * @memberof DtoForm7004
     */
    'total_payments': number;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof DtoForm7004
     */
    'year': number;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'year_ends_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'year_starts_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoForm7004
     */
    'zip': string;
}


/**
 * 
 * @export
 * @interface DtoLoginRequest
 */
export interface DtoLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoLoginRequest
     */
    'access_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoLoginRequest
     */
    'device_id': string;
    /**
     * 
     * @type {string}
     * @memberof DtoLoginRequest
     */
    'funnel'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoLoginRequest
     */
    'password': string;
    /**
     * 
     * @type {CommonProductFocus}
     * @memberof DtoLoginRequest
     */
    'product_focus'?: CommonProductFocus;
    /**
     * 
     * @type {string}
     * @memberof DtoLoginRequest
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof DtoLoginRequest
     */
    'username': string;
}


/**
 * 
 * @export
 * @interface DtoLoginResponse
 */
export interface DtoLoginResponse {
    /**
     * 
     * @type {DtoUserProfile}
     * @memberof DtoLoginResponse
     */
    'profile': DtoUserProfile;
    /**
     * 
     * @type {string}
     * @memberof DtoLoginResponse
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof DtoLoginResponse
     */
    'token_type': DtoLoginResponseTokenTypeEnum;
}

export const DtoLoginResponseTokenTypeEnum = {
    Jwt: 'jwt',
    Redirect: 'redirect'
} as const;

export type DtoLoginResponseTokenTypeEnum = typeof DtoLoginResponseTokenTypeEnum[keyof typeof DtoLoginResponseTokenTypeEnum];

/**
 * 
 * @export
 * @interface DtoMagicLinkRequest
 */
export interface DtoMagicLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoMagicLinkRequest
     */
    'email': string;
    /**
     * 
     * @type {CommonProductFocus}
     * @memberof DtoMagicLinkRequest
     */
    'product_focus'?: CommonProductFocus;
}


/**
 * 
 * @export
 * @interface DtoMagicLoginRequest
 */
export interface DtoMagicLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoMagicLoginRequest
     */
    'device_id': string;
    /**
     * 
     * @type {string}
     * @memberof DtoMagicLoginRequest
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof DtoMagicLoginRequest
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface DtoOfferPageConfig
 */
export interface DtoOfferPageConfig {
    /**
     * 
     * @type {string}
     * @memberof DtoOfferPageConfig
     */
    'name': string;
    /**
     * 
     * @type {CommonOfferPage}
     * @memberof DtoOfferPageConfig
     */
    'page_placeholder': CommonOfferPage;
    /**
     * 
     * @type {Array<DtoProduct>}
     * @memberof DtoOfferPageConfig
     */
    'products': Array<DtoProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof DtoOfferPageConfig
     */
    'progress_bar_enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoOfferPageConfig
     */
    'sku': string;
}


/**
 * 
 * @export
 * @interface DtoPasswordValidationRequest
 */
export interface DtoPasswordValidationRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoPasswordValidationRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPasswordValidationRequest
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPasswordValidationRequest
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPasswordValidationRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface DtoPayeezyWebhookData
 */
export interface DtoPayeezyWebhookData {
    /**
     * 
     * @type {string}
     * @memberof DtoPayeezyWebhookData
     */
    'authCode'?: string;
    /**
     * 
     * @type {DtoPayeezyWebhookDataCard}
     * @memberof DtoPayeezyWebhookData
     */
    'card'?: DtoPayeezyWebhookDataCard;
    /**
     * 
     * @type {string}
     * @memberof DtoPayeezyWebhookData
     */
    'clientToken'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoPayeezyWebhookData
     */
    'error'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoPayeezyWebhookData
     */
    'gatewayReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPayeezyWebhookData
     */
    'gatewayRefId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPayeezyWebhookData
     */
    'nonce'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPayeezyWebhookData
     */
    'reason'?: string;
}
/**
 * 
 * @export
 * @interface DtoPayeezyWebhookDataCard
 */
export interface DtoPayeezyWebhookDataCard {
    /**
     * 
     * @type {string}
     * @memberof DtoPayeezyWebhookDataCard
     */
    'bin'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPayeezyWebhookDataCard
     */
    'brand'?: string;
    /**
     * 
     * @type {DtoPayeezyWebhookDataCardExp}
     * @memberof DtoPayeezyWebhookDataCard
     */
    'exp'?: DtoPayeezyWebhookDataCardExp;
    /**
     * 
     * @type {string}
     * @memberof DtoPayeezyWebhookDataCard
     */
    'last4'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPayeezyWebhookDataCard
     */
    'masked'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPayeezyWebhookDataCard
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPayeezyWebhookDataCard
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface DtoPayeezyWebhookDataCardExp
 */
export interface DtoPayeezyWebhookDataCardExp {
    /**
     * 
     * @type {string}
     * @memberof DtoPayeezyWebhookDataCardExp
     */
    'month'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPayeezyWebhookDataCardExp
     */
    'year'?: string;
}
/**
 * 
 * @export
 * @interface DtoPaymentProfile
 */
export interface DtoPaymentProfile {
    /**
     * 
     * @type {boolean}
     * @memberof DtoPaymentProfile
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoPaymentProfile
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPaymentProfile
     */
    'card_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPaymentProfile
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPaymentProfile
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPaymentProfile
     */
    'expiration_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPaymentProfile
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPaymentProfile
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoPaymentProfile
     */
    'is_default'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoPaymentProfile
     */
    'is_failing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoPaymentProfile
     */
    'last4'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPaymentProfile
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPaymentProfile
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoPaymentProfile
     */
    'zip'?: string;
}
/**
 * 
 * @export
 * @interface DtoPaymentResult
 */
export interface DtoPaymentResult {
    /**
     * 
     * @type {number}
     * @memberof DtoPaymentResult
     */
    'amount_billed'?: number;
    /**
     * 
     * @type {string}
     * @memberof DtoPaymentResult
     */
    'payment_profile_id'?: string;
    /**
     * 
     * @type {Array<DtoProduct>}
     * @memberof DtoPaymentResult
     */
    'purchases'?: Array<DtoProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof DtoPaymentResult
     */
    'status'?: boolean;
}
/**
 * 
 * @export
 * @interface DtoPreAuthRequest
 */
export interface DtoPreAuthRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoPreAuthRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface DtoPreAuthResponse
 */
export interface DtoPreAuthResponse {
    /**
     * 
     * @type {Array<CommonAuthMode>}
     * @memberof DtoPreAuthResponse
     */
    'auth_modes'?: Array<CommonAuthMode>;
    /**
     * 
     * @type {string}
     * @memberof DtoPreAuthResponse
     */
    'first_name'?: string;
}
/**
 * 
 * @export
 * @interface DtoProduct
 */
export interface DtoProduct {
    /**
     * BasePrice - this is base price configured in our system after global discount. Is used by default
     * @type {number}
     * @memberof DtoProduct
     */
    'base_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof DtoProduct
     */
    'code': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DtoProduct
     */
    'conflicts'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DtoProduct
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DtoProduct
     */
    'funnel_tags'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DtoProduct
     */
    'g1_conversion_label'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoProduct
     */
    'g2_conversion_label'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoProduct
     */
    'has_trial': boolean;
    /**
     * 
     * @type {Array<DtoProduct>}
     * @memberof DtoProduct
     */
    'included_products'?: Array<DtoProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof DtoProduct
     */
    'is_available': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoProduct
     */
    'is_subscription'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoProduct
     */
    'name': string;
    /**
     * OriginalPrice - the main price. Is zero if not set. Used mostly in ads and striked out prices.
     * @type {number}
     * @memberof DtoProduct
     */
    'original_price'?: number;
    /**
     * Price - is a price we will charge user for. Is price after coupons applied if any
     * @type {number}
     * @memberof DtoProduct
     */
    'price': number;
    /**
     * 
     * @type {CommonProductTypes}
     * @memberof DtoProduct
     */
    'product_type': CommonProductTypes;
    /**
     * 
     * @type {string}
     * @memberof DtoProduct
     */
    'sku': string;
    /**
     * 
     * @type {string}
     * @memberof DtoProduct
     */
    'subscription_charge_period'?: DtoProductSubscriptionChargePeriodEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DtoProduct
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DtoProduct
     */
    'trial_period_days'?: number;
}

export const DtoProductSubscriptionChargePeriodEnum = {
    Month: 'month',
    Year: 'year'
} as const;

export type DtoProductSubscriptionChargePeriodEnum = typeof DtoProductSubscriptionChargePeriodEnum[keyof typeof DtoProductSubscriptionChargePeriodEnum];

/**
 * 
 * @export
 * @interface DtoProductInfo
 */
export interface DtoProductInfo {
    /**
     * 
     * @type {string}
     * @memberof DtoProductInfo
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoProductInfo
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof DtoProductInfo
     */
    'discount_offered'?: number;
    /**
     * 
     * @type {number}
     * @memberof DtoProductInfo
     */
    'full_price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DtoProductInfo
     */
    'has_trial'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoProductInfo
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DtoProductInfo
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof DtoProductInfo
     */
    'product_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoProductInfo
     */
    'sku'?: string;
}
/**
 * 
 * @export
 * @interface DtoProfilePaymentRequest
 */
export interface DtoProfilePaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoProfilePaymentRequest
     */
    'action_url': string;
    /**
     * 
     * @type {DtoCart}
     * @memberof DtoProfilePaymentRequest
     */
    'cart': DtoCart;
    /**
     * 
     * @type {string}
     * @memberof DtoProfilePaymentRequest
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoProfilePaymentRequest
     */
    'source': string;
}
/**
 * 
 * @export
 * @interface DtoRedtrackClickIdStoreRequest
 */
export interface DtoRedtrackClickIdStoreRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoRedtrackClickIdStoreRequest
     */
    'click_id': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DtoRedtrackClickIdStoreRequest
     */
    'params'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof DtoRedtrackClickIdStoreRequest
     */
    'r_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoRedtrackClickIdStoreRequest
     */
    'user_id'?: string;
}
/**
 * 
 * @export
 * @interface DtoRefreshTokenResponse
 */
export interface DtoRefreshTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof DtoRefreshTokenResponse
     */
    'jwt_token'?: string;
    /**
     * 
     * @type {DtoUserProfile}
     * @memberof DtoRefreshTokenResponse
     */
    'profile': DtoUserProfile;
}
/**
 * 
 * @export
 * @interface DtoRegistrationRequest
 */
export interface DtoRegistrationRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoRegistrationRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof DtoRegistrationRequest
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoRegistrationRequest
     */
    'funnel'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoRegistrationRequest
     */
    'ip_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoRegistrationRequest
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoRegistrationRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof DtoRegistrationRequest
     */
    'phone'?: string;
    /**
     * 
     * @type {CommonProductFocus}
     * @memberof DtoRegistrationRequest
     */
    'product_focus'?: CommonProductFocus;
    /**
     * 
     * @type {DtoTrackingInfo}
     * @memberof DtoRegistrationRequest
     */
    't_info': DtoTrackingInfo;
    /**
     * 
     * @type {string}
     * @memberof DtoRegistrationRequest
     */
    'user_agent'?: string;
}


/**
 * 
 * @export
 * @interface DtoSendEmailRequest
 */
export interface DtoSendEmailRequest {
    /**
     * 
     * @type {CommonBrand}
     * @memberof DtoSendEmailRequest
     */
    'brand'?: CommonBrand;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof DtoSendEmailRequest
     */
    'custom_parameters'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof DtoSendEmailRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoSendEmailRequest
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoSendEmailRequest
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoSendEmailRequest
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoSendEmailRequest
     */
    'template'?: string;
}


/**
 * 
 * @export
 * @interface DtoShieldSubscriptionRequest
 */
export interface DtoShieldSubscriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoShieldSubscriptionRequest
     */
    'action_url': string;
    /**
     * 
     * @type {string}
     * @memberof DtoShieldSubscriptionRequest
     */
    'product_sku': string;
    /**
     * 
     * @type {string}
     * @memberof DtoShieldSubscriptionRequest
     */
    'source': string;
}
/**
 * 
 * @export
 * @interface DtoSubscriptionDataRecord
 */
export interface DtoSubscriptionDataRecord {
    /**
     * 
     * @type {string}
     * @memberof DtoSubscriptionDataRecord
     */
    'created_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoSubscriptionDataRecord
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {CommonFormType}
     * @memberof DtoSubscriptionDataRecord
     */
    'form_type': CommonFormType;
    /**
     * 
     * @type {boolean}
     * @memberof DtoSubscriptionDataRecord
     */
    'is_still_active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoSubscriptionDataRecord
     */
    'is_trial'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoSubscriptionDataRecord
     */
    'last_paid'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoSubscriptionDataRecord
     */
    'next_check_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoSubscriptionDataRecord
     */
    'payment_profile_last4'?: string;
    /**
     * 
     * @type {number}
     * @memberof DtoSubscriptionDataRecord
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof DtoSubscriptionDataRecord
     */
    'subscription_id'?: string;
    /**
     * 
     * @type {CommonSubscriptionStatus}
     * @memberof DtoSubscriptionDataRecord
     */
    'subscription_status': CommonSubscriptionStatus;
    /**
     * 
     * @type {CommonProductTypes}
     * @memberof DtoSubscriptionDataRecord
     */
    'subscription_type': CommonProductTypes;
}


/**
 * 
 * @export
 * @interface DtoTokenExchangeRequest
 */
export interface DtoTokenExchangeRequest {
    /**
     * 
     * @type {string}
     * @memberof DtoTokenExchangeRequest
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface DtoTrackingInfo
 */
export interface DtoTrackingInfo {
    /**
     * 
     * @type {string}
     * @memberof DtoTrackingInfo
     */
    'aff_sub_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoTrackingInfo
     */
    'affiliate_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoTrackingInfo
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoTrackingInfo
     */
    'lead_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoTrackingInfo
     */
    'msclkid'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoTrackingInfo
     */
    'one_signal_app_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoTrackingInfo
     */
    'one_signal_user_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoTrackingInfo
     */
    'redtrack_click_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoTrackingInfo
     */
    'redtrack_domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoTrackingInfo
     */
    'ref_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoTrackingInfo
     */
    'search_source'?: string;
    /**
     * 
     * @type {CommonBrand}
     * @memberof DtoTrackingInfo
     */
    'source'?: CommonBrand;
    /**
     * 
     * @type {string}
     * @memberof DtoTrackingInfo
     */
    'transaction_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoTrackingInfo
     */
    'utm_source'?: string;
}


/**
 * 
 * @export
 * @interface DtoUserProfile
 */
export interface DtoUserProfile {
    /**
     * 
     * @type {Array<CommonAccessScopes>}
     * @memberof DtoUserProfile
     */
    'access_scopes'?: Array<CommonAccessScopes>;
    /**
     * 
     * @type {CommonBrand}
     * @memberof DtoUserProfile
     */
    'brand'?: CommonBrand;
    /**
     * 
     * @type {string}
     * @memberof DtoUserProfile
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof DtoUserProfile
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof DtoUserProfile
     */
    'funnel'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoUserProfile
     */
    'has_2f_account_configured'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoUserProfile
     */
    'has_ct_account'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoUserProfile
     */
    'has_fyt_account'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoUserProfile
     */
    'has_password'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoUserProfile
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DtoUserProfile
     */
    'last_name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DtoUserProfile
     */
    'linked_accounts'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DtoUserProfile
     */
    'phone'?: string;
    /**
     * 
     * @type {CommonProductFocus}
     * @memberof DtoUserProfile
     */
    'product_focus': CommonProductFocus;
    /**
     * 
     * @type {string}
     * @memberof DtoUserProfile
     */
    'username'?: string;
}


/**
 * 
 * @export
 * @interface DtoUserSettingsResponse
 */
export interface DtoUserSettingsResponse {
    /**
     * 
     * @type {number}
     * @memberof DtoUserSettingsResponse
     */
    'auto_renew_discount': number;
    /**
     * 
     * @type {CommonBrand}
     * @memberof DtoUserSettingsResponse
     */
    'brand'?: CommonBrand;
    /**
     * 
     * @type {boolean}
     * @memberof DtoUserSettingsResponse
     */
    'business_forms_allowed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoUserSettingsResponse
     */
    'extension_focus_file_smart_product'?: string;
    /**
     * 
     * @type {{ [key: string]: DtoFilingCardConfig; }}
     * @memberof DtoUserSettingsResponse
     */
    'filing_card_configs'?: { [key: string]: DtoFilingCardConfig; };
    /**
     * 
     * @type {string}
     * @memberof DtoUserSettingsResponse
     */
    'free_url': string;
    /**
     * 
     * @type {string}
     * @memberof DtoUserSettingsResponse
     */
    'funnel'?: string;
    /**
     * 
     * @type {{ [key: string]: Array<DtoOfferPageConfig>; }}
     * @memberof DtoUserSettingsResponse
     */
    'offer_pages'?: { [key: string]: Array<DtoOfferPageConfig>; };
    /**
     * 
     * @type {boolean}
     * @memberof DtoUserSettingsResponse
     */
    'personal_forms_allowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DtoUserSettingsResponse
     */
    'post_sell_enabled': boolean;
    /**
     * 
     * @type {Array<DtoProduct>}
     * @memberof DtoUserSettingsResponse
     */
    'products'?: Array<DtoProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof DtoUserSettingsResponse
     */
    'rating_set'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoUserSettingsResponse
     */
    'rating_set_at'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DtoUserSettingsResponse
     */
    'raw_values'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof DtoUserSettingsResponse
     */
    'return_focus_6_month_product'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoUserSettingsResponse
     */
    'return_focus_file_smart_product'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtoUserSettingsResponse
     */
    'return_focus_one_time_product'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoUserSettingsResponse
     */
    'stickies_enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DtoUserSettingsResponse
     */
    'tax_season_end'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DtoUserSettingsResponse
     */
    'upsell_enabled': boolean;
}


/**
 * 
 * @export
 * @interface DtoZipResponse
 */
export interface DtoZipResponse {
    /**
     * 
     * @type {string}
     * @memberof DtoZipResponse
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof DtoZipResponse
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof DtoZipResponse
     */
    'zip': string;
}
/**
 * 
 * @export
 * @interface F4868TaxFilingPlansRequest
 */
export interface F4868TaxFilingPlansRequest {
    /**
     * 
     * @type {string}
     * @memberof F4868TaxFilingPlansRequest
     */
    'filing_method'?: F4868TaxFilingPlansRequestFilingMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof F4868TaxFilingPlansRequest
     */
    'filing_time'?: F4868TaxFilingPlansRequestFilingTimeEnum;
    /**
     * 
     * @type {string}
     * @memberof F4868TaxFilingPlansRequest
     */
    'personal_form_id': string;
}

export const F4868TaxFilingPlansRequestFilingMethodEnum = {
    Diy: 'diy',
    Dfy: 'dfy'
} as const;

export type F4868TaxFilingPlansRequestFilingMethodEnum = typeof F4868TaxFilingPlansRequestFilingMethodEnum[keyof typeof F4868TaxFilingPlansRequestFilingMethodEnum];
export const F4868TaxFilingPlansRequestFilingTimeEnum = {
    Asap: 'asap',
    _13Months: '1-3 months',
    _6Months: '6+ months'
} as const;

export type F4868TaxFilingPlansRequestFilingTimeEnum = typeof F4868TaxFilingPlansRequestFilingTimeEnum[keyof typeof F4868TaxFilingPlansRequestFilingTimeEnum];

/**
 * 
 * @export
 * @interface F4868TaxFilingPlansResponse
 */
export interface F4868TaxFilingPlansResponse {
    /**
     * 
     * @type {string}
     * @memberof F4868TaxFilingPlansResponse
     */
    'filing_method'?: string;
    /**
     * 
     * @type {string}
     * @memberof F4868TaxFilingPlansResponse
     */
    'filing_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof F4868TaxFilingPlansResponse
     */
    'personal_form_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof F4868TaxFilingPlansResponse
     */
    'tax_year'?: string;
    /**
     * 
     * @type {string}
     * @memberof F4868TaxFilingPlansResponse
     */
    'user_id'?: string;
}
/**
 * 
 * @export
 * @interface FytBasicFedReturn
 */
export interface FytBasicFedReturn {
    /**
     * 
     * @type {string}
     * @memberof FytBasicFedReturn
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FytBasicFedReturn
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FytBasicFedReturn
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FytBasicFedReturn
     */
    'last_name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FytBasicFedReturn
     */
    'payment_complete'?: boolean;
    /**
     * 
     * @type {FytReturnStatus}
     * @memberof FytBasicFedReturn
     */
    'return_status'?: FytReturnStatus;
    /**
     * 
     * @type {Array<FytBasicStateReturn>}
     * @memberof FytBasicFedReturn
     */
    'state_returns'?: Array<FytBasicStateReturn>;
    /**
     * 
     * @type {string}
     * @memberof FytBasicFedReturn
     */
    'tax_year'?: string;
}


/**
 * 
 * @export
 * @interface FytBasicStateReturn
 */
export interface FytBasicStateReturn {
    /**
     * 
     * @type {string}
     * @memberof FytBasicStateReturn
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FytBasicStateReturn
     */
    'payment_complete'?: boolean;
    /**
     * 
     * @type {FytReturnStatus}
     * @memberof FytBasicStateReturn
     */
    'return_status'?: FytReturnStatus;
    /**
     * 
     * @type {string}
     * @memberof FytBasicStateReturn
     */
    'state_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof FytBasicStateReturn
     */
    'tax_year'?: string;
}


/**
 * 
 * @export
 * @interface FytDeviceId
 */
export interface FytDeviceId {
    /**
     * 
     * @type {string}
     * @memberof FytDeviceId
     */
    'device_id': string;
    /**
     * 
     * @type {string}
     * @memberof FytDeviceId
     */
    'platform'?: string;
    /**
     * 
     * @type {string}
     * @memberof FytDeviceId
     */
    'time_zone': string;
}
/**
 * 
 * @export
 * @interface FytLoginRequest
 */
export interface FytLoginRequest {
    /**
     * 
     * @type {FytDeviceId}
     * @memberof FytLoginRequest
     */
    'device_id': FytDeviceId;
    /**
     * 
     * @type {string}
     * @memberof FytLoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface FytLoginResponse
 */
export interface FytLoginResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof FytLoginResponse
     */
    'available_methods': Array<FytLoginResponseAvailableMethodsEnum>;
    /**
     * 
     * @type {string}
     * @memberof FytLoginResponse
     */
    'email_masked'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FytLoginResponse
     */
    'is_2f_required': boolean;
    /**
     * 
     * @type {string}
     * @memberof FytLoginResponse
     */
    'phone_masked'?: string;
    /**
     * 
     * @type {string}
     * @memberof FytLoginResponse
     */
    'redirect_url': string;
}

export const FytLoginResponseAvailableMethodsEnum = {
    Phone: 'phone',
    Email: 'email',
    Unknown: 'unknown'
} as const;

export type FytLoginResponseAvailableMethodsEnum = typeof FytLoginResponseAvailableMethodsEnum[keyof typeof FytLoginResponseAvailableMethodsEnum];

/**
 * 
 * @export
 * @interface FytRegistrationRequest
 */
export interface FytRegistrationRequest {
    /**
     * 
     * @type {FytDeviceId}
     * @memberof FytRegistrationRequest
     */
    'device_id': FytDeviceId;
    /**
     * 
     * @type {string}
     * @memberof FytRegistrationRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof FytRegistrationRequest
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof FytRegistrationRequest
     */
    'recaptcha': string;
}
/**
 * 
 * @export
 * @interface FytRegistrationResponse
 */
export interface FytRegistrationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FytRegistrationResponse
     */
    'second_factor_required': boolean;
    /**
     * 
     * @type {string}
     * @memberof FytRegistrationResponse
     */
    'url_to_redirect': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FytReturnStatus = {
    ReturnStatusUnspecified: 'unspecified',
    ReturnStatusPending: 'pending',
    ReturnStatusAccepted: 'accepted',
    ReturnStatusRejected: 'rejected',
    ReturnStatusReadyToFile: 'readyToFile',
    ReturnStatusNotReadyToFile: 'notReadyToFile'
} as const;

export type FytReturnStatus = typeof FytReturnStatus[keyof typeof FytReturnStatus];


/**
 * 
 * @export
 * @interface FytSecondFactorSelectRequest
 */
export interface FytSecondFactorSelectRequest {
    /**
     * 
     * @type {FytDeviceId}
     * @memberof FytSecondFactorSelectRequest
     */
    'device_id': FytDeviceId;
    /**
     * 
     * @type {string}
     * @memberof FytSecondFactorSelectRequest
     */
    'method': FytSecondFactorSelectRequestMethodEnum;
}

export const FytSecondFactorSelectRequestMethodEnum = {
    Phone: 'phone',
    Email: 'email'
} as const;

export type FytSecondFactorSelectRequestMethodEnum = typeof FytSecondFactorSelectRequestMethodEnum[keyof typeof FytSecondFactorSelectRequestMethodEnum];

/**
 * 
 * @export
 * @interface FytSecondFactorSelectResponse
 */
export interface FytSecondFactorSelectResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FytSecondFactorSelectResponse
     */
    'status'?: boolean;
}
/**
 * 
 * @export
 * @interface FytSecondFactorVerifyRequest
 */
export interface FytSecondFactorVerifyRequest {
    /**
     * 
     * @type {string}
     * @memberof FytSecondFactorVerifyRequest
     */
    'code'?: string;
    /**
     * 
     * @type {FytDeviceId}
     * @memberof FytSecondFactorVerifyRequest
     */
    'device_id': FytDeviceId;
    /**
     * 
     * @type {boolean}
     * @memberof FytSecondFactorVerifyRequest
     */
    'resend_code'?: boolean;
}
/**
 * 
 * @export
 * @interface FytSecondFactorVerifyResponse
 */
export interface FytSecondFactorVerifyResponse {
    /**
     * 
     * @type {string}
     * @memberof FytSecondFactorVerifyResponse
     */
    'redirect_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FytSecondFactorVerifyResponse
     */
    'requires_2fa'?: boolean;
}
/**
 * 
 * @export
 * @interface ManagementBase64File
 */
export interface ManagementBase64File {
    /**
     * 
     * @type {string}
     * @memberof ManagementBase64File
     */
    'data'?: string;
}
/**
 * 
 * @export
 * @interface ManagementImportOptions
 */
export interface ManagementImportOptions {
    /**
     * 
     * @type {string}
     * @memberof ManagementImportOptions
     */
    'device_id': string;
    /**
     * 
     * @type {string}
     * @memberof ManagementImportOptions
     */
    'document_id': string;
    /**
     * 
     * @type {string}
     * @memberof ManagementImportOptions
     */
    'document_range': string;
    /**
     * 
     * @type {string}
     * @memberof ManagementImportOptions
     */
    'ip_address'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ManagementImportOptions
     */
    'mark_as_payed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ManagementImportOptions
     */
    'user_agent': string;
    /**
     * 
     * @type {string}
     * @memberof ManagementImportOptions
     */
    'user_id': string;
    /**
     * 
     * @type {number}
     * @memberof ManagementImportOptions
     */
    'wait_for_submission_ack_minutes'?: number;
}
/**
 * 
 * @export
 * @interface MerchantsAddress
 */
export interface MerchantsAddress {
    /**
     * 
     * @type {string}
     * @memberof MerchantsAddress
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantsAddress
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantsAddress
     */
    'street_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantsAddress
     */
    'zip': string;
}
/**
 * 
 * @export
 * @interface MerchantsCardData
 */
export interface MerchantsCardData {
    /**
     * Number is the credit card number.
     * @type {string}
     * @memberof MerchantsCardData
     */
    'card_number': string;
    /**
     * Cvv is the credit card verification value.
     * @type {string}
     * @memberof MerchantsCardData
     */
    'cvv': string;
    /**
     * 
     * @type {MerchantsExpirationDate}
     * @memberof MerchantsCardData
     */
    'expiration_date': MerchantsExpirationDate;
}
/**
 * 
 * @export
 * @interface MerchantsClientSettings
 */
export interface MerchantsClientSettings {
    /**
     * 
     * @type {string}
     * @memberof MerchantsClientSettings
     */
    'js_lib_url'?: string;
    /**
     * 
     * @type {MerchantsStripeClientSettings}
     * @memberof MerchantsClientSettings
     */
    'stripe'?: MerchantsStripeClientSettings;
}
/**
 * 
 * @export
 * @interface MerchantsExpirationDate
 */
export interface MerchantsExpirationDate {
    /**
     * 
     * @type {number}
     * @memberof MerchantsExpirationDate
     */
    'month'?: number;
    /**
     * 
     * @type {number}
     * @memberof MerchantsExpirationDate
     */
    'year'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MerchantsMerchantType = {
    MerchantTypeAuthorize: 'authorize',
    MerchantTypePayeezy: 'payeezy',
    MerchantTypeStripe: 'stripe',
    MerchantTypeBofa: 'bofa',
    MerchantTypeClover: 'clover'
} as const;

export type MerchantsMerchantType = typeof MerchantsMerchantType[keyof typeof MerchantsMerchantType];


/**
 * 
 * @export
 * @interface MerchantsStripeClientSettings
 */
export interface MerchantsStripeClientSettings {
    /**
     * 
     * @type {string}
     * @memberof MerchantsStripeClientSettings
     */
    'publishable_key'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MerchantsStripeIntegrationType = {
    StripeIntegrationTypeCard: 'card',
    StripeIntegrationTypeApplePay: 'apple-pay',
    StripeIntegrationTypeGooglePay: 'google-pay',
    StripeIntegrationTypeLink: 'lin'
} as const;

export type MerchantsStripeIntegrationType = typeof MerchantsStripeIntegrationType[keyof typeof MerchantsStripeIntegrationType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ModelsColumntaxSubmissionStatus = {
    ColumntaxSubmissionStatusNotSubmitted: 'not_submitted',
    ColumntaxSubmissionStatusSubmitted: 'submitted',
    ColumntaxSubmissionStatusAccepted: 'accepted',
    ColumntaxSubmissionStatusRetryable: 'retryable',
    ColumntaxSubmissionStatusRejected: 'rejected'
} as const;

export type ModelsColumntaxSubmissionStatus = typeof ModelsColumntaxSubmissionStatus[keyof typeof ModelsColumntaxSubmissionStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ModelsColumntaxUserStatus = {
    ColumntaxUserStatusNotStarted: 'not_started',
    ColumntaxUserStatusStarted: 'started',
    ColumntaxUserStatusSubmitted: 'submitted'
} as const;

export type ModelsColumntaxUserStatus = typeof ModelsColumntaxUserStatus[keyof typeof ModelsColumntaxUserStatus];


/**
 * 
 * @export
 * @interface ModelsInvoice
 */
export interface ModelsInvoice {
    /**
     * 
     * @type {number}
     * @memberof ModelsInvoice
     */
    'amount_billed'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelsInvoice
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsInvoice
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelsInvoice
     */
    'invoice_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelsInvoice
     */
    'last4'?: string;
    /**
     * 
     * @type {Array<ModelsProductsShort>}
     * @memberof ModelsInvoice
     */
    'products'?: Array<ModelsProductsShort>;
}
/**
 * 
 * @export
 * @interface ModelsProduct
 */
export interface ModelsProduct {
    /**
     * BasePrice i.e. before any discounts
     * @type {number}
     * @memberof ModelsProduct
     */
    'base_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelsProduct
     */
    'code'?: string;
    /**
     * Conflicts contains SKU of the conflicting products
     * @type {Array<string>}
     * @memberof ModelsProduct
     */
    'conflicts'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ModelsProduct
     */
    'coupon_applied'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsProduct
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelsProduct
     */
    'deskpro_labels'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ModelsProduct
     */
    'deskpro_tag'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelsProduct
     */
    'funnel_tags'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ModelsProduct
     */
    'g1_conversion_label'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsProduct
     */
    'g2_conversion_label'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsProduct
     */
    'has_sub_products'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsProduct
     */
    'has_trial'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsProduct
     */
    'is_subscription'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelsProduct
     */
    'name'?: string;
    /**
     * Price to show if discount was applied on system level
     * @type {number}
     * @memberof ModelsProduct
     */
    'original_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelsProduct
     */
    'payment_interval'?: string;
    /**
     * Price is the price we show to the user. For trials it should be $0
     * @type {number}
     * @memberof ModelsProduct
     */
    'price'?: number;
    /**
     * If of the purchased product i.e. formId or subscriptionId
     * @type {string}
     * @memberof ModelsProduct
     */
    'product_id'?: string;
    /**
     * 
     * @type {CommonProductTypes}
     * @memberof ModelsProduct
     */
    'product_type'?: CommonProductTypes;
    /**
     * 
     * @type {string}
     * @memberof ModelsProduct
     */
    'sku'?: string;
    /**
     * 
     * @type {ModelsProductStatus}
     * @memberof ModelsProduct
     */
    'status'?: ModelsProductStatus;
    /**
     * 
     * @type {Array<ModelsProduct>}
     * @memberof ModelsProduct
     */
    'sub_products'?: Array<ModelsProduct>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelsProduct
     */
    'tags'?: Array<string>;
    /**
     * Trial interval in days
     * @type {string}
     * @memberof ModelsProduct
     */
    'trial_interval'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ModelsProductStatus = {
    ProductStatusActive: 'active',
    ProductStatusDraft: 'draft',
    ProductStatusDiscontinued: 'discontinued',
    ProductStatusDeprecated: 'deprecated',
    ProductStatusUnpublished: 'unpublished'
} as const;

export type ModelsProductStatus = typeof ModelsProductStatus[keyof typeof ModelsProductStatus];


/**
 * 
 * @export
 * @interface ModelsProductsShort
 */
export interface ModelsProductsShort {
    /**
     * 
     * @type {string}
     * @memberof ModelsProductsShort
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsProductsShort
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsProductsShort
     */
    'is_trial'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelsProductsShort
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsProductsShort
     */
    'payment_interval'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelsProductsShort
     */
    'price'?: number;
    /**
     * 
     * @type {CommonProductTypes}
     * @memberof ModelsProductsShort
     */
    'product_type'?: CommonProductTypes;
    /**
     * 
     * @type {string}
     * @memberof ModelsProductsShort
     */
    'purchased_product_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsProductsShort
     */
    'sku'?: string;
}


/**
 * 
 * @export
 * @interface ModelsUser
 */
export interface ModelsUser {
    /**
     * 
     * @type {Array<CommonAccessScopes>}
     * @memberof ModelsUser
     */
    'access_scopes'?: Array<CommonAccessScopes>;
    /**
     * 
     * @type {CommonBrand}
     * @memberof ModelsUser
     */
    'brand'?: CommonBrand;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'funnel'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsUser
     */
    'has_ct_acc'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsUser
     */
    'has_fyt_acc'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsUser
     */
    'has_password'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsUser
     */
    'is_disabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsUser
     */
    'is_payed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsUser
     */
    'is_verified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'last_name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelsUser
     */
    'linked_accounts'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'phone'?: string;
    /**
     * 
     * @type {CommonProductFocus}
     * @memberof ModelsUser
     */
    'product_focus'?: CommonProductFocus;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'product_focus_set_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'username'?: string;
}


/**
 * provide merchant configuration to be used in payment form.
 * @export
 * @interface PaymentMerchantConfig
 */
export interface PaymentMerchantConfig {
    /**
     * ClientContext context is required by bofa and clover integrations to construct a payment form and sign field values
     * @type {string}
     * @memberof PaymentMerchantConfig
     */
    'client_context'?: string;
    /**
     * MerchantCode is a merchant code. For example: sun, 1ten, bofa, clover. Used to pass it back during payment profile creation so backend knows what merchant configuration to use.
     * @type {string}
     * @memberof PaymentMerchantConfig
     */
    'merchant_code'?: string;
    /**
     * MerchantConfig is a merchant configuration that is different for every merchant engine.
     * @type {MerchantsClientSettings}
     * @memberof PaymentMerchantConfig
     */
    'merchant_config'?: MerchantsClientSettings;
    /**
     * MerchantEngine is a merchant engine. For example: payeezy, bofa, clover. That specifies what component should be used to construct a payment form.
     * @type {MerchantsMerchantType}
     * @memberof PaymentMerchantConfig
     */
    'merchant_engine'?: MerchantsMerchantType;
    /**
     * Additional merchant specific values
     * @type {string}
     * @memberof PaymentMerchantConfig
     */
    'stripe_payment_intent'?: string;
}


/**
 * 
 * @export
 * @interface PaymentPaymentContextRequest
 */
export interface PaymentPaymentContextRequest {
    /**
     * 
     * @type {DtoCart}
     * @memberof PaymentPaymentContextRequest
     */
    'cart'?: DtoCart;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentContextRequest
     */
    'context_type': PaymentPaymentContextRequestContextTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentContextRequest
     */
    'device_id': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentContextRequest
     */
    'hostname': string;
}

export const PaymentPaymentContextRequestContextTypeEnum = {
    Basic: 'basic',
    PaymentIntent: 'payment_intent'
} as const;

export type PaymentPaymentContextRequestContextTypeEnum = typeof PaymentPaymentContextRequestContextTypeEnum[keyof typeof PaymentPaymentContextRequestContextTypeEnum];

/**
 * 
 * @export
 * @interface PaymentPaymentProfileRequest
 */
export interface PaymentPaymentProfileRequest {
    /**
     * BillingAddress is the billing address associated with the payment method If only zip code is provided, other fields will be populated from the zip database required: false
     * @type {MerchantsAddress}
     * @memberof PaymentPaymentProfileRequest
     */
    'billing_address'?: MerchantsAddress;
    /**
     * CardOwnerFirstName is the first name of the card owner required: true
     * @type {string}
     * @memberof PaymentPaymentProfileRequest
     */
    'card_owner_first_name'?: string;
    /**
     * CardOwnerLastName is the last name of the card owner required: true
     * @type {string}
     * @memberof PaymentPaymentProfileRequest
     */
    'card_owner_last_name'?: string;
    /**
     * ClientToken is the token received from the payment gateway required: true
     * @type {string}
     * @memberof PaymentPaymentProfileRequest
     */
    'client_token'?: string;
    /**
     * DeviceID is the unique identifier of the device, required for all integrations required: true
     * @type {string}
     * @memberof PaymentPaymentProfileRequest
     */
    'device_id': string;
    /**
     * FullCardData contains complete card information (number, CVV, expiration) Only required for Authorize.net integration required: false
     * @type {MerchantsCardData}
     * @memberof PaymentPaymentProfileRequest
     */
    'full_card_data'?: MerchantsCardData;
    /**
     * IntegrationType is the type of integration used for Stripe and other merchants Enum: card, link, apple_pay, google_pay.  Only required for Stripe. Others should send `card` Affects the validation of the request fields
     * @type {MerchantsStripeIntegrationType}
     * @memberof PaymentPaymentProfileRequest
     */
    'integration': MerchantsStripeIntegrationType;
    /**
     * MerchantCode is a specific merchant integration code Examples: sun, 1ten, bofa, clover Note: sun and 1ten are both payeezy integrations, but use different configurations required: true
     * @type {string}
     * @memberof PaymentPaymentProfileRequest
     */
    'merchant_code'?: string;
    /**
     * MerchantEngine is the payment processing engine Enum: payeezy, bofa, clover, authorize, stripe required: true
     * @type {MerchantsMerchantType}
     * @memberof PaymentPaymentProfileRequest
     */
    'merchant_engine': MerchantsMerchantType;
    /**
     * PaymentContext represents the scenario in which the payment is being made Enum: funnel, checkout, profile, reactivation - funnel: Payment made after form creation or within a form funnel - checkout: Payment initiated on a landing page or during a standard checkout process - profile: User adding an additional card to their profile - reactivation: Payment made during subscription reactivation required: true
     * @type {string}
     * @memberof PaymentPaymentProfileRequest
     */
    'payment_context': PaymentPaymentProfileRequestPaymentContextEnum;
    /**
     * Source is the URL of the page that displayed the credit card form required: true
     * @type {string}
     * @memberof PaymentPaymentProfileRequest
     */
    'source': string;
}

export const PaymentPaymentProfileRequestPaymentContextEnum = {
    Funnel: 'funnel',
    Checkout: 'checkout',
    Profile: 'profile',
    Reactivation: 'reactivation'
} as const;

export type PaymentPaymentProfileRequestPaymentContextEnum = typeof PaymentPaymentProfileRequestPaymentContextEnum[keyof typeof PaymentPaymentProfileRequestPaymentContextEnum];

/**
 * 
 * @export
 * @interface PurchasesPurchase
 */
export interface PurchasesPurchase {
    /**
     * 
     * @type {string}
     * @memberof PurchasesPurchase
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasesPurchase
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasesPurchase
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasesPurchase
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PurchasesPurchase
     */
    'is_trial'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PurchasesPurchase
     */
    'last_payed'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasesPurchase
     */
    'last_updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasesPurchase
     */
    'name'?: string;
    /**
     * 
     * @type {CommonSubscriptionStatus}
     * @memberof PurchasesPurchase
     */
    'product_status': CommonSubscriptionStatus;
    /**
     * 
     * @type {CommonProductTypes}
     * @memberof PurchasesPurchase
     */
    'product_type': CommonProductTypes;
    /**
     * 
     * @type {string}
     * @memberof PurchasesPurchase
     */
    'purchased_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasesPurchase
     */
    'purchased_object_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasesPurchase
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasesPurchase
     */
    'valid_till'?: string;
}


/**
 * 
 * @export
 * @interface PurchasesUserPurchaseResponse
 */
export interface PurchasesUserPurchaseResponse {
    /**
     * 
     * @type {Array<CommonProductTypes>}
     * @memberof PurchasesUserPurchaseResponse
     */
    'available_producttypes': Array<CommonProductTypes>;
    /**
     * 
     * @type {Array<PurchasesPurchase>}
     * @memberof PurchasesUserPurchaseResponse
     */
    'purchases': Array<PurchasesPurchase>;
}
/**
 * 
 * @export
 * @interface RepositoryColumntaxFilings
 */
export interface RepositoryColumntaxFilings {
    /**
     * 
     * @type {string}
     * @memberof RepositoryColumntaxFilings
     */
    'consent_to_disclose_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryColumntaxFilings
     */
    'consent_to_use_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryColumntaxFilings
     */
    'consented_to_disclose'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryColumntaxFilings
     */
    'consented_to_use'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepositoryColumntaxFilings
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryColumntaxFilings
     */
    'email'?: string;
    /**
     * 
     * @type {RepositoryColumntaxSubmissionStatus}
     * @memberof RepositoryColumntaxFilings
     */
    'fed_submission_status'?: RepositoryColumntaxSubmissionStatus;
    /**
     * 
     * @type {string}
     * @memberof RepositoryColumntaxFilings
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryColumntaxFilings
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryColumntaxFilings
     */
    'is_ct_created'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepositoryColumntaxFilings
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryColumntaxFilings
     */
    'phone'?: string;
    /**
     * 
     * @type {Array<RepositoryStateStatus>}
     * @memberof RepositoryColumntaxFilings
     */
    'state_statuses'?: Array<RepositoryStateStatus>;
    /**
     * 
     * @type {number}
     * @memberof RepositoryColumntaxFilings
     */
    'tax_year'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepositoryColumntaxFilings
     */
    'updated_at'?: string;
    /**
     * 
     * @type {Array<RepositoryColumntaxUserEvent>}
     * @memberof RepositoryColumntaxFilings
     */
    'user_events'?: Array<RepositoryColumntaxUserEvent>;
    /**
     * 
     * @type {string}
     * @memberof RepositoryColumntaxFilings
     */
    'user_id'?: string;
    /**
     * 
     * @type {RepositoryColumntaxUserStatus}
     * @memberof RepositoryColumntaxFilings
     */
    'user_status'?: RepositoryColumntaxUserStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RepositoryColumntaxSubmissionStatus = {
    ColumntaxSubmissionStatusNotSubmitted: 'not_submitted',
    ColumntaxSubmissionStatusSubmitted: 'submitted',
    ColumntaxSubmissionStatusAccepted: 'accepted',
    ColumntaxSubmissionStatusRetryable: 'retryable',
    ColumntaxSubmissionStatusRejected: 'rejected'
} as const;

export type RepositoryColumntaxSubmissionStatus = typeof RepositoryColumntaxSubmissionStatus[keyof typeof RepositoryColumntaxSubmissionStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const RepositoryColumntaxUserEvent = {
    ColumntaxUserEventReachesPrequestionnaire: 'reaches_prequestionnaire',
    ColumntaxUserEventReachesTaxHome: 'reaches_tax_home',
    ColumntaxUserEventReachesBasicInfo: 'reaches_basic_info',
    ColumntaxUserEventReachesIncome: 'reaches_income',
    ColumntaxUserEventReachesCreditsAndDeductions: 'reaches_credits_and_deductions',
    ColumntaxUserEventReachesReviewAndFile: 'reaches_review_and_file',
    ColumntaxUserEventReachesQuestionnaire: 'reaches_questionnaire'
} as const;

export type RepositoryColumntaxUserEvent = typeof RepositoryColumntaxUserEvent[keyof typeof RepositoryColumntaxUserEvent];


/**
 * 
 * @export
 * @enum {string}
 */

export const RepositoryColumntaxUserStatus = {
    ColumntaxUserStatusNotStarted: 'not_started',
    ColumntaxUserStatusStarted: 'started',
    ColumntaxUserStatusSubmitted: 'submitted'
} as const;

export type RepositoryColumntaxUserStatus = typeof RepositoryColumntaxUserStatus[keyof typeof RepositoryColumntaxUserStatus];


/**
 * 
 * @export
 * @interface RepositoryDeskproResponse
 */
export interface RepositoryDeskproResponse {
    /**
     * 
     * @type {Array<RepositoryColumntaxFilings>}
     * @memberof RepositoryDeskproResponse
     */
    'ct_returns'?: Array<RepositoryColumntaxFilings>;
    /**
     * 
     * @type {Array<RepositoryGetDeskproDFYReturnsRow>}
     * @memberof RepositoryDeskproResponse
     */
    'dfy_returns'?: Array<RepositoryGetDeskproDFYReturnsRow>;
    /**
     * 
     * @type {Array<RepositoryGetDeskproRecordsRow>}
     * @memberof RepositoryDeskproResponse
     */
    'form_rows'?: Array<RepositoryGetDeskproRecordsRow>;
    /**
     * 
     * @type {Array<V1BasicFedReturn>}
     * @memberof RepositoryDeskproResponse
     */
    'fyt_returns'?: Array<V1BasicFedReturn>;
    /**
     * 
     * @type {Array<RepositoryGetDeskproPaymentProfilesRow>}
     * @memberof RepositoryDeskproResponse
     */
    'payment_profiles'?: Array<RepositoryGetDeskproPaymentProfilesRow>;
    /**
     * 
     * @type {Array<RepositoryGetDeskproUserPaymentsRow>}
     * @memberof RepositoryDeskproResponse
     */
    'payments'?: Array<RepositoryGetDeskproUserPaymentsRow>;
    /**
     * 
     * @type {Array<ModelsProduct>}
     * @memberof RepositoryDeskproResponse
     */
    'products'?: Array<ModelsProduct>;
    /**
     * 
     * @type {Array<RepositoryGetDeskproUserSubscriptionsRow>}
     * @memberof RepositoryDeskproResponse
     */
    'subscriptions'?: Array<RepositoryGetDeskproUserSubscriptionsRow>;
    /**
     * 
     * @type {ModelsUser}
     * @memberof RepositoryDeskproResponse
     */
    'user_info'?: ModelsUser;
    /**
     * 
     * @type {Array<RepositoryGetDeskproUserProductsRow>}
     * @memberof RepositoryDeskproResponse
     */
    'user_products'?: Array<RepositoryGetDeskproUserProductsRow>;
    /**
     * 
     * @type {Array<RepositoryGetDeskproUserPurchasesRow>}
     * @memberof RepositoryDeskproResponse
     */
    'user_purchases'?: Array<RepositoryGetDeskproUserPurchasesRow>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RepositoryDfyPaymentStatus = {
    DfyPaymentStatusUnpaid: 'unpaid',
    DfyPaymentStatusPartial: 'partial',
    DfyPaymentStatusPaid: 'paid'
} as const;

export type RepositoryDfyPaymentStatus = typeof RepositoryDfyPaymentStatus[keyof typeof RepositoryDfyPaymentStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const RepositoryDfyProcessStatus = {
    DfyProcessStatusNone: 'none',
    DfyProcessStatusSigned: 'signed',
    DfyProcessStatusInProgress: 'in-progress',
    DfyProcessStatusCompleted: 'completed'
} as const;

export type RepositoryDfyProcessStatus = typeof RepositoryDfyProcessStatus[keyof typeof RepositoryDfyProcessStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const RepositoryEngagementLetterStatus = {
    EngagementLetterStatusCompleted: 'completed',
    EngagementLetterStatusIncomplete: 'incomplete'
} as const;

export type RepositoryEngagementLetterStatus = typeof RepositoryEngagementLetterStatus[keyof typeof RepositoryEngagementLetterStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const RepositoryFormStatus = {
    FormStatusNew: 'new',
    FormStatusStarted: 'started',
    FormStatusComplete: 'complete',
    FormStatusQueued: 'queued',
    FormStatusPreparing: 'preparing',
    FormStatusAwaiting: 'awaiting',
    FormStatusAckReady: 'ack-ready'
} as const;

export type RepositoryFormStatus = typeof RepositoryFormStatus[keyof typeof RepositoryFormStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const RepositoryFormType = {
    FormTypePersonal: 'personal',
    FormTypeBusiness: 'business'
} as const;

export type RepositoryFormType = typeof RepositoryFormType[keyof typeof RepositoryFormType];


/**
 * 
 * @export
 * @interface RepositoryGetDeskproDFYReturnsRow
 */
export interface RepositoryGetDeskproDFYReturnsRow {
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'business_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'ip_address_at_signing'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'last_name'?: string;
    /**
     * 
     * @type {RepositoryEngagementLetterStatus}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'letter_status'?: RepositoryEngagementLetterStatus;
    /**
     * 
     * @type {RepositoryDfyPaymentStatus}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'payment_status'?: RepositoryDfyPaymentStatus;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'planning_completed'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'product_skus'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'purchase_ids'?: Array<string>;
    /**
     * 
     * @type {RepositoryFormType}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'return_type'?: RepositoryFormType;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'signature'?: string;
    /**
     * 
     * @type {RepositoryDfyProcessStatus}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'status'?: RepositoryDfyProcessStatus;
    /**
     * 
     * @type {number}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'tax_year'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'taxpayer_identification_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'time_signed'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproDFYReturnsRow
     */
    'user_id'?: string;
}


/**
 * 
 * @export
 * @interface RepositoryGetDeskproPaymentProfilesRow
 */
export interface RepositoryGetDeskproPaymentProfilesRow {
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproPaymentProfilesRow
     */
    'card_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproPaymentProfilesRow
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproPaymentProfilesRow
     */
    'expiration_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproPaymentProfilesRow
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproPaymentProfilesRow
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryGetDeskproPaymentProfilesRow
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryGetDeskproPaymentProfilesRow
     */
    'is_expiration_notified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryGetDeskproPaymentProfilesRow
     */
    'is_failing'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryGetDeskproPaymentProfilesRow
     */
    'is_primary'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproPaymentProfilesRow
     */
    'last4'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproPaymentProfilesRow
     */
    'last_name'?: string;
    /**
     * 
     * @type {RepositoryPaymentMerchant}
     * @memberof RepositoryGetDeskproPaymentProfilesRow
     */
    'merchant'?: RepositoryPaymentMerchant;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproPaymentProfilesRow
     */
    'updated_at'?: string;
}


/**
 * 
 * @export
 * @interface RepositoryGetDeskproRecordsRow
 */
export interface RepositoryGetDeskproRecordsRow {
    /**
     * 
     * @type {number}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'auto_renew_discount'?: number;
    /**
     * 
     * @type {RepositorySubscriptionStatus}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'auto_renew_status'?: RepositorySubscriptionStatus;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'auto_renew_timestamp'?: string;
    /**
     * 
     * @type {number}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'auto_renew_year'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'bundle_cancelled_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'bundle_next_billing_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'bundle_prev_billing_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'bundle_refund_at'?: string;
    /**
     * 
     * @type {RepositorySubscriptionStatus}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'bundle_status'?: RepositorySubscriptionStatus;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'business_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'cpa_credit_refund_at'?: string;
    /**
     * 
     * @type {RepositorySubscriptionStatus}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'cpa_credit_status'?: RepositorySubscriptionStatus;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'created_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'created_by_auto_extend'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'deskpro_user_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'diy_credit_refund_at'?: string;
    /**
     * 
     * @type {RepositorySubscriptionStatus}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'diy_credit_status'?: RepositorySubscriptionStatus;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'extension_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'first_name'?: string;
    /**
     * 
     * @type {RepositoryFormStatus}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'form_status'?: RepositoryFormStatus;
    /**
     * 
     * @type {RepositoryFormType}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'form_type'?: RepositoryFormType;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'ip_address'?: string;
    /**
     * 
     * @type {RepositorySubmissionStatus}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'irs_response'?: RepositorySubmissionStatus;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'merchant'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'payment_profile_status'?: boolean;
    /**
     * 
     * @type {RepositoryPaymentStatus}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'payment_status'?: RepositoryPaymentStatus;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'payment_transaction_tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'postmark'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'priority'?: boolean;
    /**
     * 
     * @type {Array<RepositoryProductsShort>}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'purchases'?: Array<RepositoryProductsShort>;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'shield_cancelled_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'shield_next_billing_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'shield_prev_billing_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'shield_refund_at'?: string;
    /**
     * 
     * @type {RepositorySubscriptionStatus}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'shield_status'?: RepositorySubscriptionStatus;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'spouse_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'spouse_last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'submission_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'submit_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'updated_at'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'user_auths'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'user_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof RepositoryGetDeskproRecordsRow
     */
    'year'?: number;
}


/**
 * 
 * @export
 * @interface RepositoryGetDeskproUserPaymentsRow
 */
export interface RepositoryGetDeskproUserPaymentsRow {
    /**
     * 
     * @type {number}
     * @memberof RepositoryGetDeskproUserPaymentsRow
     */
    'amount_billed'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserPaymentsRow
     */
    'bank_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserPaymentsRow
     */
    'bank_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserPaymentsRow
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserPaymentsRow
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserPaymentsRow
     */
    'invoice_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserPaymentsRow
     */
    'ip_address'?: string;
    /**
     * 
     * @type {RepositoryPaymentMerchant}
     * @memberof RepositoryGetDeskproUserPaymentsRow
     */
    'merchant'?: RepositoryPaymentMerchant;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserPaymentsRow
     */
    'payment_profile_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserPaymentsRow
     */
    'raw_error'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserPaymentsRow
     */
    'raw_response'?: string;
    /**
     * 
     * @type {RepositoryPaymentRecordType}
     * @memberof RepositoryGetDeskproUserPaymentsRow
     */
    'record_type'?: RepositoryPaymentRecordType;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryGetDeskproUserPaymentsRow
     */
    'transaction_complete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserPaymentsRow
     */
    'transaction_tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserPaymentsRow
     */
    'updated_at'?: string;
}


/**
 * 
 * @export
 * @interface RepositoryGetDeskproUserProductsRow
 */
export interface RepositoryGetDeskproUserProductsRow {
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserProductsRow
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserProductsRow
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryGetDeskproUserProductsRow
     */
    'is_trial'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserProductsRow
     */
    'last_payed'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserProductsRow
     */
    'product_instance_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserProductsRow
     */
    'product_sku'?: string;
    /**
     * 
     * @type {RepositoryProductTypes}
     * @memberof RepositoryGetDeskproUserProductsRow
     */
    'product_type'?: RepositoryProductTypes;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserProductsRow
     */
    'ref_payment_id'?: string;
    /**
     * 
     * @type {RepositorySubscriptionStatus}
     * @memberof RepositoryGetDeskproUserProductsRow
     */
    'status'?: RepositorySubscriptionStatus;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserProductsRow
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserProductsRow
     */
    'user_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserProductsRow
     */
    'valid_till'?: string;
}


/**
 * 
 * @export
 * @interface RepositoryGetDeskproUserPurchasesRow
 */
export interface RepositoryGetDeskproUserPurchasesRow {
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserPurchasesRow
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserPurchasesRow
     */
    'payment_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof RepositoryGetDeskproUserPurchasesRow
     */
    'price'?: number;
    /**
     * 
     * @type {RepositoryProductTypes}
     * @memberof RepositoryGetDeskproUserPurchasesRow
     */
    'product_type'?: RepositoryProductTypes;
    /**
     * 
     * @type {RepositoryPurchaseStatus}
     * @memberof RepositoryGetDeskproUserPurchasesRow
     */
    'purchase_status'?: RepositoryPurchaseStatus;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserPurchasesRow
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserPurchasesRow
     */
    'updated_at'?: string;
}


/**
 * 
 * @export
 * @interface RepositoryGetDeskproUserSubscriptionsRow
 */
export interface RepositoryGetDeskproUserSubscriptionsRow {
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'cancelled_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'charge_notified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'ignore_payments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'is_trial'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'last_error'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'last_payed'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'next_check'?: string;
    /**
     * 
     * @type {number}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'pre_charge_days'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'pre_charge_notified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'product'?: string;
    /**
     * 
     * @type {RepositoryProductTypes}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'product_type'?: RepositoryProductTypes;
    /**
     * 
     * @type {number}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'retries'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'schedule'?: string;
    /**
     * 
     * @type {RepositorySubscriptionStatus}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'status'?: RepositorySubscriptionStatus;
    /**
     * 
     * @type {number}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'suspend_notified_days_before'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepositoryGetDeskproUserSubscriptionsRow
     */
    'updated_at'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RepositoryPaymentMerchant = {
    PaymentMerchantTen: '1ten',
    PaymentMerchantSun: 'sun',
    PaymentMerchantPnc: 'pnc',
    PaymentMerchantAuthorize: 'authorize',
    PaymentMerchantBofa: 'bofa',
    PaymentMerchantTru: 'tru',
    PaymentMerchantS1st: 's1st',
    PaymentMerchantClover: 'clover',
    PaymentMerchantStripe: 'stripe'
} as const;

export type RepositoryPaymentMerchant = typeof RepositoryPaymentMerchant[keyof typeof RepositoryPaymentMerchant];


/**
 * 
 * @export
 * @enum {string}
 */

export const RepositoryPaymentRecordType = {
    PaymentRecordTypePayment: 'payment',
    PaymentRecordTypeRefund: 'refund',
    PaymentRecordTypeAuth: 'auth',
    PaymentRecordTypeTokenize: 'tokenize',
    PaymentRecordTypeCharge: 'charge'
} as const;

export type RepositoryPaymentRecordType = typeof RepositoryPaymentRecordType[keyof typeof RepositoryPaymentRecordType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RepositoryPaymentStatus = {
    PaymentStatusNone: 'none',
    PaymentStatusFailed: 'failed',
    PaymentStatusSuccess: 'success',
    PaymentStatusRefund: 'refund',
    PaymentStatusAbandoned: 'abandoned'
} as const;

export type RepositoryPaymentStatus = typeof RepositoryPaymentStatus[keyof typeof RepositoryPaymentStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const RepositoryProductTypes = {
    ProductTypesPersonal: 'personal',
    ProductTypesBusiness: 'business',
    ProductTypesShield: 'shield',
    ProductTypesPriority: 'priority',
    ProductTypesAutoExtend: 'auto-extend',
    ProductTypesTaxprep: 'taxprep',
    ProductTypesBundle: 'bundle',
    ProductTypesPrint: 'print',
    ProductTypesDiyReturn: 'diy-return',
    ProductTypesDfyReturn: 'dfy-return',
    ProductTypesBoir: 'boir'
} as const;

export type RepositoryProductTypes = typeof RepositoryProductTypes[keyof typeof RepositoryProductTypes];


/**
 * 
 * @export
 * @interface RepositoryProductsShort
 */
export interface RepositoryProductsShort {
    /**
     * 
     * @type {string}
     * @memberof RepositoryProductsShort
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryProductsShort
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryProductsShort
     */
    'is_trial'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepositoryProductsShort
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryProductsShort
     */
    'payment_interval'?: string;
    /**
     * 
     * @type {number}
     * @memberof RepositoryProductsShort
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof RepositoryProductsShort
     */
    'product_id'?: string;
    /**
     * 
     * @type {RepositoryProductTypes}
     * @memberof RepositoryProductsShort
     */
    'product_type'?: RepositoryProductTypes;
    /**
     * 
     * @type {string}
     * @memberof RepositoryProductsShort
     */
    'sku'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RepositoryPurchaseStatus = {
    PurchaseStatusPayed: 'payed',
    PurchaseStatusRefunded: 'refunded'
} as const;

export type RepositoryPurchaseStatus = typeof RepositoryPurchaseStatus[keyof typeof RepositoryPurchaseStatus];


/**
 * 
 * @export
 * @interface RepositoryStateStatus
 */
export interface RepositoryStateStatus {
    /**
     * 
     * @type {string}
     * @memberof RepositoryStateStatus
     */
    'state'?: string;
    /**
     * 
     * @type {RepositoryColumntaxSubmissionStatus}
     * @memberof RepositoryStateStatus
     */
    'submission_status'?: RepositoryColumntaxSubmissionStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RepositorySubmissionStatus = {
    SubmissionStatusNone: 'none',
    SubmissionStatusQueued: 'queued',
    SubmissionStatusSubmitted: 'submitted',
    SubmissionStatusAccepted: 'accepted',
    SubmissionStatusSubmissionFailure: 'submission-failure',
    SubmissionStatusAckRecoverableFailure: 'ack-recoverable-failure',
    SubmissionStatusAckNonrecoverableFailure: 'ack-nonrecoverable-failure',
    SubmissionStatusRetrying: 'retrying',
    SubmissionStatusSuccess: 'success'
} as const;

export type RepositorySubmissionStatus = typeof RepositorySubmissionStatus[keyof typeof RepositorySubmissionStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const RepositorySubscriptionStatus = {
    SubscriptionStatusYes: 'yes',
    SubscriptionStatusNo: 'no',
    SubscriptionStatusCancelled: 'cancelled',
    SubscriptionStatusPaymentFailed: 'payment-failed',
    SubscriptionStatusExpired: 'expired',
    SubscriptionStatusRefunded: 'refunded',
    SubscriptionStatusMatured: 'matured'
} as const;

export type RepositorySubscriptionStatus = typeof RepositorySubscriptionStatus[keyof typeof RepositorySubscriptionStatus];


/**
 * 
 * @export
 * @interface ResourcesClientPlannerAnswer
 */
export interface ResourcesClientPlannerAnswer {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourcesClientPlannerAnswer
     */
    'answer_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourcesClientPlannerAnswer
     */
    'form_ids'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ResourcesClientPlannerAnswer
     */
    'tax_year': number;
}
/**
 * 
 * @export
 * @interface ResourcesClientPlannerEvent
 */
export interface ResourcesClientPlannerEvent {
    /**
     * 
     * @type {CommonTaxPlannerEvent}
     * @memberof ResourcesClientPlannerEvent
     */
    'event': CommonTaxPlannerEvent;
}


/**
 * 
 * @export
 * @interface ResourcesPlannerAnswer
 */
export interface ResourcesPlannerAnswer {
    /**
     * 
     * @type {string}
     * @memberof ResourcesPlannerAnswer
     */
    'answer': string;
    /**
     * 
     * @type {Array<ResourcesPlannerForm>}
     * @memberof ResourcesPlannerAnswer
     */
    'forms': Array<ResourcesPlannerForm>;
    /**
     * 
     * @type {string}
     * @memberof ResourcesPlannerAnswer
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesPlannerAnswer
     */
    'is_selected': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcesPlannerAnswer
     */
    'is_visible': boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourcesPlannerAnswer
     */
    'section_id': string;
}
/**
 * 
 * @export
 * @interface ResourcesPlannerForm
 */
export interface ResourcesPlannerForm {
    /**
     * 
     * @type {string}
     * @memberof ResourcesPlannerForm
     */
    'answer_id': string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesPlannerForm
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesPlannerForm
     */
    'info': string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesPlannerForm
     */
    'recommended_form': string;
}
/**
 * 
 * @export
 * @interface ResourcesPlannerSection
 */
export interface ResourcesPlannerSection {
    /**
     * 
     * @type {Array<ResourcesPlannerAnswer>}
     * @memberof ResourcesPlannerSection
     */
    'answers': Array<ResourcesPlannerAnswer>;
    /**
     * 
     * @type {Array<ResourcesPlannerForm>}
     * @memberof ResourcesPlannerSection
     */
    'forms': Array<ResourcesPlannerForm>;
    /**
     * 
     * @type {string}
     * @memberof ResourcesPlannerSection
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesPlannerSection
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesPlannerSection
     */
    'step_id': string;
}
/**
 * 
 * @export
 * @interface ResourcesPlannerStep
 */
export interface ResourcesPlannerStep {
    /**
     * 
     * @type {string}
     * @memberof ResourcesPlannerStep
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesPlannerStep
     */
    'description_show_all': string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesPlannerStep
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesPlannerStep
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesPlannerStep
     */
    'question': string;
    /**
     * 
     * @type {Array<ResourcesPlannerSection>}
     * @memberof ResourcesPlannerStep
     */
    'sections': Array<ResourcesPlannerSection>;
}
/**
 * 
 * @export
 * @interface ResourcesResourceVaultRecord
 */
export interface ResourcesResourceVaultRecord {
    /**
     * 
     * @type {string}
     * @memberof ResourcesResourceVaultRecord
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesResourceVaultRecord
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesResourceVaultRecord
     */
    'image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesResourceVaultRecord
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesResourceVaultRecord
     */
    'section'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesResourceVaultRecord
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesResourceVaultRecord
     */
    'video_duration'?: string;
}
/**
 * 
 * @export
 * @interface ResourcesResourceVaultSection
 */
export interface ResourcesResourceVaultSection {
    /**
     * 
     * @type {string}
     * @memberof ResourcesResourceVaultSection
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesResourceVaultSection
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourcesResourceVaultSection
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SubscriptionsExpiredSubscriptionResponse
 */
export interface SubscriptionsExpiredSubscriptionResponse {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionsExpiredSubscriptionResponse
     */
    'expired_subscription_sku'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionsExpiredSubscriptionResponse
     */
    'has_expired_subscriptions'?: boolean;
}
/**
 * 
 * @export
 * @interface UploadContactUsFileRequest
 */
export interface UploadContactUsFileRequest {
    /**
     * this is a test file
     * @type {File}
     * @memberof UploadContactUsFileRequest
     */
    'file': File;
}
/**
 * 
 * @export
 * @interface UserContactUsRequest
 */
export interface UserContactUsRequest {
    /**
     * 
     * @type {string}
     * @memberof UserContactUsRequest
     */
    'company'?: string;
    /**
     * 
     * @type {UserDepartment}
     * @memberof UserContactUsRequest
     */
    'department': UserDepartment;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserContactUsRequest
     */
    'file_references'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserContactUsRequest
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserContactUsRequest
     */
    'text'?: string;
}


/**
 * 
 * @export
 * @interface UserContactUsResponse
 */
export interface UserContactUsResponse {
    /**
     * 
     * @type {string}
     * @memberof UserContactUsResponse
     */
    'reference_number'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserDepartment = {
    DepartmentExtensions: 'extensions',
    DepartmentReturns: 'returns',
    DepartmentShield: 'shield',
    DepartmentExperts: 'experts',
    DepartmentFiledirect: 'filedirect',
    DepartmentBoir: 'boir'
} as const;

export type UserDepartment = typeof UserDepartment[keyof typeof UserDepartment];


/**
 * 
 * @export
 * @interface UserProductFocusSetRequest
 */
export interface UserProductFocusSetRequest {
    /**
     * 
     * @type {CommonProductFocus}
     * @memberof UserProductFocusSetRequest
     */
    'product_focus'?: CommonProductFocus;
}


/**
 * 
 * @export
 * @interface UserSetPasswordRequest
 */
export interface UserSetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UserSetPasswordRequest
     */
    'new_password'?: string;
}
/**
 * 
 * @export
 * @interface UserTrafficSourceRequest
 */
export interface UserTrafficSourceRequest {
    /**
     * redtrack_click_id - just to double check it present
     * @type {string}
     * @memberof UserTrafficSourceRequest
     */
    'redtrack_click_id'?: string;
    /**
     * ref_id passed to the landing page
     * @type {string}
     * @memberof UserTrafficSourceRequest
     */
    'ref_id'?: string;
    /**
     * utm source passed via query string to the landing page
     * @type {string}
     * @memberof UserTrafficSourceRequest
     */
    'utm_source'?: string;
}
/**
 * 
 * @export
 * @interface UtilsArticle
 */
export interface UtilsArticle {
    /**
     * 
     * @type {string}
     * @memberof UtilsArticle
     */
    'category_names'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilsArticle
     */
    'comments'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilsArticle
     */
    'content'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilsArticle
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UtilsArticle
     */
    'rating_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilsArticle
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilsArticle
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilsArticle
     */
    'total_rating'?: number;
    /**
     * 
     * @type {number}
     * @memberof UtilsArticle
     */
    'view_count'?: number;
}
/**
 * 
 * @export
 * @interface UtilsContactUsRequest
 */
export interface UtilsContactUsRequest {
    /**
     * 
     * @type {string}
     * @memberof UtilsContactUsRequest
     */
    'company'?: string;
    /**
     * 
     * @type {UtilsDepartment}
     * @memberof UtilsContactUsRequest
     */
    'department'?: UtilsDepartment;
    /**
     * 
     * @type {string}
     * @memberof UtilsContactUsRequest
     */
    'email'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UtilsContactUsRequest
     */
    'file_references'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UtilsContactUsRequest
     */
    'full_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilsContactUsRequest
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilsContactUsRequest
     */
    'text'?: string;
}


/**
 * 
 * @export
 * @interface UtilsContactUsResponse
 */
export interface UtilsContactUsResponse {
    /**
     * 
     * @type {string}
     * @memberof UtilsContactUsResponse
     */
    'reference_number'?: string;
}
/**
 * 
 * @export
 * @interface UtilsCourse
 */
export interface UtilsCourse {
    /**
     * 
     * @type {string}
     * @memberof UtilsCourse
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof UtilsCourse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UtilsCourse
     */
    'link': string;
    /**
     * 
     * @type {string}
     * @memberof UtilsCourse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UtilsCourse
     */
    'time_to_read': number;
}
/**
 * 
 * @export
 * @interface UtilsDeductionAnswers
 */
export interface UtilsDeductionAnswers {
    /**
     * 
     * @type {Array<string>}
     * @memberof UtilsDeductionAnswers
     */
    'expenses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UtilsDeductionAnswers
     */
    'income_sources'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UtilsDepartment = {
    DepartmentExtensions: 'extensions',
    DepartmentReturns: 'returns',
    DepartmentShield: 'shield',
    DepartmentExperts: 'experts',
    DepartmentFiledirect: 'filedirect',
    DepartmentBoir: 'boir'
} as const;

export type UtilsDepartment = typeof UtilsDepartment[keyof typeof UtilsDepartment];


/**
 * 
 * @export
 * @interface UtilsPhoneValidationRequest
 */
export interface UtilsPhoneValidationRequest {
    /**
     * 
     * @type {string}
     * @memberof UtilsPhoneValidationRequest
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface UtilsProductShort
 */
export interface UtilsProductShort {
    /**
     * 
     * @type {number}
     * @memberof UtilsProductShort
     */
    'base_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilsProductShort
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilsProductShort
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UtilsProductShort
     */
    'funnel_tags'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UtilsProductShort
     */
    'has_trial'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilsProductShort
     */
    'is_subscription'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilsProductShort
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilsProductShort
     */
    'original_price'?: number;
    /**
     * 
     * @type {number}
     * @memberof UtilsProductShort
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilsProductShort
     */
    'product_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilsProductShort
     */
    'sku'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UtilsProductShort
     */
    'tags'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UtilsTaxPreparer
 */
export interface UtilsTaxPreparer {
    /**
     * 
     * @type {string}
     * @memberof UtilsTaxPreparer
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof UtilsTaxPreparer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UtilsTaxPreparer
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof UtilsTaxPreparer
     */
    'link': string;
}
/**
 * 
 * @export
 * @interface V1AckDetails
 */
export interface V1AckDetails {
    /**
     * 
     * @type {string}
     * @memberof V1AckDetails
     */
    'ack_status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof V1AckDetails
     */
    'edited_since_ack'?: boolean;
    /**
     * 
     * @type {Array<V1AckError>}
     * @memberof V1AckDetails
     */
    'errors'?: Array<V1AckError>;
    /**
     * 
     * @type {string}
     * @memberof V1AckDetails
     */
    'submission_id'?: string;
}
/**
 * 
 * @export
 * @interface V1AckError
 */
export interface V1AckError {
    /**
     * 
     * @type {string}
     * @memberof V1AckError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1AckError
     */
    'rule'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1AckError
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface V1BasicFedReturn
 */
export interface V1BasicFedReturn {
    /**
     * 
     * @type {V1AckDetails}
     * @memberof V1BasicFedReturn
     */
    'ack_details'?: V1AckDetails;
    /**
     * 
     * @type {string}
     * @memberof V1BasicFedReturn
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1BasicFedReturn
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1BasicFedReturn
     */
    'last_name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof V1BasicFedReturn
     */
    'payment_complete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof V1BasicFedReturn
     */
    'return_id'?: string;
    /**
     * 
     * @type {V1ReturnStatus}
     * @memberof V1BasicFedReturn
     */
    'return_status'?: V1ReturnStatus;
    /**
     * 
     * @type {Array<V1BasicStateReturn>}
     * @memberof V1BasicFedReturn
     */
    'state_returns'?: Array<V1BasicStateReturn>;
    /**
     * 
     * @type {string}
     * @memberof V1BasicFedReturn
     */
    'tax_year'?: string;
}


/**
 * 
 * @export
 * @interface V1BasicStateReturn
 */
export interface V1BasicStateReturn {
    /**
     * 
     * @type {V1AckDetails}
     * @memberof V1BasicStateReturn
     */
    'ack_details'?: V1AckDetails;
    /**
     * 
     * @type {string}
     * @memberof V1BasicStateReturn
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof V1BasicStateReturn
     */
    'payment_complete'?: boolean;
    /**
     * 
     * @type {V1ReturnStatus}
     * @memberof V1BasicStateReturn
     */
    'return_status'?: V1ReturnStatus;
    /**
     * 
     * @type {string}
     * @memberof V1BasicStateReturn
     */
    'state_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1BasicStateReturn
     */
    'tax_year'?: string;
}


/**
 * 
 * @export
 * @interface V1DeductionAnswer
 */
export interface V1DeductionAnswer {
    /**
     * 
     * @type {Array<V1Dedudction>}
     * @memberof V1DeductionAnswer
     */
    'deductions'?: Array<V1Dedudction>;
    /**
     * 
     * @type {string}
     * @memberof V1DeductionAnswer
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1DeductionAnswer
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1DeductionAnswer
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1DeductionAnswer
     */
    'page'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1DeductionAnswer
     */
    'question'?: string;
}
/**
 * 
 * @export
 * @interface V1Dedudction
 */
export interface V1Dedudction {
    /**
     * 
     * @type {string}
     * @memberof V1Dedudction
     */
    'answerId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1Dedudction
     */
    'answer_ids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof V1Dedudction
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Dedudction
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Dedudction
     */
    'instruction'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1Dedudction
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const V1ReturnStatus = {
    ReturnStatus_RETURN_STATUS_UNSPECIFIED: 0,
    ReturnStatus_RETURN_STATUS_PENDING: 1,
    ReturnStatus_RETURN_STATUS_ACCEPTED: 2,
    ReturnStatus_RETURN_STATUS_REJECTED: 3,
    ReturnStatus_RETURN_STATUS_READY_TO_FILE: 4,
    ReturnStatus_RETURN_STATUS_NOT_READY_TO_FILE: 5
} as const;

export type V1ReturnStatus = typeof V1ReturnStatus[keyof typeof V1ReturnStatus];



/**
 * BOIRApi - axios parameter creator
 * @export
 */
export const BOIRApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes a BOIR form
         * @summary Deletes a BOIR form
         * @param {string} formId Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBoirForm: async (formId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('deleteBoirForm', 'formId', formId)
            const localVarPath = `/boir/form/{form_id}`
                .replace(`{${"form_id"}}`, encodeURIComponent(String(formId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides all current user\'s BOIR forms
         * @summary Provides all current user\'s BOIR forms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBOIRForms: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/boir/forms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides the available count of BOIR forms
         * @summary Provides the available count of BOIR forms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableBoirFormCount: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/boir/available-forms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides the transcript of BOIR forms
         * @summary Provides the transcript of BOIR forms
         * @param {string} formId Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoirTranscript: async (formId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('getBoirTranscript', 'formId', formId)
            const localVarPath = `/documents/boir/{formId}/transcript.pdf`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BOIRApi - functional programming interface
 * @export
 */
export const BOIRApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BOIRApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes a BOIR form
         * @summary Deletes a BOIR form
         * @param {string} formId Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBoirForm(formId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBoirForm(formId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BOIRApi.deleteBoirForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provides all current user\'s BOIR forms
         * @summary Provides all current user\'s BOIR forms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBOIRForms(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppControllerBoirForm>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBOIRForms(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BOIRApi.getAllBOIRForms']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provides the available count of BOIR forms
         * @summary Provides the available count of BOIR forms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableBoirFormCount(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoirAvailableBoirFormCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableBoirFormCount(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BOIRApi.getAvailableBoirFormCount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provides the transcript of BOIR forms
         * @summary Provides the transcript of BOIR forms
         * @param {string} formId Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoirTranscript(formId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoirTranscript(formId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BOIRApi.getBoirTranscript']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BOIRApi - factory interface
 * @export
 */
export const BOIRApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BOIRApiFp(configuration)
    return {
        /**
         * Deletes a BOIR form
         * @summary Deletes a BOIR form
         * @param {string} formId Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBoirForm(formId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBoirForm(formId, options).then((request) => request(axios, basePath));
        },
        /**
         * Provides all current user\'s BOIR forms
         * @summary Provides all current user\'s BOIR forms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBOIRForms(options?: RawAxiosRequestConfig): AxiosPromise<Array<AppControllerBoirForm>> {
            return localVarFp.getAllBOIRForms(options).then((request) => request(axios, basePath));
        },
        /**
         * Provides the available count of BOIR forms
         * @summary Provides the available count of BOIR forms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableBoirFormCount(options?: RawAxiosRequestConfig): AxiosPromise<BoirAvailableBoirFormCountResponse> {
            return localVarFp.getAvailableBoirFormCount(options).then((request) => request(axios, basePath));
        },
        /**
         * Provides the transcript of BOIR forms
         * @summary Provides the transcript of BOIR forms
         * @param {string} formId Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoirTranscript(formId: string, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getBoirTranscript(formId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BOIRApi - object-oriented interface
 * @export
 * @class BOIRApi
 * @extends {BaseAPI}
 */
export class BOIRApi extends BaseAPI {
    /**
     * Deletes a BOIR form
     * @summary Deletes a BOIR form
     * @param {string} formId Form ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BOIRApi
     */
    public deleteBoirForm(formId: string, options?: RawAxiosRequestConfig) {
        return BOIRApiFp(this.configuration).deleteBoirForm(formId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides all current user\'s BOIR forms
     * @summary Provides all current user\'s BOIR forms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BOIRApi
     */
    public getAllBOIRForms(options?: RawAxiosRequestConfig) {
        return BOIRApiFp(this.configuration).getAllBOIRForms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides the available count of BOIR forms
     * @summary Provides the available count of BOIR forms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BOIRApi
     */
    public getAvailableBoirFormCount(options?: RawAxiosRequestConfig) {
        return BOIRApiFp(this.configuration).getAvailableBoirFormCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides the transcript of BOIR forms
     * @summary Provides the transcript of BOIR forms
     * @param {string} formId Form ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BOIRApi
     */
    public getBoirTranscript(formId: string, options?: RawAxiosRequestConfig) {
        return BOIRApiFp(this.configuration).getBoirTranscript(formId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BusinessFormsApi - axios parameter creator
 * @export
 */
export const BusinessFormsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new F7004 Business form and provides it\'s unique id. Other operations should be made using id provided
         * @summary Creates new F7004 Business form
         * @param {DtoF7004CreateRequest} request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBusinessForm: async (request: DtoF7004CreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createBusinessForm', 'request', request)
            const localVarPath = `/f7004`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets current user BusinessForms
         * @summary Gets current user BusinessForms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBusinessForms: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/f7004`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets current user BusinessForm
         * @summary Gets current user BusinessForm
         * @param {string} id Business Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessFormById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBusinessFormById', 'id', id)
            const localVarPath = `/f7004/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores Step 2 data of the business form
         * @summary Saves Step 2 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step2Request} businessFormStep2Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeBusinessFormStep2: async (id: string, businessFormStep2Request: DtoF7004Step2Request, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storeBusinessFormStep2', 'id', id)
            // verify required parameter 'businessFormStep2Request' is not null or undefined
            assertParamExists('storeBusinessFormStep2', 'businessFormStep2Request', businessFormStep2Request)
            const localVarPath = `/f7004/{id}/step2`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(businessFormStep2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores Step 3 data of the business form
         * @summary Saves Step 3 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step3Request} businessFormStep3Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeBusinessFormStep3: async (id: string, businessFormStep3Request: DtoF7004Step3Request, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storeBusinessFormStep3', 'id', id)
            // verify required parameter 'businessFormStep3Request' is not null or undefined
            assertParamExists('storeBusinessFormStep3', 'businessFormStep3Request', businessFormStep3Request)
            const localVarPath = `/f7004/{id}/step3`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(businessFormStep3Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores Step 4 data of the business form
         * @summary Saves Step 4 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step4Request} businessFormStep4Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeBusinessFormStep4: async (id: string, businessFormStep4Request: DtoF7004Step4Request, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storeBusinessFormStep4', 'id', id)
            // verify required parameter 'businessFormStep4Request' is not null or undefined
            assertParamExists('storeBusinessFormStep4', 'businessFormStep4Request', businessFormStep4Request)
            const localVarPath = `/f7004/{id}/step4`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(businessFormStep4Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores Step 5 data of the business form
         * @summary Saves Step 5 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step5Request} businessFormStep5Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeBusinessFormStep5: async (id: string, businessFormStep5Request: DtoF7004Step5Request, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storeBusinessFormStep5', 'id', id)
            // verify required parameter 'businessFormStep5Request' is not null or undefined
            assertParamExists('storeBusinessFormStep5', 'businessFormStep5Request', businessFormStep5Request)
            const localVarPath = `/f7004/{id}/step5`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(businessFormStep5Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores Step 6 data of the business form
         * @summary Saves Step 6 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step6Request} businessFormStep6Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeBusinessFormStep6: async (id: string, businessFormStep6Request: DtoF7004Step6Request, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storeBusinessFormStep6', 'id', id)
            // verify required parameter 'businessFormStep6Request' is not null or undefined
            assertParamExists('storeBusinessFormStep6', 'businessFormStep6Request', businessFormStep6Request)
            const localVarPath = `/f7004/{id}/step6`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(businessFormStep6Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores Step 7 data of the business form
         * @summary Saves Step 7 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step7Request} businessFormStep7Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeBusinessFormStep7: async (id: string, businessFormStep7Request: DtoF7004Step7Request, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storeBusinessFormStep7', 'id', id)
            // verify required parameter 'businessFormStep7Request' is not null or undefined
            assertParamExists('storeBusinessFormStep7', 'businessFormStep7Request', businessFormStep7Request)
            const localVarPath = `/f7004/{id}/step7`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(businessFormStep7Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores Step 8 data of the business form
         * @summary Saves Step 8 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step8Request} businessFormStep8Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeBusinessFormStep8: async (id: string, businessFormStep8Request: DtoF7004Step8Request, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storeBusinessFormStep8', 'id', id)
            // verify required parameter 'businessFormStep8Request' is not null or undefined
            assertParamExists('storeBusinessFormStep8', 'businessFormStep8Request', businessFormStep8Request)
            const localVarPath = `/f7004/{id}/step8`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(businessFormStep8Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores Step 9 data of the business form
         * @summary Saves Step 9 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step9Request} businessFormStep9Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeBusinessFormStep9: async (id: string, businessFormStep9Request: DtoF7004Step9Request, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storeBusinessFormStep9', 'id', id)
            // verify required parameter 'businessFormStep9Request' is not null or undefined
            assertParamExists('storeBusinessFormStep9', 'businessFormStep9Request', businessFormStep9Request)
            const localVarPath = `/f7004/{id}/step9`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(businessFormStep9Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Makes full business form data update. Should be done after all step info collected and form created.
         * @summary Makes full business form data update
         * @param {string} id Business Form ID
         * @param {DtoF7004UpdateFormRequest} businessFormUpdateRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessForm: async (id: string, businessFormUpdateRequest: DtoF7004UpdateFormRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBusinessForm', 'id', id)
            // verify required parameter 'businessFormUpdateRequest' is not null or undefined
            assertParamExists('updateBusinessForm', 'businessFormUpdateRequest', businessFormUpdateRequest)
            const localVarPath = `/f7004/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(businessFormUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submits user intention ot e-file extension
         * @summary Submits user intention ot e-file extension
         * @param {string} id Business Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        willEFileBusinessForm: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('willEFileBusinessForm', 'id', id)
            const localVarPath = `/f7004/{id}/e-file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessFormsApi - functional programming interface
 * @export
 */
export const BusinessFormsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessFormsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new F7004 Business form and provides it\'s unique id. Other operations should be made using id provided
         * @summary Creates new F7004 Business form
         * @param {DtoF7004CreateRequest} request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBusinessForm(request: DtoF7004CreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoF7004CreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBusinessForm(request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessFormsApi.createBusinessForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets current user BusinessForms
         * @summary Gets current user BusinessForms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBusinessForms(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DtoForm7004>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBusinessForms(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessFormsApi.getAllBusinessForms']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets current user BusinessForm
         * @summary Gets current user BusinessForm
         * @param {string} id Business Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessFormById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoForm7004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessFormById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessFormsApi.getBusinessFormById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores Step 2 data of the business form
         * @summary Saves Step 2 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step2Request} businessFormStep2Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeBusinessFormStep2(id: string, businessFormStep2Request: DtoF7004Step2Request, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeBusinessFormStep2(id, businessFormStep2Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessFormsApi.storeBusinessFormStep2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores Step 3 data of the business form
         * @summary Saves Step 3 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step3Request} businessFormStep3Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeBusinessFormStep3(id: string, businessFormStep3Request: DtoF7004Step3Request, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeBusinessFormStep3(id, businessFormStep3Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessFormsApi.storeBusinessFormStep3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores Step 4 data of the business form
         * @summary Saves Step 4 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step4Request} businessFormStep4Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeBusinessFormStep4(id: string, businessFormStep4Request: DtoF7004Step4Request, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeBusinessFormStep4(id, businessFormStep4Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessFormsApi.storeBusinessFormStep4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores Step 5 data of the business form
         * @summary Saves Step 5 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step5Request} businessFormStep5Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeBusinessFormStep5(id: string, businessFormStep5Request: DtoF7004Step5Request, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeBusinessFormStep5(id, businessFormStep5Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessFormsApi.storeBusinessFormStep5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores Step 6 data of the business form
         * @summary Saves Step 6 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step6Request} businessFormStep6Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeBusinessFormStep6(id: string, businessFormStep6Request: DtoF7004Step6Request, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeBusinessFormStep6(id, businessFormStep6Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessFormsApi.storeBusinessFormStep6']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores Step 7 data of the business form
         * @summary Saves Step 7 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step7Request} businessFormStep7Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeBusinessFormStep7(id: string, businessFormStep7Request: DtoF7004Step7Request, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeBusinessFormStep7(id, businessFormStep7Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessFormsApi.storeBusinessFormStep7']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores Step 8 data of the business form
         * @summary Saves Step 8 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step8Request} businessFormStep8Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeBusinessFormStep8(id: string, businessFormStep8Request: DtoF7004Step8Request, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeBusinessFormStep8(id, businessFormStep8Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessFormsApi.storeBusinessFormStep8']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores Step 9 data of the business form
         * @summary Saves Step 9 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step9Request} businessFormStep9Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeBusinessFormStep9(id: string, businessFormStep9Request: DtoF7004Step9Request, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeBusinessFormStep9(id, businessFormStep9Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessFormsApi.storeBusinessFormStep9']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Makes full business form data update. Should be done after all step info collected and form created.
         * @summary Makes full business form data update
         * @param {string} id Business Form ID
         * @param {DtoF7004UpdateFormRequest} businessFormUpdateRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBusinessForm(id: string, businessFormUpdateRequest: DtoF7004UpdateFormRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoF7004UpdateFormResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusinessForm(id, businessFormUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessFormsApi.updateBusinessForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Submits user intention ot e-file extension
         * @summary Submits user intention ot e-file extension
         * @param {string} id Business Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async willEFileBusinessForm(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.willEFileBusinessForm(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessFormsApi.willEFileBusinessForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BusinessFormsApi - factory interface
 * @export
 */
export const BusinessFormsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BusinessFormsApiFp(configuration)
    return {
        /**
         * Creates new F7004 Business form and provides it\'s unique id. Other operations should be made using id provided
         * @summary Creates new F7004 Business form
         * @param {DtoF7004CreateRequest} request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBusinessForm(request: DtoF7004CreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtoF7004CreateResponse> {
            return localVarFp.createBusinessForm(request, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets current user BusinessForms
         * @summary Gets current user BusinessForms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBusinessForms(options?: RawAxiosRequestConfig): AxiosPromise<Array<DtoForm7004>> {
            return localVarFp.getAllBusinessForms(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets current user BusinessForm
         * @summary Gets current user BusinessForm
         * @param {string} id Business Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessFormById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DtoForm7004> {
            return localVarFp.getBusinessFormById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores Step 2 data of the business form
         * @summary Saves Step 2 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step2Request} businessFormStep2Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeBusinessFormStep2(id: string, businessFormStep2Request: DtoF7004Step2Request, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.storeBusinessFormStep2(id, businessFormStep2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores Step 3 data of the business form
         * @summary Saves Step 3 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step3Request} businessFormStep3Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeBusinessFormStep3(id: string, businessFormStep3Request: DtoF7004Step3Request, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.storeBusinessFormStep3(id, businessFormStep3Request, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores Step 4 data of the business form
         * @summary Saves Step 4 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step4Request} businessFormStep4Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeBusinessFormStep4(id: string, businessFormStep4Request: DtoF7004Step4Request, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.storeBusinessFormStep4(id, businessFormStep4Request, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores Step 5 data of the business form
         * @summary Saves Step 5 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step5Request} businessFormStep5Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeBusinessFormStep5(id: string, businessFormStep5Request: DtoF7004Step5Request, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.storeBusinessFormStep5(id, businessFormStep5Request, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores Step 6 data of the business form
         * @summary Saves Step 6 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step6Request} businessFormStep6Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeBusinessFormStep6(id: string, businessFormStep6Request: DtoF7004Step6Request, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.storeBusinessFormStep6(id, businessFormStep6Request, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores Step 7 data of the business form
         * @summary Saves Step 7 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step7Request} businessFormStep7Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeBusinessFormStep7(id: string, businessFormStep7Request: DtoF7004Step7Request, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.storeBusinessFormStep7(id, businessFormStep7Request, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores Step 8 data of the business form
         * @summary Saves Step 8 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step8Request} businessFormStep8Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeBusinessFormStep8(id: string, businessFormStep8Request: DtoF7004Step8Request, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.storeBusinessFormStep8(id, businessFormStep8Request, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores Step 9 data of the business form
         * @summary Saves Step 9 of the business form
         * @param {string} id Business Form ID
         * @param {DtoF7004Step9Request} businessFormStep9Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeBusinessFormStep9(id: string, businessFormStep9Request: DtoF7004Step9Request, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.storeBusinessFormStep9(id, businessFormStep9Request, options).then((request) => request(axios, basePath));
        },
        /**
         * Makes full business form data update. Should be done after all step info collected and form created.
         * @summary Makes full business form data update
         * @param {string} id Business Form ID
         * @param {DtoF7004UpdateFormRequest} businessFormUpdateRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessForm(id: string, businessFormUpdateRequest: DtoF7004UpdateFormRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtoF7004UpdateFormResponse> {
            return localVarFp.updateBusinessForm(id, businessFormUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Submits user intention ot e-file extension
         * @summary Submits user intention ot e-file extension
         * @param {string} id Business Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        willEFileBusinessForm(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.willEFileBusinessForm(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessFormsApi - object-oriented interface
 * @export
 * @class BusinessFormsApi
 * @extends {BaseAPI}
 */
export class BusinessFormsApi extends BaseAPI {
    /**
     * Creates new F7004 Business form and provides it\'s unique id. Other operations should be made using id provided
     * @summary Creates new F7004 Business form
     * @param {DtoF7004CreateRequest} request Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessFormsApi
     */
    public createBusinessForm(request: DtoF7004CreateRequest, options?: RawAxiosRequestConfig) {
        return BusinessFormsApiFp(this.configuration).createBusinessForm(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets current user BusinessForms
     * @summary Gets current user BusinessForms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessFormsApi
     */
    public getAllBusinessForms(options?: RawAxiosRequestConfig) {
        return BusinessFormsApiFp(this.configuration).getAllBusinessForms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets current user BusinessForm
     * @summary Gets current user BusinessForm
     * @param {string} id Business Form ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessFormsApi
     */
    public getBusinessFormById(id: string, options?: RawAxiosRequestConfig) {
        return BusinessFormsApiFp(this.configuration).getBusinessFormById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores Step 2 data of the business form
     * @summary Saves Step 2 of the business form
     * @param {string} id Business Form ID
     * @param {DtoF7004Step2Request} businessFormStep2Request Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessFormsApi
     */
    public storeBusinessFormStep2(id: string, businessFormStep2Request: DtoF7004Step2Request, options?: RawAxiosRequestConfig) {
        return BusinessFormsApiFp(this.configuration).storeBusinessFormStep2(id, businessFormStep2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores Step 3 data of the business form
     * @summary Saves Step 3 of the business form
     * @param {string} id Business Form ID
     * @param {DtoF7004Step3Request} businessFormStep3Request Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessFormsApi
     */
    public storeBusinessFormStep3(id: string, businessFormStep3Request: DtoF7004Step3Request, options?: RawAxiosRequestConfig) {
        return BusinessFormsApiFp(this.configuration).storeBusinessFormStep3(id, businessFormStep3Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores Step 4 data of the business form
     * @summary Saves Step 4 of the business form
     * @param {string} id Business Form ID
     * @param {DtoF7004Step4Request} businessFormStep4Request Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessFormsApi
     */
    public storeBusinessFormStep4(id: string, businessFormStep4Request: DtoF7004Step4Request, options?: RawAxiosRequestConfig) {
        return BusinessFormsApiFp(this.configuration).storeBusinessFormStep4(id, businessFormStep4Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores Step 5 data of the business form
     * @summary Saves Step 5 of the business form
     * @param {string} id Business Form ID
     * @param {DtoF7004Step5Request} businessFormStep5Request Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessFormsApi
     */
    public storeBusinessFormStep5(id: string, businessFormStep5Request: DtoF7004Step5Request, options?: RawAxiosRequestConfig) {
        return BusinessFormsApiFp(this.configuration).storeBusinessFormStep5(id, businessFormStep5Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores Step 6 data of the business form
     * @summary Saves Step 6 of the business form
     * @param {string} id Business Form ID
     * @param {DtoF7004Step6Request} businessFormStep6Request Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessFormsApi
     */
    public storeBusinessFormStep6(id: string, businessFormStep6Request: DtoF7004Step6Request, options?: RawAxiosRequestConfig) {
        return BusinessFormsApiFp(this.configuration).storeBusinessFormStep6(id, businessFormStep6Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores Step 7 data of the business form
     * @summary Saves Step 7 of the business form
     * @param {string} id Business Form ID
     * @param {DtoF7004Step7Request} businessFormStep7Request Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessFormsApi
     */
    public storeBusinessFormStep7(id: string, businessFormStep7Request: DtoF7004Step7Request, options?: RawAxiosRequestConfig) {
        return BusinessFormsApiFp(this.configuration).storeBusinessFormStep7(id, businessFormStep7Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores Step 8 data of the business form
     * @summary Saves Step 8 of the business form
     * @param {string} id Business Form ID
     * @param {DtoF7004Step8Request} businessFormStep8Request Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessFormsApi
     */
    public storeBusinessFormStep8(id: string, businessFormStep8Request: DtoF7004Step8Request, options?: RawAxiosRequestConfig) {
        return BusinessFormsApiFp(this.configuration).storeBusinessFormStep8(id, businessFormStep8Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores Step 9 data of the business form
     * @summary Saves Step 9 of the business form
     * @param {string} id Business Form ID
     * @param {DtoF7004Step9Request} businessFormStep9Request Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessFormsApi
     */
    public storeBusinessFormStep9(id: string, businessFormStep9Request: DtoF7004Step9Request, options?: RawAxiosRequestConfig) {
        return BusinessFormsApiFp(this.configuration).storeBusinessFormStep9(id, businessFormStep9Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Makes full business form data update. Should be done after all step info collected and form created.
     * @summary Makes full business form data update
     * @param {string} id Business Form ID
     * @param {DtoF7004UpdateFormRequest} businessFormUpdateRequest Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessFormsApi
     */
    public updateBusinessForm(id: string, businessFormUpdateRequest: DtoF7004UpdateFormRequest, options?: RawAxiosRequestConfig) {
        return BusinessFormsApiFp(this.configuration).updateBusinessForm(id, businessFormUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submits user intention ot e-file extension
     * @summary Submits user intention ot e-file extension
     * @param {string} id Business Form ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessFormsApi
     */
    public willEFileBusinessForm(id: string, options?: RawAxiosRequestConfig) {
        return BusinessFormsApiFp(this.configuration).willEFileBusinessForm(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ColumnTaxApi - axios parameter creator
 * @export
 */
export const ColumnTaxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Edits a column tax filing for the current user
         * @summary EditFiling a column tax filing
         * @param {string} filingId Filing ID to edit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        columntaxFilingFilingIdPut: async (filingId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filingId' is not null or undefined
            assertParamExists('columntaxFilingFilingIdPut', 'filingId', filingId)
            const localVarPath = `/columntax/filing/{filingId}`
                .replace(`{${"filingId"}}`, encodeURIComponent(String(filingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Initiates a new column tax filing process for the current user
         * @summary Start a new column tax filing
         * @param {ColumntaxNewColumnTaxFilingRequest} request New Column Tax Filing Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        columntaxFilingPost: async (request: ColumntaxNewColumnTaxFilingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('columntaxFilingPost', 'request', request)
            const localVarPath = `/columntax/filing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Signs a consent to disclose
         * @summary SignConsentToDisclose
         * @param {ColumntaxConsentRequest} consentRequest Consent request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        columntaxSignConsentToDisclosePost: async (consentRequest: ColumntaxConsentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consentRequest' is not null or undefined
            assertParamExists('columntaxSignConsentToDisclosePost', 'consentRequest', consentRequest)
            const localVarPath = `/columntax/sign/consent-to-disclose`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Signs a consent to use
         * @summary Sign Consert to Use
         * @param {ColumntaxConsentRequest} consentRequest Consent request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        columntaxSignConsentToUsePost: async (consentRequest: ColumntaxConsentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consentRequest' is not null or undefined
            assertParamExists('columntaxSignConsentToUsePost', 'consentRequest', consentRequest)
            const localVarPath = `/columntax/sign/consent-to-use`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the return URL for the column tax filing
         * @summary Gets the return URL for the column tax filing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCTReturnURL: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/columntax/return-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets list of column tax returns
         * @summary Gets list of column tax returns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCTReturns: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/columntax/filings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides information about column tax settings for the current user
         * @summary Get column tax settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColumnTaxSettings: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/columntax/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ColumnTaxApi - functional programming interface
 * @export
 */
export const ColumnTaxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ColumnTaxApiAxiosParamCreator(configuration)
    return {
        /**
         * Edits a column tax filing for the current user
         * @summary EditFiling a column tax filing
         * @param {string} filingId Filing ID to edit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async columntaxFilingFilingIdPut(filingId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColumntaxTaxReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.columntaxFilingFilingIdPut(filingId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ColumnTaxApi.columntaxFilingFilingIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Initiates a new column tax filing process for the current user
         * @summary Start a new column tax filing
         * @param {ColumntaxNewColumnTaxFilingRequest} request New Column Tax Filing Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async columntaxFilingPost(request: ColumntaxNewColumnTaxFilingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColumntaxTaxReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.columntaxFilingPost(request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ColumnTaxApi.columntaxFilingPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Signs a consent to disclose
         * @summary SignConsentToDisclose
         * @param {ColumntaxConsentRequest} consentRequest Consent request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async columntaxSignConsentToDisclosePost(consentRequest: ColumntaxConsentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColumntaxConsentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.columntaxSignConsentToDisclosePost(consentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ColumnTaxApi.columntaxSignConsentToDisclosePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Signs a consent to use
         * @summary Sign Consert to Use
         * @param {ColumntaxConsentRequest} consentRequest Consent request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async columntaxSignConsentToUsePost(consentRequest: ColumntaxConsentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColumntaxConsentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.columntaxSignConsentToUsePost(consentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ColumnTaxApi.columntaxSignConsentToUsePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets the return URL for the column tax filing
         * @summary Gets the return URL for the column tax filing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCTReturnURL(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColumntaxReturnURLResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCTReturnURL(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ColumnTaxApi.getCTReturnURL']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets list of column tax returns
         * @summary Gets list of column tax returns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCTReturns(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ColumntaxTaxReturn>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCTReturns(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ColumnTaxApi.getCTReturns']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provides information about column tax settings for the current user
         * @summary Get column tax settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getColumnTaxSettings(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColumntaxSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getColumnTaxSettings(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ColumnTaxApi.getColumnTaxSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ColumnTaxApi - factory interface
 * @export
 */
export const ColumnTaxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ColumnTaxApiFp(configuration)
    return {
        /**
         * Edits a column tax filing for the current user
         * @summary EditFiling a column tax filing
         * @param {string} filingId Filing ID to edit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        columntaxFilingFilingIdPut(filingId: string, options?: RawAxiosRequestConfig): AxiosPromise<ColumntaxTaxReturn> {
            return localVarFp.columntaxFilingFilingIdPut(filingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Initiates a new column tax filing process for the current user
         * @summary Start a new column tax filing
         * @param {ColumntaxNewColumnTaxFilingRequest} request New Column Tax Filing Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        columntaxFilingPost(request: ColumntaxNewColumnTaxFilingRequest, options?: RawAxiosRequestConfig): AxiosPromise<ColumntaxTaxReturn> {
            return localVarFp.columntaxFilingPost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * Signs a consent to disclose
         * @summary SignConsentToDisclose
         * @param {ColumntaxConsentRequest} consentRequest Consent request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        columntaxSignConsentToDisclosePost(consentRequest: ColumntaxConsentRequest, options?: RawAxiosRequestConfig): AxiosPromise<ColumntaxConsentResponse> {
            return localVarFp.columntaxSignConsentToDisclosePost(consentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Signs a consent to use
         * @summary Sign Consert to Use
         * @param {ColumntaxConsentRequest} consentRequest Consent request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        columntaxSignConsentToUsePost(consentRequest: ColumntaxConsentRequest, options?: RawAxiosRequestConfig): AxiosPromise<ColumntaxConsentResponse> {
            return localVarFp.columntaxSignConsentToUsePost(consentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the return URL for the column tax filing
         * @summary Gets the return URL for the column tax filing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCTReturnURL(options?: RawAxiosRequestConfig): AxiosPromise<ColumntaxReturnURLResponse> {
            return localVarFp.getCTReturnURL(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets list of column tax returns
         * @summary Gets list of column tax returns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCTReturns(options?: RawAxiosRequestConfig): AxiosPromise<Array<ColumntaxTaxReturn>> {
            return localVarFp.getCTReturns(options).then((request) => request(axios, basePath));
        },
        /**
         * Provides information about column tax settings for the current user
         * @summary Get column tax settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColumnTaxSettings(options?: RawAxiosRequestConfig): AxiosPromise<ColumntaxSettings> {
            return localVarFp.getColumnTaxSettings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ColumnTaxApi - object-oriented interface
 * @export
 * @class ColumnTaxApi
 * @extends {BaseAPI}
 */
export class ColumnTaxApi extends BaseAPI {
    /**
     * Edits a column tax filing for the current user
     * @summary EditFiling a column tax filing
     * @param {string} filingId Filing ID to edit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColumnTaxApi
     */
    public columntaxFilingFilingIdPut(filingId: string, options?: RawAxiosRequestConfig) {
        return ColumnTaxApiFp(this.configuration).columntaxFilingFilingIdPut(filingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Initiates a new column tax filing process for the current user
     * @summary Start a new column tax filing
     * @param {ColumntaxNewColumnTaxFilingRequest} request New Column Tax Filing Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColumnTaxApi
     */
    public columntaxFilingPost(request: ColumntaxNewColumnTaxFilingRequest, options?: RawAxiosRequestConfig) {
        return ColumnTaxApiFp(this.configuration).columntaxFilingPost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Signs a consent to disclose
     * @summary SignConsentToDisclose
     * @param {ColumntaxConsentRequest} consentRequest Consent request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColumnTaxApi
     */
    public columntaxSignConsentToDisclosePost(consentRequest: ColumntaxConsentRequest, options?: RawAxiosRequestConfig) {
        return ColumnTaxApiFp(this.configuration).columntaxSignConsentToDisclosePost(consentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Signs a consent to use
     * @summary Sign Consert to Use
     * @param {ColumntaxConsentRequest} consentRequest Consent request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColumnTaxApi
     */
    public columntaxSignConsentToUsePost(consentRequest: ColumntaxConsentRequest, options?: RawAxiosRequestConfig) {
        return ColumnTaxApiFp(this.configuration).columntaxSignConsentToUsePost(consentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the return URL for the column tax filing
     * @summary Gets the return URL for the column tax filing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColumnTaxApi
     */
    public getCTReturnURL(options?: RawAxiosRequestConfig) {
        return ColumnTaxApiFp(this.configuration).getCTReturnURL(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets list of column tax returns
     * @summary Gets list of column tax returns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColumnTaxApi
     */
    public getCTReturns(options?: RawAxiosRequestConfig) {
        return ColumnTaxApiFp(this.configuration).getCTReturns(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides information about column tax settings for the current user
     * @summary Get column tax settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColumnTaxApi
     */
    public getColumnTaxSettings(options?: RawAxiosRequestConfig) {
        return ColumnTaxApiFp(this.configuration).getColumnTaxSettings(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DFYReturnsApi - axios parameter creator
 * @export
 */
export const DFYReturnsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Provides all current user\'s done for you returns
         * @summary Provides all current user\'s done for you returns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDFYReturns: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dfy/returns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Signs a DFY return
         * @summary Signs a DFY return
         * @param {string} id DFY Return ID
         * @param {DfySignReturnRequest} signRequest Provides user signature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signDFYReturn: async (id: string, signRequest: DfySignReturnRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('signDFYReturn', 'id', id)
            // verify required parameter 'signRequest' is not null or undefined
            assertParamExists('signDFYReturn', 'signRequest', signRequest)
            const localVarPath = `/dfy/sign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DFYReturnsApi - functional programming interface
 * @export
 */
export const DFYReturnsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DFYReturnsApiAxiosParamCreator(configuration)
    return {
        /**
         * Provides all current user\'s done for you returns
         * @summary Provides all current user\'s done for you returns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDFYReturns(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DfyTaxReturn>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDFYReturns(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DFYReturnsApi.getAllDFYReturns']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Signs a DFY return
         * @summary Signs a DFY return
         * @param {string} id DFY Return ID
         * @param {DfySignReturnRequest} signRequest Provides user signature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signDFYReturn(id: string, signRequest: DfySignReturnRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signDFYReturn(id, signRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DFYReturnsApi.signDFYReturn']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DFYReturnsApi - factory interface
 * @export
 */
export const DFYReturnsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DFYReturnsApiFp(configuration)
    return {
        /**
         * Provides all current user\'s done for you returns
         * @summary Provides all current user\'s done for you returns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDFYReturns(options?: RawAxiosRequestConfig): AxiosPromise<Array<DfyTaxReturn>> {
            return localVarFp.getAllDFYReturns(options).then((request) => request(axios, basePath));
        },
        /**
         * Signs a DFY return
         * @summary Signs a DFY return
         * @param {string} id DFY Return ID
         * @param {DfySignReturnRequest} signRequest Provides user signature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signDFYReturn(id: string, signRequest: DfySignReturnRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.signDFYReturn(id, signRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DFYReturnsApi - object-oriented interface
 * @export
 * @class DFYReturnsApi
 * @extends {BaseAPI}
 */
export class DFYReturnsApi extends BaseAPI {
    /**
     * Provides all current user\'s done for you returns
     * @summary Provides all current user\'s done for you returns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DFYReturnsApi
     */
    public getAllDFYReturns(options?: RawAxiosRequestConfig) {
        return DFYReturnsApiFp(this.configuration).getAllDFYReturns(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Signs a DFY return
     * @summary Signs a DFY return
     * @param {string} id DFY Return ID
     * @param {DfySignReturnRequest} signRequest Provides user signature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DFYReturnsApi
     */
    public signDFYReturn(id: string, signRequest: DfySignReturnRequest, options?: RawAxiosRequestConfig) {
        return DFYReturnsApiFp(this.configuration).signDFYReturn(id, signRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets current user BusinessForm
         * @summary Gets current user BusinessForm
         * @param {string} id Business Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessFormPDF: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBusinessFormPDF', 'id', id)
            const localVarPath = `/documents/f7004/{id}/form_7004.pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides invoice pdf download
         * @summary Provides invoice pdf download
         * @param {string} userId Invoice ID
         * @param {string} type Personal or Business
         * @param {string} signature Signature
         * @param {string} signDate Sign Date
         * @param {string} ein EIN for business or SSN for personal
         * @param {string} [selfUrl] Hostname of the callee in form of https://example.com
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEngagementLetterPdf: async (userId: string, type: string, signature: string, signDate: string, ein: string, selfUrl?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getEngagementLetterPdf', 'userId', userId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getEngagementLetterPdf', 'type', type)
            // verify required parameter 'signature' is not null or undefined
            assertParamExists('getEngagementLetterPdf', 'signature', signature)
            // verify required parameter 'signDate' is not null or undefined
            assertParamExists('getEngagementLetterPdf', 'signDate', signDate)
            // verify required parameter 'ein' is not null or undefined
            assertParamExists('getEngagementLetterPdf', 'ein', ein)
            const localVarPath = `/documents/dfy/letter/{user_id}/engagement_letter.pdf`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (selfUrl !== undefined) {
                localVarQueryParameter['self_url'] = selfUrl;
            }

            if (signature !== undefined) {
                localVarQueryParameter['signature'] = signature;
            }

            if (signDate !== undefined) {
                localVarQueryParameter['sign_date'] = signDate;
            }

            if (ein !== undefined) {
                localVarQueryParameter['ein'] = ein;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides invoice pdf download
         * @summary Provides invoice pdf download
         * @param {string} id Invoice ID
         * @param {string} seq Sequential number of the invoice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicePdf: async (id: string, seq: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInvoicePdf', 'id', id)
            // verify required parameter 'seq' is not null or undefined
            assertParamExists('getInvoicePdf', 'seq', seq)
            const localVarPath = `/documents/invoice/{id}/invoice_{seq}.pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"seq"}}`, encodeURIComponent(String(seq)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets current user PersonalForm
         * @summary Gets current user PersonalForm
         * @param {string} id PersonalForm ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalFormAck: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPersonalFormAck', 'id', id)
            const localVarPath = `/documents/form/{id}/acknowledgement.pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets current user PersonalForm
         * @summary Gets current user PersonalForm
         * @param {string} id PersonalForm ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalFormPDF: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPersonalFormPDF', 'id', id)
            const localVarPath = `/documents/f4868/{id}/form_4868.pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns list of the payments user made in short mode that can be considered as an invoices
         * @summary Returns list of user invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInvoices: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets current user BusinessForm
         * @summary Gets current user BusinessForm
         * @param {string} id Business Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessFormPDF(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessFormPDF(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.getBusinessFormPDF']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provides invoice pdf download
         * @summary Provides invoice pdf download
         * @param {string} userId Invoice ID
         * @param {string} type Personal or Business
         * @param {string} signature Signature
         * @param {string} signDate Sign Date
         * @param {string} ein EIN for business or SSN for personal
         * @param {string} [selfUrl] Hostname of the callee in form of https://example.com
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEngagementLetterPdf(userId: string, type: string, signature: string, signDate: string, ein: string, selfUrl?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEngagementLetterPdf(userId, type, signature, signDate, ein, selfUrl, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.getEngagementLetterPdf']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provides invoice pdf download
         * @summary Provides invoice pdf download
         * @param {string} id Invoice ID
         * @param {string} seq Sequential number of the invoice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoicePdf(id: string, seq: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoicePdf(id, seq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.getInvoicePdf']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets current user PersonalForm
         * @summary Gets current user PersonalForm
         * @param {string} id PersonalForm ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonalFormAck(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonalFormAck(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.getPersonalFormAck']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets current user PersonalForm
         * @summary Gets current user PersonalForm
         * @param {string} id PersonalForm ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonalFormPDF(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonalFormPDF(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.getPersonalFormPDF']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns list of the payments user made in short mode that can be considered as an invoices
         * @summary Returns list of user invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInvoices(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModelsInvoice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInvoices(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentsApi.getUserInvoices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsApiFp(configuration)
    return {
        /**
         * Gets current user BusinessForm
         * @summary Gets current user BusinessForm
         * @param {string} id Business Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessFormPDF(id: string, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getBusinessFormPDF(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Provides invoice pdf download
         * @summary Provides invoice pdf download
         * @param {string} userId Invoice ID
         * @param {string} type Personal or Business
         * @param {string} signature Signature
         * @param {string} signDate Sign Date
         * @param {string} ein EIN for business or SSN for personal
         * @param {string} [selfUrl] Hostname of the callee in form of https://example.com
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEngagementLetterPdf(userId: string, type: string, signature: string, signDate: string, ein: string, selfUrl?: string, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getEngagementLetterPdf(userId, type, signature, signDate, ein, selfUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * Provides invoice pdf download
         * @summary Provides invoice pdf download
         * @param {string} id Invoice ID
         * @param {string} seq Sequential number of the invoice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicePdf(id: string, seq: string, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getInvoicePdf(id, seq, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets current user PersonalForm
         * @summary Gets current user PersonalForm
         * @param {string} id PersonalForm ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalFormAck(id: string, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getPersonalFormAck(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets current user PersonalForm
         * @summary Gets current user PersonalForm
         * @param {string} id PersonalForm ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalFormPDF(id: string, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getPersonalFormPDF(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns list of the payments user made in short mode that can be considered as an invoices
         * @summary Returns list of user invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInvoices(options?: RawAxiosRequestConfig): AxiosPromise<Array<ModelsInvoice>> {
            return localVarFp.getUserInvoices(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
    /**
     * Gets current user BusinessForm
     * @summary Gets current user BusinessForm
     * @param {string} id Business Form ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getBusinessFormPDF(id: string, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getBusinessFormPDF(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides invoice pdf download
     * @summary Provides invoice pdf download
     * @param {string} userId Invoice ID
     * @param {string} type Personal or Business
     * @param {string} signature Signature
     * @param {string} signDate Sign Date
     * @param {string} ein EIN for business or SSN for personal
     * @param {string} [selfUrl] Hostname of the callee in form of https://example.com
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getEngagementLetterPdf(userId: string, type: string, signature: string, signDate: string, ein: string, selfUrl?: string, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getEngagementLetterPdf(userId, type, signature, signDate, ein, selfUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides invoice pdf download
     * @summary Provides invoice pdf download
     * @param {string} id Invoice ID
     * @param {string} seq Sequential number of the invoice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getInvoicePdf(id: string, seq: string, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getInvoicePdf(id, seq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets current user PersonalForm
     * @summary Gets current user PersonalForm
     * @param {string} id PersonalForm ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getPersonalFormAck(id: string, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getPersonalFormAck(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets current user PersonalForm
     * @summary Gets current user PersonalForm
     * @param {string} id PersonalForm ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getPersonalFormPDF(id: string, options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getPersonalFormPDF(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns list of the payments user made in short mode that can be considered as an invoices
     * @summary Returns list of user invoices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getUserInvoices(options?: RawAxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getUserInvoices(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FYTApi - axios parameter creator
 * @export
 */
export const FYTApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
         * @summary Performs registration of the user in FYT
         * @param {FytRegistrationRequest} request Registration Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fytAccCreate: async (request: FytRegistrationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('fytAccCreate', 'request', request)
            const localVarPath = `/fyt/user/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
         * @summary Check weither we have FYT account setup and ready
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fytAccExists: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fyt/user/exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
         * @summary Selects second factors
         * @param {FytSecondFactorSelectRequest} request Login Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fytFactorSelect: async (request: FytSecondFactorSelectRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('fytFactorSelect', 'request', request)
            const localVarPath = `/fyt/user/verify/select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
         * @summary Selects second factors
         * @param {FytSecondFactorVerifyRequest} request Login Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fytFactorVerify: async (request: FytSecondFactorVerifyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('fytFactorVerify', 'request', request)
            const localVarPath = `/fyt/user/verify/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
         * @summary Logs user int into DIY platform
         * @param {FytLoginRequest} request Login Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fytLogin: async (request: FytLoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('fytLogin', 'request', request)
            const localVarPath = `/fyt/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FYTApi - functional programming interface
 * @export
 */
export const FYTApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FYTApiAxiosParamCreator(configuration)
    return {
        /**
         * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
         * @summary Performs registration of the user in FYT
         * @param {FytRegistrationRequest} request Registration Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fytAccCreate(request: FytRegistrationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FytRegistrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fytAccCreate(request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FYTApi.fytAccCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
         * @summary Check weither we have FYT account setup and ready
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fytAccExists(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fytAccExists(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FYTApi.fytAccExists']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
         * @summary Selects second factors
         * @param {FytSecondFactorSelectRequest} request Login Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fytFactorSelect(request: FytSecondFactorSelectRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FytSecondFactorSelectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fytFactorSelect(request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FYTApi.fytFactorSelect']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
         * @summary Selects second factors
         * @param {FytSecondFactorVerifyRequest} request Login Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fytFactorVerify(request: FytSecondFactorVerifyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FytSecondFactorVerifyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fytFactorVerify(request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FYTApi.fytFactorVerify']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
         * @summary Logs user int into DIY platform
         * @param {FytLoginRequest} request Login Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fytLogin(request: FytLoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FytLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fytLogin(request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FYTApi.fytLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FYTApi - factory interface
 * @export
 */
export const FYTApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FYTApiFp(configuration)
    return {
        /**
         * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
         * @summary Performs registration of the user in FYT
         * @param {FytRegistrationRequest} request Registration Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fytAccCreate(request: FytRegistrationRequest, options?: RawAxiosRequestConfig): AxiosPromise<FytRegistrationResponse> {
            return localVarFp.fytAccCreate(request, options).then((request) => request(axios, basePath));
        },
        /**
         * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
         * @summary Check weither we have FYT account setup and ready
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fytAccExists(options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.fytAccExists(options).then((request) => request(axios, basePath));
        },
        /**
         * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
         * @summary Selects second factors
         * @param {FytSecondFactorSelectRequest} request Login Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fytFactorSelect(request: FytSecondFactorSelectRequest, options?: RawAxiosRequestConfig): AxiosPromise<FytSecondFactorSelectResponse> {
            return localVarFp.fytFactorSelect(request, options).then((request) => request(axios, basePath));
        },
        /**
         * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
         * @summary Selects second factors
         * @param {FytSecondFactorVerifyRequest} request Login Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fytFactorVerify(request: FytSecondFactorVerifyRequest, options?: RawAxiosRequestConfig): AxiosPromise<FytSecondFactorVerifyResponse> {
            return localVarFp.fytFactorVerify(request, options).then((request) => request(axios, basePath));
        },
        /**
         * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
         * @summary Logs user int into DIY platform
         * @param {FytLoginRequest} request Login Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fytLogin(request: FytLoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<FytLoginResponse> {
            return localVarFp.fytLogin(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FYTApi - object-oriented interface
 * @export
 * @class FYTApi
 * @extends {BaseAPI}
 */
export class FYTApi extends BaseAPI {
    /**
     * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
     * @summary Performs registration of the user in FYT
     * @param {FytRegistrationRequest} request Registration Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FYTApi
     */
    public fytAccCreate(request: FytRegistrationRequest, options?: RawAxiosRequestConfig) {
        return FYTApiFp(this.configuration).fytAccCreate(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
     * @summary Check weither we have FYT account setup and ready
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FYTApi
     */
    public fytAccExists(options?: RawAxiosRequestConfig) {
        return FYTApiFp(this.configuration).fytAccExists(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
     * @summary Selects second factors
     * @param {FytSecondFactorSelectRequest} request Login Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FYTApi
     */
    public fytFactorSelect(request: FytSecondFactorSelectRequest, options?: RawAxiosRequestConfig) {
        return FYTApiFp(this.configuration).fytFactorSelect(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
     * @summary Selects second factors
     * @param {FytSecondFactorVerifyRequest} request Login Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FYTApi
     */
    public fytFactorVerify(request: FytSecondFactorVerifyRequest, options?: RawAxiosRequestConfig) {
        return FYTApiFp(this.configuration).fytFactorVerify(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Flow changes based on fyt acc exists or not. If not we need to execute registration flow, else login flow
     * @summary Logs user int into DIY platform
     * @param {FytLoginRequest} request Login Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FYTApi
     */
    public fytLogin(request: FytLoginRequest, options?: RawAxiosRequestConfig) {
        return FYTApiFp(this.configuration).fytLogin(request, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FormHelpersApi - axios parameter creator
 * @export
 */
export const FormHelpersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets filing answers on tax filing plans questions
         * @summary Gets filing answers on tax filing plans questions
         * @param {string} formId id of the personal form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxFilingPlans: async (formId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('getTaxFilingPlans', 'formId', formId)
            const localVarPath = `/tax-filing-plans/{formId}`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores user\'s answers on tax filing plans questions
         * @summary Stores user\'s answers on tax filing plans questions
         * @param {F4868TaxFilingPlansRequest} taxFilingPlansRequest User tax filing plan answers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTaxFilingPlans: async (taxFilingPlansRequest: F4868TaxFilingPlansRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taxFilingPlansRequest' is not null or undefined
            assertParamExists('setTaxFilingPlans', 'taxFilingPlansRequest', taxFilingPlansRequest)
            const localVarPath = `/tax-filing-plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taxFilingPlansRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormHelpersApi - functional programming interface
 * @export
 */
export const FormHelpersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormHelpersApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets filing answers on tax filing plans questions
         * @summary Gets filing answers on tax filing plans questions
         * @param {string} formId id of the personal form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaxFilingPlans(formId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<F4868TaxFilingPlansResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaxFilingPlans(formId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormHelpersApi.getTaxFilingPlans']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores user\'s answers on tax filing plans questions
         * @summary Stores user\'s answers on tax filing plans questions
         * @param {F4868TaxFilingPlansRequest} taxFilingPlansRequest User tax filing plan answers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTaxFilingPlans(taxFilingPlansRequest: F4868TaxFilingPlansRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<F4868TaxFilingPlansResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTaxFilingPlans(taxFilingPlansRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormHelpersApi.setTaxFilingPlans']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FormHelpersApi - factory interface
 * @export
 */
export const FormHelpersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormHelpersApiFp(configuration)
    return {
        /**
         * Gets filing answers on tax filing plans questions
         * @summary Gets filing answers on tax filing plans questions
         * @param {string} formId id of the personal form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxFilingPlans(formId: string, options?: RawAxiosRequestConfig): AxiosPromise<F4868TaxFilingPlansResponse> {
            return localVarFp.getTaxFilingPlans(formId, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores user\'s answers on tax filing plans questions
         * @summary Stores user\'s answers on tax filing plans questions
         * @param {F4868TaxFilingPlansRequest} taxFilingPlansRequest User tax filing plan answers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTaxFilingPlans(taxFilingPlansRequest: F4868TaxFilingPlansRequest, options?: RawAxiosRequestConfig): AxiosPromise<F4868TaxFilingPlansResponse> {
            return localVarFp.setTaxFilingPlans(taxFilingPlansRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FormHelpersApi - object-oriented interface
 * @export
 * @class FormHelpersApi
 * @extends {BaseAPI}
 */
export class FormHelpersApi extends BaseAPI {
    /**
     * Gets filing answers on tax filing plans questions
     * @summary Gets filing answers on tax filing plans questions
     * @param {string} formId id of the personal form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormHelpersApi
     */
    public getTaxFilingPlans(formId: string, options?: RawAxiosRequestConfig) {
        return FormHelpersApiFp(this.configuration).getTaxFilingPlans(formId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores user\'s answers on tax filing plans questions
     * @summary Stores user\'s answers on tax filing plans questions
     * @param {F4868TaxFilingPlansRequest} taxFilingPlansRequest User tax filing plan answers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormHelpersApi
     */
    public setTaxFilingPlans(taxFilingPlansRequest: F4868TaxFilingPlansRequest, options?: RawAxiosRequestConfig) {
        return FormHelpersApiFp(this.configuration).setTaxFilingPlans(taxFilingPlansRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ManagementApi - axios parameter creator
 * @export
 */
export const ManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Sends custom email for beneficial owner
         * @summary Sends custom email for beneficial owner
         * @param {string} userId User ID to get info for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beneficialOwnerDeskproStats: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('beneficialOwnerDeskproStats', 'userId', userId)
            const localVarPath = `/management/boir/deskpro/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancels any subscription by provided form id and type and sends appropriate email
         * @summary Cancels any subscription by provided form id and type
         * @param {string} subscriptionId Subscription ID to cancel
         * @param {string} ticketId Id of the ticket to run macro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription: async (subscriptionId: string, ticketId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('cancelSubscription', 'subscriptionId', subscriptionId)
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('cancelSubscription', 'ticketId', ticketId)
            const localVarPath = `/management/subscription/cancel/{subscriptionId}`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)

            if (ticketId !== undefined) {
                localVarQueryParameter['ticket_id'] = ticketId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends custom email
         * @summary Sends custom email
         * @param {string} userId User ID to get info for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deskproStats: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deskproStats', 'userId', userId)
            const localVarPath = `/management/user/deskpro/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends custom email
         * @summary Sends custom email
         * @param {string} userId User ID to get info for
         * @param {string} dfyReturnId ReturnID to get Enagelement letter for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEngagementLetterDeskpro: async (userId: string, dfyReturnId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getEngagementLetterDeskpro', 'userId', userId)
            // verify required parameter 'dfyReturnId' is not null or undefined
            assertParamExists('getEngagementLetterDeskpro', 'dfyReturnId', dfyReturnId)
            const localVarPath = `/management/users/{userId}/deskpro/dfy/letter/{dfyReturnId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"dfyReturnId"}}`, encodeURIComponent(String(dfyReturnId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancels any subscription by provided form id and type and sends appropriate email
         * @summary Cancels any subscription by provided form id and type
         * @param {ManagementImportOptions} personalFormStep3Request Options for the import
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importBusinessForm: async (personalFormStep3Request: ManagementImportOptions, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personalFormStep3Request' is not null or undefined
            assertParamExists('importBusinessForm', 'personalFormStep3Request', personalFormStep3Request)
            const localVarPath = `/management/import/business`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personalFormStep3Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancels any subscription by provided form id and type and sends appropriate email
         * @summary Cancels any subscription by provided form id and type
         * @param {ManagementImportOptions} personalFormStep3Request Options for the import
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPersonalForm: async (personalFormStep3Request: ManagementImportOptions, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personalFormStep3Request' is not null or undefined
            assertParamExists('importPersonalForm', 'personalFormStep3Request', personalFormStep3Request)
            const localVarPath = `/management/import/personal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personalFormStep3Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unsubscribes provided forms from ontraport messaging. Uses unsub_list
         * @summary Unsubscribes provided forms from ontraport messaging. Uses unsub_list
         * @param {Array<string>} formIds Form Ids to unsubscribe divided by ; (semicolon)
         * @param {string} unsubList list of ids to unsubscribe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ontraportUnsubscribeLists: async (formIds: Array<string>, unsubList: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formIds' is not null or undefined
            assertParamExists('ontraportUnsubscribeLists', 'formIds', formIds)
            // verify required parameter 'unsubList' is not null or undefined
            assertParamExists('ontraportUnsubscribeLists', 'unsubList', unsubList)
            const localVarPath = `/management/ontraport/forms/unsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)

            if (formIds) {
                localVarQueryParameter['form_ids'] = formIds.join(COLLECTION_FORMATS.csv);
            }

            if (unsubList !== undefined) {
                localVarQueryParameter['unsub_list'] = unsubList;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unsubscribes all user forms from ontraport. Uses unsub_list
         * @summary Unsubscribes all user forms from ontraport. Uses unsub_list
         * @param {string} email Client\&#39;s email
         * @param {Array<string>} unsubList list of ids to unsubscribe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ontraportUserUnsubscribe: async (email: string, unsubList: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('ontraportUserUnsubscribe', 'email', email)
            // verify required parameter 'unsubList' is not null or undefined
            assertParamExists('ontraportUserUnsubscribe', 'unsubList', unsubList)
            const localVarPath = `/management/ontraport/users/unsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (unsubList) {
                localVarQueryParameter['unsub_list'] = unsubList.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancels any subscription by provided form id and type and sends appropriate email
         * @summary Cancels any subscription by provided form id and type
         * @param {string} userId Deskpro id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purgeUser: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('purgeUser', 'userId', userId)
            const localVarPath = `/management/user/purge/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fully refunds payment based on id provided
         * @summary Refunds payment by provided id
         * @param {string} paymentId Id of the payment to refund
         * @param {string} userId Id of the user to refund payment for
         * @param {string} ticketId TicketId id to run deskpro macros for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundPayment: async (paymentId: string, userId: string, ticketId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('refundPayment', 'paymentId', paymentId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('refundPayment', 'userId', userId)
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('refundPayment', 'ticketId', ticketId)
            const localVarPath = `/management/payment/{paymentId}/refund`
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (ticketId !== undefined) {
                localVarQueryParameter['ticket_id'] = ticketId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends custom email
         * @summary Sends custom email
         * @param {string} subscriptionId Id of a subscripotion to retry
         * @param {ResetSubscriptionRetriesSubscriptionTypeEnum} subscriptionType Subscription type
         * @param {string} retries Number of retries to make. Max is 3
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetSubscriptionRetries: async (subscriptionId: string, subscriptionType: ResetSubscriptionRetriesSubscriptionTypeEnum, retries: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('resetSubscriptionRetries', 'subscriptionId', subscriptionId)
            // verify required parameter 'subscriptionType' is not null or undefined
            assertParamExists('resetSubscriptionRetries', 'subscriptionType', subscriptionType)
            // verify required parameter 'retries' is not null or undefined
            assertParamExists('resetSubscriptionRetries', 'retries', retries)
            const localVarPath = `/management/subscription/payments/retry/{subscriptionType}/{subscriptionId}`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)))
                .replace(`{${"subscriptionType"}}`, encodeURIComponent(String(subscriptionType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)

            if (retries !== undefined) {
                localVarQueryParameter['retries'] = retries;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends custom email
         * @summary Sends custom email
         * @param {string} formId Form ID to reprocess
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resubmit: async (formId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('resubmit', 'formId', formId)
            const localVarPath = `/management/form/resubmit/{formId}`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends custom email
         * @summary Sends custom email
         * @param {DtoSendEmailRequest} emailRequest Email sending parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCustomEmail: async (emailRequest: DtoSendEmailRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailRequest' is not null or undefined
            assertParamExists('sendCustomEmail', 'emailRequest', emailRequest)
            const localVarPath = `/management/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Opts out a user from SMS
         * @summary Opts out a user from SMS
         * @param {string} userId User id - our system internal user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smsOptout: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('smsOptout', 'userId', userId)
            const localVarPath = `/management/user/sms-optout/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManagementApi - functional programming interface
 * @export
 */
export const ManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Sends custom email for beneficial owner
         * @summary Sends custom email for beneficial owner
         * @param {string} userId User ID to get info for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beneficialOwnerDeskproStats(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoDeskproResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beneficialOwnerDeskproStats(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.beneficialOwnerDeskproStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Cancels any subscription by provided form id and type and sends appropriate email
         * @summary Cancels any subscription by provided form id and type
         * @param {string} subscriptionId Subscription ID to cancel
         * @param {string} ticketId Id of the ticket to run macro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSubscription(subscriptionId: string, ticketId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscription(subscriptionId, ticketId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.cancelSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sends custom email
         * @summary Sends custom email
         * @param {string} userId User ID to get info for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deskproStats(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepositoryDeskproResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deskproStats(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.deskproStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sends custom email
         * @summary Sends custom email
         * @param {string} userId User ID to get info for
         * @param {string} dfyReturnId ReturnID to get Enagelement letter for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEngagementLetterDeskpro(userId: string, dfyReturnId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagementBase64File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEngagementLetterDeskpro(userId, dfyReturnId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.getEngagementLetterDeskpro']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Cancels any subscription by provided form id and type and sends appropriate email
         * @summary Cancels any subscription by provided form id and type
         * @param {ManagementImportOptions} personalFormStep3Request Options for the import
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importBusinessForm(personalFormStep3Request: ManagementImportOptions, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importBusinessForm(personalFormStep3Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.importBusinessForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Cancels any subscription by provided form id and type and sends appropriate email
         * @summary Cancels any subscription by provided form id and type
         * @param {ManagementImportOptions} personalFormStep3Request Options for the import
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importPersonalForm(personalFormStep3Request: ManagementImportOptions, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importPersonalForm(personalFormStep3Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.importPersonalForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Unsubscribes provided forms from ontraport messaging. Uses unsub_list
         * @summary Unsubscribes provided forms from ontraport messaging. Uses unsub_list
         * @param {Array<string>} formIds Form Ids to unsubscribe divided by ; (semicolon)
         * @param {string} unsubList list of ids to unsubscribe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ontraportUnsubscribeLists(formIds: Array<string>, unsubList: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ontraportUnsubscribeLists(formIds, unsubList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.ontraportUnsubscribeLists']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Unsubscribes all user forms from ontraport. Uses unsub_list
         * @summary Unsubscribes all user forms from ontraport. Uses unsub_list
         * @param {string} email Client\&#39;s email
         * @param {Array<string>} unsubList list of ids to unsubscribe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ontraportUserUnsubscribe(email: string, unsubList: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ontraportUserUnsubscribe(email, unsubList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.ontraportUserUnsubscribe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Cancels any subscription by provided form id and type and sends appropriate email
         * @summary Cancels any subscription by provided form id and type
         * @param {string} userId Deskpro id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purgeUser(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purgeUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.purgeUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fully refunds payment based on id provided
         * @summary Refunds payment by provided id
         * @param {string} paymentId Id of the payment to refund
         * @param {string} userId Id of the user to refund payment for
         * @param {string} ticketId TicketId id to run deskpro macros for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundPayment(paymentId: string, userId: string, ticketId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundPayment(paymentId, userId, ticketId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.refundPayment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sends custom email
         * @summary Sends custom email
         * @param {string} subscriptionId Id of a subscripotion to retry
         * @param {ResetSubscriptionRetriesSubscriptionTypeEnum} subscriptionType Subscription type
         * @param {string} retries Number of retries to make. Max is 3
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetSubscriptionRetries(subscriptionId: string, subscriptionType: ResetSubscriptionRetriesSubscriptionTypeEnum, retries: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetSubscriptionRetries(subscriptionId, subscriptionType, retries, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.resetSubscriptionRetries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sends custom email
         * @summary Sends custom email
         * @param {string} formId Form ID to reprocess
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resubmit(formId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resubmit(formId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.resubmit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Sends custom email
         * @summary Sends custom email
         * @param {DtoSendEmailRequest} emailRequest Email sending parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendCustomEmail(emailRequest: DtoSendEmailRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendCustomEmail(emailRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.sendCustomEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Opts out a user from SMS
         * @summary Opts out a user from SMS
         * @param {string} userId User id - our system internal user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smsOptout(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smsOptout(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.smsOptout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ManagementApi - factory interface
 * @export
 */
export const ManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManagementApiFp(configuration)
    return {
        /**
         * Sends custom email for beneficial owner
         * @summary Sends custom email for beneficial owner
         * @param {string} userId User ID to get info for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beneficialOwnerDeskproStats(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<DtoDeskproResponse> {
            return localVarFp.beneficialOwnerDeskproStats(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancels any subscription by provided form id and type and sends appropriate email
         * @summary Cancels any subscription by provided form id and type
         * @param {string} subscriptionId Subscription ID to cancel
         * @param {string} ticketId Id of the ticket to run macro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription(subscriptionId: string, ticketId: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.cancelSubscription(subscriptionId, ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends custom email
         * @summary Sends custom email
         * @param {string} userId User ID to get info for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deskproStats(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<RepositoryDeskproResponse> {
            return localVarFp.deskproStats(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends custom email
         * @summary Sends custom email
         * @param {string} userId User ID to get info for
         * @param {string} dfyReturnId ReturnID to get Enagelement letter for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEngagementLetterDeskpro(userId: string, dfyReturnId: string, options?: RawAxiosRequestConfig): AxiosPromise<ManagementBase64File> {
            return localVarFp.getEngagementLetterDeskpro(userId, dfyReturnId, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancels any subscription by provided form id and type and sends appropriate email
         * @summary Cancels any subscription by provided form id and type
         * @param {ManagementImportOptions} personalFormStep3Request Options for the import
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importBusinessForm(personalFormStep3Request: ManagementImportOptions, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.importBusinessForm(personalFormStep3Request, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancels any subscription by provided form id and type and sends appropriate email
         * @summary Cancels any subscription by provided form id and type
         * @param {ManagementImportOptions} personalFormStep3Request Options for the import
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPersonalForm(personalFormStep3Request: ManagementImportOptions, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.importPersonalForm(personalFormStep3Request, options).then((request) => request(axios, basePath));
        },
        /**
         * Unsubscribes provided forms from ontraport messaging. Uses unsub_list
         * @summary Unsubscribes provided forms from ontraport messaging. Uses unsub_list
         * @param {Array<string>} formIds Form Ids to unsubscribe divided by ; (semicolon)
         * @param {string} unsubList list of ids to unsubscribe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ontraportUnsubscribeLists(formIds: Array<string>, unsubList: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.ontraportUnsubscribeLists(formIds, unsubList, options).then((request) => request(axios, basePath));
        },
        /**
         * Unsubscribes all user forms from ontraport. Uses unsub_list
         * @summary Unsubscribes all user forms from ontraport. Uses unsub_list
         * @param {string} email Client\&#39;s email
         * @param {Array<string>} unsubList list of ids to unsubscribe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ontraportUserUnsubscribe(email: string, unsubList: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.ontraportUserUnsubscribe(email, unsubList, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancels any subscription by provided form id and type and sends appropriate email
         * @summary Cancels any subscription by provided form id and type
         * @param {string} userId Deskpro id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purgeUser(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.purgeUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fully refunds payment based on id provided
         * @summary Refunds payment by provided id
         * @param {string} paymentId Id of the payment to refund
         * @param {string} userId Id of the user to refund payment for
         * @param {string} ticketId TicketId id to run deskpro macros for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundPayment(paymentId: string, userId: string, ticketId: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.refundPayment(paymentId, userId, ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends custom email
         * @summary Sends custom email
         * @param {string} subscriptionId Id of a subscripotion to retry
         * @param {ResetSubscriptionRetriesSubscriptionTypeEnum} subscriptionType Subscription type
         * @param {string} retries Number of retries to make. Max is 3
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetSubscriptionRetries(subscriptionId: string, subscriptionType: ResetSubscriptionRetriesSubscriptionTypeEnum, retries: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.resetSubscriptionRetries(subscriptionId, subscriptionType, retries, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends custom email
         * @summary Sends custom email
         * @param {string} formId Form ID to reprocess
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resubmit(formId: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.resubmit(formId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends custom email
         * @summary Sends custom email
         * @param {DtoSendEmailRequest} emailRequest Email sending parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCustomEmail(emailRequest: DtoSendEmailRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.sendCustomEmail(emailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Opts out a user from SMS
         * @summary Opts out a user from SMS
         * @param {string} userId User id - our system internal user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smsOptout(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.smsOptout(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManagementApi - object-oriented interface
 * @export
 * @class ManagementApi
 * @extends {BaseAPI}
 */
export class ManagementApi extends BaseAPI {
    /**
     * Sends custom email for beneficial owner
     * @summary Sends custom email for beneficial owner
     * @param {string} userId User ID to get info for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public beneficialOwnerDeskproStats(userId: string, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).beneficialOwnerDeskproStats(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancels any subscription by provided form id and type and sends appropriate email
     * @summary Cancels any subscription by provided form id and type
     * @param {string} subscriptionId Subscription ID to cancel
     * @param {string} ticketId Id of the ticket to run macro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public cancelSubscription(subscriptionId: string, ticketId: string, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).cancelSubscription(subscriptionId, ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends custom email
     * @summary Sends custom email
     * @param {string} userId User ID to get info for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public deskproStats(userId: string, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).deskproStats(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends custom email
     * @summary Sends custom email
     * @param {string} userId User ID to get info for
     * @param {string} dfyReturnId ReturnID to get Enagelement letter for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public getEngagementLetterDeskpro(userId: string, dfyReturnId: string, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).getEngagementLetterDeskpro(userId, dfyReturnId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancels any subscription by provided form id and type and sends appropriate email
     * @summary Cancels any subscription by provided form id and type
     * @param {ManagementImportOptions} personalFormStep3Request Options for the import
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public importBusinessForm(personalFormStep3Request: ManagementImportOptions, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).importBusinessForm(personalFormStep3Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancels any subscription by provided form id and type and sends appropriate email
     * @summary Cancels any subscription by provided form id and type
     * @param {ManagementImportOptions} personalFormStep3Request Options for the import
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public importPersonalForm(personalFormStep3Request: ManagementImportOptions, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).importPersonalForm(personalFormStep3Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unsubscribes provided forms from ontraport messaging. Uses unsub_list
     * @summary Unsubscribes provided forms from ontraport messaging. Uses unsub_list
     * @param {Array<string>} formIds Form Ids to unsubscribe divided by ; (semicolon)
     * @param {string} unsubList list of ids to unsubscribe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public ontraportUnsubscribeLists(formIds: Array<string>, unsubList: string, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).ontraportUnsubscribeLists(formIds, unsubList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unsubscribes all user forms from ontraport. Uses unsub_list
     * @summary Unsubscribes all user forms from ontraport. Uses unsub_list
     * @param {string} email Client\&#39;s email
     * @param {Array<string>} unsubList list of ids to unsubscribe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public ontraportUserUnsubscribe(email: string, unsubList: Array<string>, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).ontraportUserUnsubscribe(email, unsubList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancels any subscription by provided form id and type and sends appropriate email
     * @summary Cancels any subscription by provided form id and type
     * @param {string} userId Deskpro id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public purgeUser(userId: string, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).purgeUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fully refunds payment based on id provided
     * @summary Refunds payment by provided id
     * @param {string} paymentId Id of the payment to refund
     * @param {string} userId Id of the user to refund payment for
     * @param {string} ticketId TicketId id to run deskpro macros for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public refundPayment(paymentId: string, userId: string, ticketId: string, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).refundPayment(paymentId, userId, ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends custom email
     * @summary Sends custom email
     * @param {string} subscriptionId Id of a subscripotion to retry
     * @param {ResetSubscriptionRetriesSubscriptionTypeEnum} subscriptionType Subscription type
     * @param {string} retries Number of retries to make. Max is 3
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public resetSubscriptionRetries(subscriptionId: string, subscriptionType: ResetSubscriptionRetriesSubscriptionTypeEnum, retries: string, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).resetSubscriptionRetries(subscriptionId, subscriptionType, retries, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends custom email
     * @summary Sends custom email
     * @param {string} formId Form ID to reprocess
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public resubmit(formId: string, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).resubmit(formId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends custom email
     * @summary Sends custom email
     * @param {DtoSendEmailRequest} emailRequest Email sending parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public sendCustomEmail(emailRequest: DtoSendEmailRequest, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).sendCustomEmail(emailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Opts out a user from SMS
     * @summary Opts out a user from SMS
     * @param {string} userId User id - our system internal user ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public smsOptout(userId: string, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).smsOptout(userId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ResetSubscriptionRetriesSubscriptionTypeEnum = {
    Shield: 'shield',
    AutoExtend: 'auto-extend',
    Bundle: 'bundle'
} as const;
export type ResetSubscriptionRetriesSubscriptionTypeEnum = typeof ResetSubscriptionRetriesSubscriptionTypeEnum[keyof typeof ResetSubscriptionRetriesSubscriptionTypeEnum];


/**
 * OAuthApi - axios parameter creator
 * @export
 */
export const OAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * If registration / login is made from the satellite or any other 3rd party website should use this method to echange token returned by registraiton or login api to a valid jwt token
         * @summary Exchanges temporary access code / token got during login/registration to proper jwt token
         * @param {DtoTokenExchangeRequest} exchangeRequest Provides temporary token for exchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangeRefreshToken: async (exchangeRequest: DtoTokenExchangeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeRequest' is not null or undefined
            assertParamExists('exchangeRefreshToken', 'exchangeRequest', exchangeRequest)
            const localVarPath = `/oauth/exchange/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exchanges email/username and password for jwt token
         * @summary Exchanges email/username and password for jwt token
         * @param {DtoLoginRequest} loginRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser: async (loginRequest: DtoLoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('loginUser', 'loginRequest', loginRequest)
            const localVarPath = `/oauth/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives callback data from the oauth provider.
         * @summary receive oauth info from data provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exchanges magic token for jwt token
         * @summary Exchanges magic token for jwt token
         * @param {DtoMagicLoginRequest} loginRequest Logins user magicly using provided token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magicLoginUser: async (loginRequest: DtoMagicLoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('magicLoginUser', 'loginRequest', loginRequest)
            const localVarPath = `/oauth/magic-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives callback data from the oauth provider.
         * @summary receive oauth info from data provider
         * @param {string} provider Provider to use for login
         * @param {string} state State ID from the login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oAuthCallback: async (provider: string, state: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('oAuthCallback', 'provider', provider)
            // verify required parameter 'state' is not null or undefined
            assertParamExists('oAuthCallback', 'state', state)
            const localVarPath = `/oauth/callback`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows to check if user is already registered and what auth methods are available
         * @summary Allows to get possible auth methods user can use to authenticate
         * @param {DtoPreAuthRequest} exchangeRequest Provides user email and options for pre-auth request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preAuthenticate: async (exchangeRequest: DtoPreAuthRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeRequest' is not null or undefined
            assertParamExists('preAuthenticate', 'exchangeRequest', exchangeRequest)
            const localVarPath = `/oauth/pre-auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Api call allows refreshing existing JWT token or expired one if refreshToken is still valid and presents in cookies
         * @summary refreshes JWT token
         * @param {string} [refreshToken] Refresh token  if provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshJWTToken: async (refreshToken?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (refreshToken !== undefined) {
                localVarQueryParameter['refresh-token'] = refreshToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates an account for the user via manually filling registration form
         * @summary creates user account by filling registration form
         * @param {DtoRegistrationRequest} registrationRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser: async (registrationRequest: DtoRegistrationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationRequest' is not null or undefined
            assertParamExists('registerUser', 'registrationRequest', registrationRequest)
            const localVarPath = `/oauth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Methods sends link to the user which allows to login without entering password
         * @summary Method sends link to the user which allows to login without entering password
         * @param {DtoMagicLinkRequest} exchangeRequest Provides user email and options for pre-auth request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMagicLink: async (exchangeRequest: DtoMagicLinkRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeRequest' is not null or undefined
            assertParamExists('sendMagicLink', 'exchangeRequest', exchangeRequest)
            const localVarPath = `/oauth/one-click-intent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts OAuth authentication routine based on selected provider.
         * @summary starts oauth login
         * @param {StartOAuthAuthActionEnum} authAction authentication action
         * @param {string} provider Provider to use for login
         * @param {string} source Satellite hostname
         * @param {string} deviceId DeviceID of the user
         * @param {string} leadId LeadID
         * @param {string} searchSource Search Source
         * @param {string} [funnel] funnel which user will use
         * @param {StartOAuthEEnum} [e] engine to use
         * @param {string} [rdtkclid] RedTrack Click ID
         * @param {string} [osuserid] OneSignal UserId
         * @param {string} [osappid] OneSignal App ID
         * @param {string} [msclkid] Microsoft click id
         * @param {string} [affId] Affiliate ID
         * @param {string} [productFocus] Product Focus
         * @param {string} [affSubId] Affiliate SUB ID
         * @param {string} [transactionId] Affiliate Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startOAuth: async (authAction: StartOAuthAuthActionEnum, provider: string, source: string, deviceId: string, leadId: string, searchSource: string, funnel?: string, e?: StartOAuthEEnum, rdtkclid?: string, osuserid?: string, osappid?: string, msclkid?: string, affId?: string, productFocus?: string, affSubId?: string, transactionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authAction' is not null or undefined
            assertParamExists('startOAuth', 'authAction', authAction)
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('startOAuth', 'provider', provider)
            // verify required parameter 'source' is not null or undefined
            assertParamExists('startOAuth', 'source', source)
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('startOAuth', 'deviceId', deviceId)
            // verify required parameter 'leadId' is not null or undefined
            assertParamExists('startOAuth', 'leadId', leadId)
            // verify required parameter 'searchSource' is not null or undefined
            assertParamExists('startOAuth', 'searchSource', searchSource)
            const localVarPath = `/oauth/via/{provider}`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authAction !== undefined) {
                localVarQueryParameter['auth_action'] = authAction;
            }

            if (funnel !== undefined) {
                localVarQueryParameter['funnel'] = funnel;
            }

            if (e !== undefined) {
                localVarQueryParameter['e'] = e;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['device_id'] = deviceId;
            }

            if (rdtkclid !== undefined) {
                localVarQueryParameter['rdtkclid'] = rdtkclid;
            }

            if (leadId !== undefined) {
                localVarQueryParameter['lead_id'] = leadId;
            }

            if (searchSource !== undefined) {
                localVarQueryParameter['search_source'] = searchSource;
            }

            if (osuserid !== undefined) {
                localVarQueryParameter['osuserid'] = osuserid;
            }

            if (osappid !== undefined) {
                localVarQueryParameter['osappid'] = osappid;
            }

            if (msclkid !== undefined) {
                localVarQueryParameter['msclkid'] = msclkid;
            }

            if (affId !== undefined) {
                localVarQueryParameter['aff_id'] = affId;
            }

            if (productFocus !== undefined) {
                localVarQueryParameter['product_focus'] = productFocus;
            }

            if (affSubId !== undefined) {
                localVarQueryParameter['aff_sub_id'] = affSubId;
            }

            if (transactionId !== undefined) {
                localVarQueryParameter['transaction_id'] = transactionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets CSFR token
         * @summary Gets CSFR tokenn
         * @param {string} [refreshToken] Refresh token  if provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCsfrToken: async (refreshToken?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/_csfr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (refreshToken !== undefined) {
                localVarQueryParameter['refresh-token'] = refreshToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OAuthApi - functional programming interface
 * @export
 */
export const OAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * If registration / login is made from the satellite or any other 3rd party website should use this method to echange token returned by registraiton or login api to a valid jwt token
         * @summary Exchanges temporary access code / token got during login/registration to proper jwt token
         * @param {DtoTokenExchangeRequest} exchangeRequest Provides temporary token for exchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangeRefreshToken(exchangeRequest: DtoTokenExchangeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangeRefreshToken(exchangeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OAuthApi.exchangeRefreshToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Exchanges email/username and password for jwt token
         * @summary Exchanges email/username and password for jwt token
         * @param {DtoLoginRequest} loginRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginUser(loginRequest: DtoLoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginUser(loginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OAuthApi.loginUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Receives callback data from the oauth provider.
         * @summary receive oauth info from data provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OAuthApi.logout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Exchanges magic token for jwt token
         * @summary Exchanges magic token for jwt token
         * @param {DtoMagicLoginRequest} loginRequest Logins user magicly using provided token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magicLoginUser(loginRequest: DtoMagicLoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.magicLoginUser(loginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OAuthApi.magicLoginUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Receives callback data from the oauth provider.
         * @summary receive oauth info from data provider
         * @param {string} provider Provider to use for login
         * @param {string} state State ID from the login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oAuthCallback(provider: string, state: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oAuthCallback(provider, state, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OAuthApi.oAuthCallback']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Allows to check if user is already registered and what auth methods are available
         * @summary Allows to get possible auth methods user can use to authenticate
         * @param {DtoPreAuthRequest} exchangeRequest Provides user email and options for pre-auth request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async preAuthenticate(exchangeRequest: DtoPreAuthRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoPreAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.preAuthenticate(exchangeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OAuthApi.preAuthenticate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Api call allows refreshing existing JWT token or expired one if refreshToken is still valid and presents in cookies
         * @summary refreshes JWT token
         * @param {string} [refreshToken] Refresh token  if provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshJWTToken(refreshToken?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoRefreshTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshJWTToken(refreshToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OAuthApi.refreshJWTToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates an account for the user via manually filling registration form
         * @summary creates user account by filling registration form
         * @param {DtoRegistrationRequest} registrationRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUser(registrationRequest: DtoRegistrationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUser(registrationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OAuthApi.registerUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Methods sends link to the user which allows to login without entering password
         * @summary Method sends link to the user which allows to login without entering password
         * @param {DtoMagicLinkRequest} exchangeRequest Provides user email and options for pre-auth request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMagicLink(exchangeRequest: DtoMagicLinkRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMagicLink(exchangeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OAuthApi.sendMagicLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Starts OAuth authentication routine based on selected provider.
         * @summary starts oauth login
         * @param {StartOAuthAuthActionEnum} authAction authentication action
         * @param {string} provider Provider to use for login
         * @param {string} source Satellite hostname
         * @param {string} deviceId DeviceID of the user
         * @param {string} leadId LeadID
         * @param {string} searchSource Search Source
         * @param {string} [funnel] funnel which user will use
         * @param {StartOAuthEEnum} [e] engine to use
         * @param {string} [rdtkclid] RedTrack Click ID
         * @param {string} [osuserid] OneSignal UserId
         * @param {string} [osappid] OneSignal App ID
         * @param {string} [msclkid] Microsoft click id
         * @param {string} [affId] Affiliate ID
         * @param {string} [productFocus] Product Focus
         * @param {string} [affSubId] Affiliate SUB ID
         * @param {string} [transactionId] Affiliate Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startOAuth(authAction: StartOAuthAuthActionEnum, provider: string, source: string, deviceId: string, leadId: string, searchSource: string, funnel?: string, e?: StartOAuthEEnum, rdtkclid?: string, osuserid?: string, osappid?: string, msclkid?: string, affId?: string, productFocus?: string, affSubId?: string, transactionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startOAuth(authAction, provider, source, deviceId, leadId, searchSource, funnel, e, rdtkclid, osuserid, osappid, msclkid, affId, productFocus, affSubId, transactionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OAuthApi.startOAuth']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets CSFR token
         * @summary Gets CSFR tokenn
         * @param {string} [refreshToken] Refresh token  if provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCsfrToken(refreshToken?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCsfrToken(refreshToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OAuthApi.updateCsfrToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OAuthApi - factory interface
 * @export
 */
export const OAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OAuthApiFp(configuration)
    return {
        /**
         * If registration / login is made from the satellite or any other 3rd party website should use this method to echange token returned by registraiton or login api to a valid jwt token
         * @summary Exchanges temporary access code / token got during login/registration to proper jwt token
         * @param {DtoTokenExchangeRequest} exchangeRequest Provides temporary token for exchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangeRefreshToken(exchangeRequest: DtoTokenExchangeRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtoLoginResponse> {
            return localVarFp.exchangeRefreshToken(exchangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Exchanges email/username and password for jwt token
         * @summary Exchanges email/username and password for jwt token
         * @param {DtoLoginRequest} loginRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser(loginRequest: DtoLoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtoLoginResponse> {
            return localVarFp.loginUser(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Receives callback data from the oauth provider.
         * @summary receive oauth info from data provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * Exchanges magic token for jwt token
         * @summary Exchanges magic token for jwt token
         * @param {DtoMagicLoginRequest} loginRequest Logins user magicly using provided token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magicLoginUser(loginRequest: DtoMagicLoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtoLoginResponse> {
            return localVarFp.magicLoginUser(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Receives callback data from the oauth provider.
         * @summary receive oauth info from data provider
         * @param {string} provider Provider to use for login
         * @param {string} state State ID from the login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oAuthCallback(provider: string, state: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.oAuthCallback(provider, state, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows to check if user is already registered and what auth methods are available
         * @summary Allows to get possible auth methods user can use to authenticate
         * @param {DtoPreAuthRequest} exchangeRequest Provides user email and options for pre-auth request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preAuthenticate(exchangeRequest: DtoPreAuthRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtoPreAuthResponse> {
            return localVarFp.preAuthenticate(exchangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Api call allows refreshing existing JWT token or expired one if refreshToken is still valid and presents in cookies
         * @summary refreshes JWT token
         * @param {string} [refreshToken] Refresh token  if provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshJWTToken(refreshToken?: string, options?: RawAxiosRequestConfig): AxiosPromise<DtoRefreshTokenResponse> {
            return localVarFp.refreshJWTToken(refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates an account for the user via manually filling registration form
         * @summary creates user account by filling registration form
         * @param {DtoRegistrationRequest} registrationRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(registrationRequest: DtoRegistrationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtoLoginResponse> {
            return localVarFp.registerUser(registrationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Methods sends link to the user which allows to login without entering password
         * @summary Method sends link to the user which allows to login without entering password
         * @param {DtoMagicLinkRequest} exchangeRequest Provides user email and options for pre-auth request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMagicLink(exchangeRequest: DtoMagicLinkRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.sendMagicLink(exchangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Starts OAuth authentication routine based on selected provider.
         * @summary starts oauth login
         * @param {StartOAuthAuthActionEnum} authAction authentication action
         * @param {string} provider Provider to use for login
         * @param {string} source Satellite hostname
         * @param {string} deviceId DeviceID of the user
         * @param {string} leadId LeadID
         * @param {string} searchSource Search Source
         * @param {string} [funnel] funnel which user will use
         * @param {StartOAuthEEnum} [e] engine to use
         * @param {string} [rdtkclid] RedTrack Click ID
         * @param {string} [osuserid] OneSignal UserId
         * @param {string} [osappid] OneSignal App ID
         * @param {string} [msclkid] Microsoft click id
         * @param {string} [affId] Affiliate ID
         * @param {string} [productFocus] Product Focus
         * @param {string} [affSubId] Affiliate SUB ID
         * @param {string} [transactionId] Affiliate Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startOAuth(authAction: StartOAuthAuthActionEnum, provider: string, source: string, deviceId: string, leadId: string, searchSource: string, funnel?: string, e?: StartOAuthEEnum, rdtkclid?: string, osuserid?: string, osappid?: string, msclkid?: string, affId?: string, productFocus?: string, affSubId?: string, transactionId?: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.startOAuth(authAction, provider, source, deviceId, leadId, searchSource, funnel, e, rdtkclid, osuserid, osappid, msclkid, affId, productFocus, affSubId, transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets CSFR token
         * @summary Gets CSFR tokenn
         * @param {string} [refreshToken] Refresh token  if provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCsfrToken(refreshToken?: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.updateCsfrToken(refreshToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OAuthApi - object-oriented interface
 * @export
 * @class OAuthApi
 * @extends {BaseAPI}
 */
export class OAuthApi extends BaseAPI {
    /**
     * If registration / login is made from the satellite or any other 3rd party website should use this method to echange token returned by registraiton or login api to a valid jwt token
     * @summary Exchanges temporary access code / token got during login/registration to proper jwt token
     * @param {DtoTokenExchangeRequest} exchangeRequest Provides temporary token for exchange
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public exchangeRefreshToken(exchangeRequest: DtoTokenExchangeRequest, options?: RawAxiosRequestConfig) {
        return OAuthApiFp(this.configuration).exchangeRefreshToken(exchangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exchanges email/username and password for jwt token
     * @summary Exchanges email/username and password for jwt token
     * @param {DtoLoginRequest} loginRequest Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public loginUser(loginRequest: DtoLoginRequest, options?: RawAxiosRequestConfig) {
        return OAuthApiFp(this.configuration).loginUser(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Receives callback data from the oauth provider.
     * @summary receive oauth info from data provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public logout(options?: RawAxiosRequestConfig) {
        return OAuthApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exchanges magic token for jwt token
     * @summary Exchanges magic token for jwt token
     * @param {DtoMagicLoginRequest} loginRequest Logins user magicly using provided token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public magicLoginUser(loginRequest: DtoMagicLoginRequest, options?: RawAxiosRequestConfig) {
        return OAuthApiFp(this.configuration).magicLoginUser(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Receives callback data from the oauth provider.
     * @summary receive oauth info from data provider
     * @param {string} provider Provider to use for login
     * @param {string} state State ID from the login request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public oAuthCallback(provider: string, state: string, options?: RawAxiosRequestConfig) {
        return OAuthApiFp(this.configuration).oAuthCallback(provider, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows to check if user is already registered and what auth methods are available
     * @summary Allows to get possible auth methods user can use to authenticate
     * @param {DtoPreAuthRequest} exchangeRequest Provides user email and options for pre-auth request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public preAuthenticate(exchangeRequest: DtoPreAuthRequest, options?: RawAxiosRequestConfig) {
        return OAuthApiFp(this.configuration).preAuthenticate(exchangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Api call allows refreshing existing JWT token or expired one if refreshToken is still valid and presents in cookies
     * @summary refreshes JWT token
     * @param {string} [refreshToken] Refresh token  if provided
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public refreshJWTToken(refreshToken?: string, options?: RawAxiosRequestConfig) {
        return OAuthApiFp(this.configuration).refreshJWTToken(refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates an account for the user via manually filling registration form
     * @summary creates user account by filling registration form
     * @param {DtoRegistrationRequest} registrationRequest Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public registerUser(registrationRequest: DtoRegistrationRequest, options?: RawAxiosRequestConfig) {
        return OAuthApiFp(this.configuration).registerUser(registrationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Methods sends link to the user which allows to login without entering password
     * @summary Method sends link to the user which allows to login without entering password
     * @param {DtoMagicLinkRequest} exchangeRequest Provides user email and options for pre-auth request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public sendMagicLink(exchangeRequest: DtoMagicLinkRequest, options?: RawAxiosRequestConfig) {
        return OAuthApiFp(this.configuration).sendMagicLink(exchangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starts OAuth authentication routine based on selected provider.
     * @summary starts oauth login
     * @param {StartOAuthAuthActionEnum} authAction authentication action
     * @param {string} provider Provider to use for login
     * @param {string} source Satellite hostname
     * @param {string} deviceId DeviceID of the user
     * @param {string} leadId LeadID
     * @param {string} searchSource Search Source
     * @param {string} [funnel] funnel which user will use
     * @param {StartOAuthEEnum} [e] engine to use
     * @param {string} [rdtkclid] RedTrack Click ID
     * @param {string} [osuserid] OneSignal UserId
     * @param {string} [osappid] OneSignal App ID
     * @param {string} [msclkid] Microsoft click id
     * @param {string} [affId] Affiliate ID
     * @param {string} [productFocus] Product Focus
     * @param {string} [affSubId] Affiliate SUB ID
     * @param {string} [transactionId] Affiliate Transaction ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public startOAuth(authAction: StartOAuthAuthActionEnum, provider: string, source: string, deviceId: string, leadId: string, searchSource: string, funnel?: string, e?: StartOAuthEEnum, rdtkclid?: string, osuserid?: string, osappid?: string, msclkid?: string, affId?: string, productFocus?: string, affSubId?: string, transactionId?: string, options?: RawAxiosRequestConfig) {
        return OAuthApiFp(this.configuration).startOAuth(authAction, provider, source, deviceId, leadId, searchSource, funnel, e, rdtkclid, osuserid, osappid, msclkid, affId, productFocus, affSubId, transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets CSFR token
     * @summary Gets CSFR tokenn
     * @param {string} [refreshToken] Refresh token  if provided
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public updateCsfrToken(refreshToken?: string, options?: RawAxiosRequestConfig) {
        return OAuthApiFp(this.configuration).updateCsfrToken(refreshToken, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const StartOAuthAuthActionEnum = {
    Login: 'login',
    Registration: 'registration'
} as const;
export type StartOAuthAuthActionEnum = typeof StartOAuthAuthActionEnum[keyof typeof StartOAuthAuthActionEnum];
/**
 * @export
 */
export const StartOAuthEEnum = {
    Eftn: 'eftn',
    Efto: 'efto',
    Fst: 'fst'
} as const;
export type StartOAuthEEnum = typeof StartOAuthEEnum[keyof typeof StartOAuthEEnum];


/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Makes payment via previously saved payment profile
         * @summary Makes payment via previously saved payment profile
         * @param {string} profileId PaymentProfileId to use for payment
         * @param {DtoProfilePaymentRequest} paymentProfileRequest Pays using already created payment profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        charge: async (profileId: string, paymentProfileRequest: DtoProfilePaymentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('charge', 'profileId', profileId)
            // verify required parameter 'paymentProfileRequest' is not null or undefined
            assertParamExists('charge', 'paymentProfileRequest', paymentProfileRequest)
            const localVarPath = `/payment/charge/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides merchant configuration
         * @param {PaymentPaymentContextRequest} contextRequest Payemnt context requested for the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentContext: async (contextRequest: PaymentPaymentContextRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contextRequest' is not null or undefined
            assertParamExists('getPaymentContext', 'contextRequest', contextRequest)
            const localVarPath = `/payment/context`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contextRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns list of the payment profiles available for the user
         * @summary Returns list of the payment profiles available for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentProfiles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment/profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a payment profile for selected merchant that can be used to make payments
         * @summary Creates a payment profile for selected merchant
         * @param {PaymentPaymentProfileRequest} paymentProfileRequest Payment profile creation request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newPaymentProfile: async (paymentProfileRequest: PaymentPaymentProfileRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentProfileRequest' is not null or undefined
            assertParamExists('newPaymentProfile', 'paymentProfileRequest', paymentProfileRequest)
            const localVarPath = `/payment/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets info from the payeezy merchant with coded CC info. Response should be received before any other payment calls. Is triggered by Authorize call.
         * @summary Gets info from the payeezy merchant with coded CC info
         * @param {DtoPayeezyWebhookData} payeezyWebhookRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payeezyWebhook: async (payeezyWebhookRequest: DtoPayeezyWebhookData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payeezyWebhookRequest' is not null or undefined
            assertParamExists('payeezyWebhook', 'payeezyWebhookRequest', payeezyWebhookRequest)
            const localVarPath = `/payment/payeezy/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payeezyWebhookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register client side payment failure usually when tokenization fails
         * @summary Register client side payment failure
         * @param {string} merchant The code of the merchant that failed on the client side
         * @param {DtoClientSideTokenizationError} merchantErrorRegistrationRequest Detailed information about merchant error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenizationFailure: async (merchant: string, merchantErrorRegistrationRequest: DtoClientSideTokenizationError, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchant' is not null or undefined
            assertParamExists('tokenizationFailure', 'merchant', merchant)
            // verify required parameter 'merchantErrorRegistrationRequest' is not null or undefined
            assertParamExists('tokenizationFailure', 'merchantErrorRegistrationRequest', merchantErrorRegistrationRequest)
            const localVarPath = `/payment/{merchant}/error`
                .replace(`{${"merchant"}}`, encodeURIComponent(String(merchant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(merchantErrorRegistrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resets all errors for the selected payment profile. Used to reset retry counter for the subscriptions (shield or bundle)
         * @summary Resets all errors for the selected payment profile. Used to reset retry counter for the subscriptions (shield or bundle)
         * @param {string} profileId PaymentProfileID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentProfile: async (profileId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('updatePaymentProfile', 'profileId', profileId)
            const localVarPath = `/payment/profile/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Makes payment via previously saved payment profile
         * @summary Makes payment via previously saved payment profile
         * @param {string} profileId PaymentProfileId to use for payment
         * @param {DtoProfilePaymentRequest} paymentProfileRequest Pays using already created payment profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async charge(profileId: string, paymentProfileRequest: DtoProfilePaymentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoPaymentResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.charge(profileId, paymentProfileRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.charge']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provides merchant configuration
         * @param {PaymentPaymentContextRequest} contextRequest Payemnt context requested for the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentContext(contextRequest: PaymentPaymentContextRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMerchantConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentContext(contextRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.getPaymentContext']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns list of the payment profiles available for the user
         * @summary Returns list of the payment profiles available for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentProfiles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DtoPaymentProfile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentProfiles(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.getPaymentProfiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a payment profile for selected merchant that can be used to make payments
         * @summary Creates a payment profile for selected merchant
         * @param {PaymentPaymentProfileRequest} paymentProfileRequest Payment profile creation request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newPaymentProfile(paymentProfileRequest: PaymentPaymentProfileRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoPaymentProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newPaymentProfile(paymentProfileRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.newPaymentProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets info from the payeezy merchant with coded CC info. Response should be received before any other payment calls. Is triggered by Authorize call.
         * @summary Gets info from the payeezy merchant with coded CC info
         * @param {DtoPayeezyWebhookData} payeezyWebhookRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payeezyWebhook(payeezyWebhookRequest: DtoPayeezyWebhookData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoF4868CreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payeezyWebhook(payeezyWebhookRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.payeezyWebhook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Register client side payment failure usually when tokenization fails
         * @summary Register client side payment failure
         * @param {string} merchant The code of the merchant that failed on the client side
         * @param {DtoClientSideTokenizationError} merchantErrorRegistrationRequest Detailed information about merchant error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenizationFailure(merchant: string, merchantErrorRegistrationRequest: DtoClientSideTokenizationError, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoClientSideTokenizationError>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenizationFailure(merchant, merchantErrorRegistrationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.tokenizationFailure']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Resets all errors for the selected payment profile. Used to reset retry counter for the subscriptions (shield or bundle)
         * @summary Resets all errors for the selected payment profile. Used to reset retry counter for the subscriptions (shield or bundle)
         * @param {string} profileId PaymentProfileID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePaymentProfile(profileId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoPaymentProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePaymentProfile(profileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.updatePaymentProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * Makes payment via previously saved payment profile
         * @summary Makes payment via previously saved payment profile
         * @param {string} profileId PaymentProfileId to use for payment
         * @param {DtoProfilePaymentRequest} paymentProfileRequest Pays using already created payment profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        charge(profileId: string, paymentProfileRequest: DtoProfilePaymentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtoPaymentResult> {
            return localVarFp.charge(profileId, paymentProfileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Provides merchant configuration
         * @param {PaymentPaymentContextRequest} contextRequest Payemnt context requested for the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentContext(contextRequest: PaymentPaymentContextRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaymentMerchantConfig> {
            return localVarFp.getPaymentContext(contextRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns list of the payment profiles available for the user
         * @summary Returns list of the payment profiles available for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentProfiles(options?: RawAxiosRequestConfig): AxiosPromise<Array<DtoPaymentProfile>> {
            return localVarFp.getPaymentProfiles(options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a payment profile for selected merchant that can be used to make payments
         * @summary Creates a payment profile for selected merchant
         * @param {PaymentPaymentProfileRequest} paymentProfileRequest Payment profile creation request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newPaymentProfile(paymentProfileRequest: PaymentPaymentProfileRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtoPaymentProfile> {
            return localVarFp.newPaymentProfile(paymentProfileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets info from the payeezy merchant with coded CC info. Response should be received before any other payment calls. Is triggered by Authorize call.
         * @summary Gets info from the payeezy merchant with coded CC info
         * @param {DtoPayeezyWebhookData} payeezyWebhookRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payeezyWebhook(payeezyWebhookRequest: DtoPayeezyWebhookData, options?: RawAxiosRequestConfig): AxiosPromise<DtoF4868CreateResponse> {
            return localVarFp.payeezyWebhook(payeezyWebhookRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Register client side payment failure usually when tokenization fails
         * @summary Register client side payment failure
         * @param {string} merchant The code of the merchant that failed on the client side
         * @param {DtoClientSideTokenizationError} merchantErrorRegistrationRequest Detailed information about merchant error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenizationFailure(merchant: string, merchantErrorRegistrationRequest: DtoClientSideTokenizationError, options?: RawAxiosRequestConfig): AxiosPromise<DtoClientSideTokenizationError> {
            return localVarFp.tokenizationFailure(merchant, merchantErrorRegistrationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Resets all errors for the selected payment profile. Used to reset retry counter for the subscriptions (shield or bundle)
         * @summary Resets all errors for the selected payment profile. Used to reset retry counter for the subscriptions (shield or bundle)
         * @param {string} profileId PaymentProfileID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentProfile(profileId: string, options?: RawAxiosRequestConfig): AxiosPromise<DtoPaymentProfile> {
            return localVarFp.updatePaymentProfile(profileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * Makes payment via previously saved payment profile
     * @summary Makes payment via previously saved payment profile
     * @param {string} profileId PaymentProfileId to use for payment
     * @param {DtoProfilePaymentRequest} paymentProfileRequest Pays using already created payment profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public charge(profileId: string, paymentProfileRequest: DtoProfilePaymentRequest, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).charge(profileId, paymentProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides merchant configuration
     * @param {PaymentPaymentContextRequest} contextRequest Payemnt context requested for the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getPaymentContext(contextRequest: PaymentPaymentContextRequest, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).getPaymentContext(contextRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns list of the payment profiles available for the user
     * @summary Returns list of the payment profiles available for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getPaymentProfiles(options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).getPaymentProfiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a payment profile for selected merchant that can be used to make payments
     * @summary Creates a payment profile for selected merchant
     * @param {PaymentPaymentProfileRequest} paymentProfileRequest Payment profile creation request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public newPaymentProfile(paymentProfileRequest: PaymentPaymentProfileRequest, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).newPaymentProfile(paymentProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets info from the payeezy merchant with coded CC info. Response should be received before any other payment calls. Is triggered by Authorize call.
     * @summary Gets info from the payeezy merchant with coded CC info
     * @param {DtoPayeezyWebhookData} payeezyWebhookRequest Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public payeezyWebhook(payeezyWebhookRequest: DtoPayeezyWebhookData, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).payeezyWebhook(payeezyWebhookRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register client side payment failure usually when tokenization fails
     * @summary Register client side payment failure
     * @param {string} merchant The code of the merchant that failed on the client side
     * @param {DtoClientSideTokenizationError} merchantErrorRegistrationRequest Detailed information about merchant error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public tokenizationFailure(merchant: string, merchantErrorRegistrationRequest: DtoClientSideTokenizationError, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).tokenizationFailure(merchant, merchantErrorRegistrationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Resets all errors for the selected payment profile. Used to reset retry counter for the subscriptions (shield or bundle)
     * @summary Resets all errors for the selected payment profile. Used to reset retry counter for the subscriptions (shield or bundle)
     * @param {string} profileId PaymentProfileID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public updatePaymentProfile(profileId: string, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).updatePaymentProfile(profileId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PersonalFormsApi - axios parameter creator
 * @export
 */
export const PersonalFormsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new F4868 personal form and provides it\'s unique id. Other operations should be made using id provided
         * @summary Creates new F4868 personal form
         * @param {DtoF4868CreateRequest} personalFormCreateRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPersonalForm: async (personalFormCreateRequest: DtoF4868CreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personalFormCreateRequest' is not null or undefined
            assertParamExists('createPersonalForm', 'personalFormCreateRequest', personalFormCreateRequest)
            const localVarPath = `/f4868`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personalFormCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets current user PersonalForms
         * @summary Gets current user PersonalForms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPersonalForms: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/f4868`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets current user PersonalForm
         * @summary Gets current user PersonalForm
         * @param {string} id Personal Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalFormById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPersonalFormById', 'id', id)
            const localVarPath = `/f4868/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores date provided during Step 2 of the personal form
         * @summary Saves Step 2 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step2Request} personalFormStep2Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePersonalFormStep2: async (id: string, personalFormStep2Request: DtoF4868Step2Request, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storePersonalFormStep2', 'id', id)
            // verify required parameter 'personalFormStep2Request' is not null or undefined
            assertParamExists('storePersonalFormStep2', 'personalFormStep2Request', personalFormStep2Request)
            const localVarPath = `/f4868/{id}/step2`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personalFormStep2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores date provided during Step 3 of the personal form
         * @summary Saves Step 3 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step3Request} personalFormStep3Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePersonalFormStep3: async (id: string, personalFormStep3Request: DtoF4868Step3Request, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storePersonalFormStep3', 'id', id)
            // verify required parameter 'personalFormStep3Request' is not null or undefined
            assertParamExists('storePersonalFormStep3', 'personalFormStep3Request', personalFormStep3Request)
            const localVarPath = `/f4868/{id}/step3`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personalFormStep3Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores date provided during Step 4 of the personal form
         * @summary Saves Step 4 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step4Request} personalFormStep4Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePersonalFormStep4: async (id: string, personalFormStep4Request: DtoF4868Step4Request, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storePersonalFormStep4', 'id', id)
            // verify required parameter 'personalFormStep4Request' is not null or undefined
            assertParamExists('storePersonalFormStep4', 'personalFormStep4Request', personalFormStep4Request)
            const localVarPath = `/f4868/{id}/step4`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personalFormStep4Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores date provided during Step 5 of the personal form
         * @summary Saves Step 5 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step5Request} personalFormStep5Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePersonalFormStep5: async (id: string, personalFormStep5Request: DtoF4868Step5Request, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storePersonalFormStep5', 'id', id)
            // verify required parameter 'personalFormStep5Request' is not null or undefined
            assertParamExists('storePersonalFormStep5', 'personalFormStep5Request', personalFormStep5Request)
            const localVarPath = `/f4868/{id}/step5`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personalFormStep5Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores date provided during Step 6 of the personal form
         * @summary Saves Step 6 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step6Request} personalFormStep6Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePersonalFormStep6: async (id: string, personalFormStep6Request: DtoF4868Step6Request, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storePersonalFormStep6', 'id', id)
            // verify required parameter 'personalFormStep6Request' is not null or undefined
            assertParamExists('storePersonalFormStep6', 'personalFormStep6Request', personalFormStep6Request)
            const localVarPath = `/f4868/{id}/step6`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personalFormStep6Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores date provided during Step 7 of the personal form
         * @summary Saves Step 7 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step7Request} personalFormStep7Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePersonalFormStep7: async (id: string, personalFormStep7Request: DtoF4868Step7Request, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storePersonalFormStep7', 'id', id)
            // verify required parameter 'personalFormStep7Request' is not null or undefined
            assertParamExists('storePersonalFormStep7', 'personalFormStep7Request', personalFormStep7Request)
            const localVarPath = `/f4868/{id}/step7`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personalFormStep7Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fully updates already created personal form. All step data should be collected and form should be already created.
         * @summary Fully updates already created personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868FullUpdateRequest} personalFormUpdateRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonalForm: async (id: string, personalFormUpdateRequest: DtoF4868FullUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePersonalForm', 'id', id)
            // verify required parameter 'personalFormUpdateRequest' is not null or undefined
            assertParamExists('updatePersonalForm', 'personalFormUpdateRequest', personalFormUpdateRequest)
            const localVarPath = `/f4868/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personalFormUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submits user intention ot e-file extension
         * @summary Submits user intention ot e-file extension
         * @param {string} id Personal Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        willEFilePersonalForm: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('willEFilePersonalForm', 'id', id)
            const localVarPath = `/f4868/{id}/e-file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonalFormsApi - functional programming interface
 * @export
 */
export const PersonalFormsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonalFormsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new F4868 personal form and provides it\'s unique id. Other operations should be made using id provided
         * @summary Creates new F4868 personal form
         * @param {DtoF4868CreateRequest} personalFormCreateRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPersonalForm(personalFormCreateRequest: DtoF4868CreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoF4868CreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPersonalForm(personalFormCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalFormsApi.createPersonalForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets current user PersonalForms
         * @summary Gets current user PersonalForms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPersonalForms(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DtoForm4868>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPersonalForms(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalFormsApi.getAllPersonalForms']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets current user PersonalForm
         * @summary Gets current user PersonalForm
         * @param {string} id Personal Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonalFormById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoForm4868>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonalFormById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalFormsApi.getPersonalFormById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores date provided during Step 2 of the personal form
         * @summary Saves Step 2 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step2Request} personalFormStep2Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storePersonalFormStep2(id: string, personalFormStep2Request: DtoF4868Step2Request, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storePersonalFormStep2(id, personalFormStep2Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalFormsApi.storePersonalFormStep2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores date provided during Step 3 of the personal form
         * @summary Saves Step 3 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step3Request} personalFormStep3Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storePersonalFormStep3(id: string, personalFormStep3Request: DtoF4868Step3Request, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storePersonalFormStep3(id, personalFormStep3Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalFormsApi.storePersonalFormStep3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores date provided during Step 4 of the personal form
         * @summary Saves Step 4 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step4Request} personalFormStep4Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storePersonalFormStep4(id: string, personalFormStep4Request: DtoF4868Step4Request, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storePersonalFormStep4(id, personalFormStep4Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalFormsApi.storePersonalFormStep4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores date provided during Step 5 of the personal form
         * @summary Saves Step 5 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step5Request} personalFormStep5Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storePersonalFormStep5(id: string, personalFormStep5Request: DtoF4868Step5Request, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storePersonalFormStep5(id, personalFormStep5Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalFormsApi.storePersonalFormStep5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores date provided during Step 6 of the personal form
         * @summary Saves Step 6 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step6Request} personalFormStep6Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storePersonalFormStep6(id: string, personalFormStep6Request: DtoF4868Step6Request, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storePersonalFormStep6(id, personalFormStep6Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalFormsApi.storePersonalFormStep6']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores date provided during Step 7 of the personal form
         * @summary Saves Step 7 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step7Request} personalFormStep7Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storePersonalFormStep7(id: string, personalFormStep7Request: DtoF4868Step7Request, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storePersonalFormStep7(id, personalFormStep7Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalFormsApi.storePersonalFormStep7']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fully updates already created personal form. All step data should be collected and form should be already created.
         * @summary Fully updates already created personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868FullUpdateRequest} personalFormUpdateRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePersonalForm(id: string, personalFormUpdateRequest: DtoF4868FullUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoF4868FullUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePersonalForm(id, personalFormUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalFormsApi.updatePersonalForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Submits user intention ot e-file extension
         * @summary Submits user intention ot e-file extension
         * @param {string} id Personal Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async willEFilePersonalForm(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.willEFilePersonalForm(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalFormsApi.willEFilePersonalForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PersonalFormsApi - factory interface
 * @export
 */
export const PersonalFormsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonalFormsApiFp(configuration)
    return {
        /**
         * Creates new F4868 personal form and provides it\'s unique id. Other operations should be made using id provided
         * @summary Creates new F4868 personal form
         * @param {DtoF4868CreateRequest} personalFormCreateRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPersonalForm(personalFormCreateRequest: DtoF4868CreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtoF4868CreateResponse> {
            return localVarFp.createPersonalForm(personalFormCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets current user PersonalForms
         * @summary Gets current user PersonalForms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPersonalForms(options?: RawAxiosRequestConfig): AxiosPromise<Array<DtoForm4868>> {
            return localVarFp.getAllPersonalForms(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets current user PersonalForm
         * @summary Gets current user PersonalForm
         * @param {string} id Personal Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalFormById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DtoForm4868> {
            return localVarFp.getPersonalFormById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores date provided during Step 2 of the personal form
         * @summary Saves Step 2 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step2Request} personalFormStep2Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePersonalFormStep2(id: string, personalFormStep2Request: DtoF4868Step2Request, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.storePersonalFormStep2(id, personalFormStep2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores date provided during Step 3 of the personal form
         * @summary Saves Step 3 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step3Request} personalFormStep3Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePersonalFormStep3(id: string, personalFormStep3Request: DtoF4868Step3Request, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.storePersonalFormStep3(id, personalFormStep3Request, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores date provided during Step 4 of the personal form
         * @summary Saves Step 4 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step4Request} personalFormStep4Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePersonalFormStep4(id: string, personalFormStep4Request: DtoF4868Step4Request, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.storePersonalFormStep4(id, personalFormStep4Request, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores date provided during Step 5 of the personal form
         * @summary Saves Step 5 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step5Request} personalFormStep5Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePersonalFormStep5(id: string, personalFormStep5Request: DtoF4868Step5Request, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.storePersonalFormStep5(id, personalFormStep5Request, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores date provided during Step 6 of the personal form
         * @summary Saves Step 6 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step6Request} personalFormStep6Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePersonalFormStep6(id: string, personalFormStep6Request: DtoF4868Step6Request, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.storePersonalFormStep6(id, personalFormStep6Request, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores date provided during Step 7 of the personal form
         * @summary Saves Step 7 of the personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868Step7Request} personalFormStep7Request Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePersonalFormStep7(id: string, personalFormStep7Request: DtoF4868Step7Request, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.storePersonalFormStep7(id, personalFormStep7Request, options).then((request) => request(axios, basePath));
        },
        /**
         * Fully updates already created personal form. All step data should be collected and form should be already created.
         * @summary Fully updates already created personal form
         * @param {string} id Personal Form ID
         * @param {DtoF4868FullUpdateRequest} personalFormUpdateRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonalForm(id: string, personalFormUpdateRequest: DtoF4868FullUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtoF4868FullUpdateResponse> {
            return localVarFp.updatePersonalForm(id, personalFormUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Submits user intention ot e-file extension
         * @summary Submits user intention ot e-file extension
         * @param {string} id Personal Form ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        willEFilePersonalForm(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.willEFilePersonalForm(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonalFormsApi - object-oriented interface
 * @export
 * @class PersonalFormsApi
 * @extends {BaseAPI}
 */
export class PersonalFormsApi extends BaseAPI {
    /**
     * Creates new F4868 personal form and provides it\'s unique id. Other operations should be made using id provided
     * @summary Creates new F4868 personal form
     * @param {DtoF4868CreateRequest} personalFormCreateRequest Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalFormsApi
     */
    public createPersonalForm(personalFormCreateRequest: DtoF4868CreateRequest, options?: RawAxiosRequestConfig) {
        return PersonalFormsApiFp(this.configuration).createPersonalForm(personalFormCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets current user PersonalForms
     * @summary Gets current user PersonalForms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalFormsApi
     */
    public getAllPersonalForms(options?: RawAxiosRequestConfig) {
        return PersonalFormsApiFp(this.configuration).getAllPersonalForms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets current user PersonalForm
     * @summary Gets current user PersonalForm
     * @param {string} id Personal Form ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalFormsApi
     */
    public getPersonalFormById(id: string, options?: RawAxiosRequestConfig) {
        return PersonalFormsApiFp(this.configuration).getPersonalFormById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores date provided during Step 2 of the personal form
     * @summary Saves Step 2 of the personal form
     * @param {string} id Personal Form ID
     * @param {DtoF4868Step2Request} personalFormStep2Request Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalFormsApi
     */
    public storePersonalFormStep2(id: string, personalFormStep2Request: DtoF4868Step2Request, options?: RawAxiosRequestConfig) {
        return PersonalFormsApiFp(this.configuration).storePersonalFormStep2(id, personalFormStep2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores date provided during Step 3 of the personal form
     * @summary Saves Step 3 of the personal form
     * @param {string} id Personal Form ID
     * @param {DtoF4868Step3Request} personalFormStep3Request Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalFormsApi
     */
    public storePersonalFormStep3(id: string, personalFormStep3Request: DtoF4868Step3Request, options?: RawAxiosRequestConfig) {
        return PersonalFormsApiFp(this.configuration).storePersonalFormStep3(id, personalFormStep3Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores date provided during Step 4 of the personal form
     * @summary Saves Step 4 of the personal form
     * @param {string} id Personal Form ID
     * @param {DtoF4868Step4Request} personalFormStep4Request Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalFormsApi
     */
    public storePersonalFormStep4(id: string, personalFormStep4Request: DtoF4868Step4Request, options?: RawAxiosRequestConfig) {
        return PersonalFormsApiFp(this.configuration).storePersonalFormStep4(id, personalFormStep4Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores date provided during Step 5 of the personal form
     * @summary Saves Step 5 of the personal form
     * @param {string} id Personal Form ID
     * @param {DtoF4868Step5Request} personalFormStep5Request Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalFormsApi
     */
    public storePersonalFormStep5(id: string, personalFormStep5Request: DtoF4868Step5Request, options?: RawAxiosRequestConfig) {
        return PersonalFormsApiFp(this.configuration).storePersonalFormStep5(id, personalFormStep5Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores date provided during Step 6 of the personal form
     * @summary Saves Step 6 of the personal form
     * @param {string} id Personal Form ID
     * @param {DtoF4868Step6Request} personalFormStep6Request Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalFormsApi
     */
    public storePersonalFormStep6(id: string, personalFormStep6Request: DtoF4868Step6Request, options?: RawAxiosRequestConfig) {
        return PersonalFormsApiFp(this.configuration).storePersonalFormStep6(id, personalFormStep6Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores date provided during Step 7 of the personal form
     * @summary Saves Step 7 of the personal form
     * @param {string} id Personal Form ID
     * @param {DtoF4868Step7Request} personalFormStep7Request Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalFormsApi
     */
    public storePersonalFormStep7(id: string, personalFormStep7Request: DtoF4868Step7Request, options?: RawAxiosRequestConfig) {
        return PersonalFormsApiFp(this.configuration).storePersonalFormStep7(id, personalFormStep7Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fully updates already created personal form. All step data should be collected and form should be already created.
     * @summary Fully updates already created personal form
     * @param {string} id Personal Form ID
     * @param {DtoF4868FullUpdateRequest} personalFormUpdateRequest Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalFormsApi
     */
    public updatePersonalForm(id: string, personalFormUpdateRequest: DtoF4868FullUpdateRequest, options?: RawAxiosRequestConfig) {
        return PersonalFormsApiFp(this.configuration).updatePersonalForm(id, personalFormUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submits user intention ot e-file extension
     * @summary Submits user intention ot e-file extension
     * @param {string} id Personal Form ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalFormsApi
     */
    public willEFilePersonalForm(id: string, options?: RawAxiosRequestConfig) {
        return PersonalFormsApiFp(this.configuration).willEFilePersonalForm(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PlannerApi - axios parameter creator
 * @export
 */
export const PlannerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Submits a customer event in planner to select a field and the value will be sent to ontraport
         * @summary CustomerPlannerEvent
         * @param {ResourcesClientPlannerEvent} body Planner events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerPlannerEvent: async (body: ResourcesClientPlannerEvent, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('customerPlannerEvent', 'body', body)
            const localVarPath = `/planner/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets list of sections, forms, answers and steps for the planner
         * @summary GetPlannerAnswers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerPlannerAnswers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/planner/answers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets list of sections, forms, answers and steps for the planner
         * @summary Gets planner data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanner: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/planner/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Saves list of sections, forms, answers and steps for the planner
         * @summary SaveCustomerPlannerAnswers
         * @param {ResourcesClientPlannerAnswer} body Planner steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCustomerPlannerAnswers: async (body: ResourcesClientPlannerAnswer, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('saveCustomerPlannerAnswers', 'body', body)
            const localVarPath = `/planner/answers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlannerApi - functional programming interface
 * @export
 */
export const PlannerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlannerApiAxiosParamCreator(configuration)
    return {
        /**
         * Submits a customer event in planner to select a field and the value will be sent to ontraport
         * @summary CustomerPlannerEvent
         * @param {ResourcesClientPlannerEvent} body Planner events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerPlannerEvent(body: ResourcesClientPlannerEvent, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerPlannerEvent(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlannerApi.customerPlannerEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets list of sections, forms, answers and steps for the planner
         * @summary GetPlannerAnswers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerPlannerAnswers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourcesClientPlannerAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerPlannerAnswers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlannerApi.getCustomerPlannerAnswers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets list of sections, forms, answers and steps for the planner
         * @summary Gets planner data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlanner(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResourcesPlannerStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlanner(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlannerApi.getPlanner']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Saves list of sections, forms, answers and steps for the planner
         * @summary SaveCustomerPlannerAnswers
         * @param {ResourcesClientPlannerAnswer} body Planner steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCustomerPlannerAnswers(body: ResourcesClientPlannerAnswer, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCustomerPlannerAnswers(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlannerApi.saveCustomerPlannerAnswers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PlannerApi - factory interface
 * @export
 */
export const PlannerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlannerApiFp(configuration)
    return {
        /**
         * Submits a customer event in planner to select a field and the value will be sent to ontraport
         * @summary CustomerPlannerEvent
         * @param {ResourcesClientPlannerEvent} body Planner events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerPlannerEvent(body: ResourcesClientPlannerEvent, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.customerPlannerEvent(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets list of sections, forms, answers and steps for the planner
         * @summary GetPlannerAnswers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerPlannerAnswers(options?: RawAxiosRequestConfig): AxiosPromise<ResourcesClientPlannerAnswer> {
            return localVarFp.getCustomerPlannerAnswers(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets list of sections, forms, answers and steps for the planner
         * @summary Gets planner data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanner(options?: RawAxiosRequestConfig): AxiosPromise<Array<ResourcesPlannerStep>> {
            return localVarFp.getPlanner(options).then((request) => request(axios, basePath));
        },
        /**
         * Saves list of sections, forms, answers and steps for the planner
         * @summary SaveCustomerPlannerAnswers
         * @param {ResourcesClientPlannerAnswer} body Planner steps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCustomerPlannerAnswers(body: ResourcesClientPlannerAnswer, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.saveCustomerPlannerAnswers(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlannerApi - object-oriented interface
 * @export
 * @class PlannerApi
 * @extends {BaseAPI}
 */
export class PlannerApi extends BaseAPI {
    /**
     * Submits a customer event in planner to select a field and the value will be sent to ontraport
     * @summary CustomerPlannerEvent
     * @param {ResourcesClientPlannerEvent} body Planner events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlannerApi
     */
    public customerPlannerEvent(body: ResourcesClientPlannerEvent, options?: RawAxiosRequestConfig) {
        return PlannerApiFp(this.configuration).customerPlannerEvent(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets list of sections, forms, answers and steps for the planner
     * @summary GetPlannerAnswers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlannerApi
     */
    public getCustomerPlannerAnswers(options?: RawAxiosRequestConfig) {
        return PlannerApiFp(this.configuration).getCustomerPlannerAnswers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets list of sections, forms, answers and steps for the planner
     * @summary Gets planner data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlannerApi
     */
    public getPlanner(options?: RawAxiosRequestConfig) {
        return PlannerApiFp(this.configuration).getPlanner(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Saves list of sections, forms, answers and steps for the planner
     * @summary SaveCustomerPlannerAnswers
     * @param {ResourcesClientPlannerAnswer} body Planner steps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlannerApi
     */
    public saveCustomerPlannerAnswers(body: ResourcesClientPlannerAnswer, options?: RawAxiosRequestConfig) {
        return PlannerApiFp(this.configuration).saveCustomerPlannerAnswers(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PurchasesApi - axios parameter creator
 * @export
 */
export const PurchasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all user pruchases. Does not return refunded or cancelled subscriptions or expired
         * @summary Returns all active user purchases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPurchases: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/purchases/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides product information for specified product SKU
         * @summary Provides product information for specified product SKU
         * @param {string} urlCode Code to extract url from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBySku: async (urlCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'urlCode' is not null or undefined
            assertParamExists('productBySku', 'urlCode', urlCode)
            const localVarPath = `/purchases/info/product/:sku`
                .replace(`{${"urlCode"}}`, encodeURIComponent(String(urlCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchasesApi - functional programming interface
 * @export
 */
export const PurchasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchasesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all user pruchases. Does not return refunded or cancelled subscriptions or expired
         * @summary Returns all active user purchases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPurchases(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchasesUserPurchaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPurchases(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasesApi.getUserPurchases']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provides product information for specified product SKU
         * @summary Provides product information for specified product SKU
         * @param {string} urlCode Code to extract url from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productBySku(urlCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoProductInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productBySku(urlCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasesApi.productBySku']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PurchasesApi - factory interface
 * @export
 */
export const PurchasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchasesApiFp(configuration)
    return {
        /**
         * Returns all user pruchases. Does not return refunded or cancelled subscriptions or expired
         * @summary Returns all active user purchases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPurchases(options?: RawAxiosRequestConfig): AxiosPromise<PurchasesUserPurchaseResponse> {
            return localVarFp.getUserPurchases(options).then((request) => request(axios, basePath));
        },
        /**
         * Provides product information for specified product SKU
         * @summary Provides product information for specified product SKU
         * @param {string} urlCode Code to extract url from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBySku(urlCode: string, options?: RawAxiosRequestConfig): AxiosPromise<DtoProductInfo> {
            return localVarFp.productBySku(urlCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchasesApi - object-oriented interface
 * @export
 * @class PurchasesApi
 * @extends {BaseAPI}
 */
export class PurchasesApi extends BaseAPI {
    /**
     * Returns all user pruchases. Does not return refunded or cancelled subscriptions or expired
     * @summary Returns all active user purchases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasesApi
     */
    public getUserPurchases(options?: RawAxiosRequestConfig) {
        return PurchasesApiFp(this.configuration).getUserPurchases(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides product information for specified product SKU
     * @summary Provides product information for specified product SKU
     * @param {string} urlCode Code to extract url from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasesApi
     */
    public productBySku(urlCode: string, options?: RawAxiosRequestConfig) {
        return PurchasesApiFp(this.configuration).productBySku(urlCode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReturnsApi - axios parameter creator
 * @export
 */
export const ReturnsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get All user returns
         * @summary Get All user returns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserReturns: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fyt/returns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get All user returns
         * @summary Get All user returns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reFetchUserReturns: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fyt/returns/force-fetch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReturnsApi - functional programming interface
 * @export
 */
export const ReturnsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReturnsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get All user returns
         * @summary Get All user returns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUserReturns(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<FytBasicFedReturn>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUserReturns(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReturnsApi.getAllUserReturns']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get All user returns
         * @summary Get All user returns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reFetchUserReturns(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<FytBasicFedReturn>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reFetchUserReturns(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReturnsApi.reFetchUserReturns']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReturnsApi - factory interface
 * @export
 */
export const ReturnsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReturnsApiFp(configuration)
    return {
        /**
         * Get All user returns
         * @summary Get All user returns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserReturns(options?: RawAxiosRequestConfig): AxiosPromise<Array<Array<FytBasicFedReturn>>> {
            return localVarFp.getAllUserReturns(options).then((request) => request(axios, basePath));
        },
        /**
         * Get All user returns
         * @summary Get All user returns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reFetchUserReturns(options?: RawAxiosRequestConfig): AxiosPromise<Array<Array<FytBasicFedReturn>>> {
            return localVarFp.reFetchUserReturns(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReturnsApi - object-oriented interface
 * @export
 * @class ReturnsApi
 * @extends {BaseAPI}
 */
export class ReturnsApi extends BaseAPI {
    /**
     * Get All user returns
     * @summary Get All user returns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public getAllUserReturns(options?: RawAxiosRequestConfig) {
        return ReturnsApiFp(this.configuration).getAllUserReturns(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get All user returns
     * @summary Get All user returns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsApi
     */
    public reFetchUserReturns(options?: RawAxiosRequestConfig) {
        return ReturnsApiFp(this.configuration).reFetchUserReturns(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SubscriptionsApi - axios parameter creator
 * @export
 */
export const SubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds shield subscription for the selected form
         * @summary Adds shield subscription for the selected form
         * @param {DtoShieldSubscriptionRequest} shieldSubscriptionRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addShieldSubscription: async (shieldSubscriptionRequest: DtoShieldSubscriptionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shieldSubscriptionRequest' is not null or undefined
            assertParamExists('addShieldSubscription', 'shieldSubscriptionRequest', shieldSubscriptionRequest)
            const localVarPath = `/subscriptions/shield/subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shieldSubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edits (enables or disables) file smart subscription for the form
         * @summary Edits (enables or disables) file smart subscription for the form
         * @param {DtoEditSubscriptionRequest} shieldSubscriptionEditRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBundleSubscription: async (shieldSubscriptionEditRequest: DtoEditSubscriptionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shieldSubscriptionEditRequest' is not null or undefined
            assertParamExists('editBundleSubscription', 'shieldSubscriptionEditRequest', shieldSubscriptionEditRequest)
            const localVarPath = `/subscriptions/bundle/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shieldSubscriptionEditRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edits (enables or disables) shield subscription for the form
         * @summary Edits (enables or disables) shield subscription for the form
         * @param {DtoEditSubscriptionRequest} shieldSubscriptionEditRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editShieldSubscription: async (shieldSubscriptionEditRequest: DtoEditSubscriptionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shieldSubscriptionEditRequest' is not null or undefined
            assertParamExists('editShieldSubscription', 'shieldSubscriptionEditRequest', shieldSubscriptionEditRequest)
            const localVarPath = `/subscriptions/shield/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shieldSubscriptionEditRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get bundle subscription for the form
         * @summary Get bundle subscription for the form
         * @param {string} subscriptionId SubscriptionId of the Shield subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBundleStatus: async (subscriptionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('getBundleStatus', 'subscriptionId', subscriptionId)
            const localVarPath = `/subscriptions/{subscriptionId}/bundle_status`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets selected form Shield subscription status
         * @summary Gets selected form Shield subscription status
         * @param {string} subscriptionId subscriptionId of the Shield subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShieldStatus: async (subscriptionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('getShieldStatus', 'subscriptionId', subscriptionId)
            const localVarPath = `/subscriptions/{subscriptionId}/shield_status`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns list of the payment profiles available for the user
         * @summary Returns list of the payment profiles available for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionStatuses: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscriptions/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns if user has expired subscriptions and product of the expired subscription
         * @summary Returns if user has expired subscriptions and product of the expired subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasExpiredSubscriptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscriptions/expired-subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionsApi - functional programming interface
 * @export
 */
export const SubscriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Adds shield subscription for the selected form
         * @summary Adds shield subscription for the selected form
         * @param {DtoShieldSubscriptionRequest} shieldSubscriptionRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addShieldSubscription(shieldSubscriptionRequest: DtoShieldSubscriptionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addShieldSubscription(shieldSubscriptionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.addShieldSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Edits (enables or disables) file smart subscription for the form
         * @summary Edits (enables or disables) file smart subscription for the form
         * @param {DtoEditSubscriptionRequest} shieldSubscriptionEditRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editBundleSubscription(shieldSubscriptionEditRequest: DtoEditSubscriptionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editBundleSubscription(shieldSubscriptionEditRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.editBundleSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Edits (enables or disables) shield subscription for the form
         * @summary Edits (enables or disables) shield subscription for the form
         * @param {DtoEditSubscriptionRequest} shieldSubscriptionEditRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editShieldSubscription(shieldSubscriptionEditRequest: DtoEditSubscriptionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editShieldSubscription(shieldSubscriptionEditRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.editShieldSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get bundle subscription for the form
         * @summary Get bundle subscription for the form
         * @param {string} subscriptionId SubscriptionId of the Shield subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBundleStatus(subscriptionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoSubscriptionDataRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBundleStatus(subscriptionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.getBundleStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets selected form Shield subscription status
         * @summary Gets selected form Shield subscription status
         * @param {string} subscriptionId subscriptionId of the Shield subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShieldStatus(subscriptionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoSubscriptionDataRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShieldStatus(subscriptionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.getShieldStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns list of the payment profiles available for the user
         * @summary Returns list of the payment profiles available for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionStatuses(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DtoSubscriptionDataRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionStatuses(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.getSubscriptionStatuses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns if user has expired subscriptions and product of the expired subscription
         * @summary Returns if user has expired subscriptions and product of the expired subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasExpiredSubscriptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionsExpiredSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasExpiredSubscriptions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubscriptionsApi.hasExpiredSubscriptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SubscriptionsApi - factory interface
 * @export
 */
export const SubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionsApiFp(configuration)
    return {
        /**
         * Adds shield subscription for the selected form
         * @summary Adds shield subscription for the selected form
         * @param {DtoShieldSubscriptionRequest} shieldSubscriptionRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addShieldSubscription(shieldSubscriptionRequest: DtoShieldSubscriptionRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.addShieldSubscription(shieldSubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Edits (enables or disables) file smart subscription for the form
         * @summary Edits (enables or disables) file smart subscription for the form
         * @param {DtoEditSubscriptionRequest} shieldSubscriptionEditRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBundleSubscription(shieldSubscriptionEditRequest: DtoEditSubscriptionRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.editBundleSubscription(shieldSubscriptionEditRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Edits (enables or disables) shield subscription for the form
         * @summary Edits (enables or disables) shield subscription for the form
         * @param {DtoEditSubscriptionRequest} shieldSubscriptionEditRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editShieldSubscription(shieldSubscriptionEditRequest: DtoEditSubscriptionRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.editShieldSubscription(shieldSubscriptionEditRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get bundle subscription for the form
         * @summary Get bundle subscription for the form
         * @param {string} subscriptionId SubscriptionId of the Shield subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBundleStatus(subscriptionId: string, options?: RawAxiosRequestConfig): AxiosPromise<DtoSubscriptionDataRecord> {
            return localVarFp.getBundleStatus(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets selected form Shield subscription status
         * @summary Gets selected form Shield subscription status
         * @param {string} subscriptionId subscriptionId of the Shield subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShieldStatus(subscriptionId: string, options?: RawAxiosRequestConfig): AxiosPromise<DtoSubscriptionDataRecord> {
            return localVarFp.getShieldStatus(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns list of the payment profiles available for the user
         * @summary Returns list of the payment profiles available for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionStatuses(options?: RawAxiosRequestConfig): AxiosPromise<Array<DtoSubscriptionDataRecord>> {
            return localVarFp.getSubscriptionStatuses(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns if user has expired subscriptions and product of the expired subscription
         * @summary Returns if user has expired subscriptions and product of the expired subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasExpiredSubscriptions(options?: RawAxiosRequestConfig): AxiosPromise<SubscriptionsExpiredSubscriptionResponse> {
            return localVarFp.hasExpiredSubscriptions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionsApi - object-oriented interface
 * @export
 * @class SubscriptionsApi
 * @extends {BaseAPI}
 */
export class SubscriptionsApi extends BaseAPI {
    /**
     * Adds shield subscription for the selected form
     * @summary Adds shield subscription for the selected form
     * @param {DtoShieldSubscriptionRequest} shieldSubscriptionRequest Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public addShieldSubscription(shieldSubscriptionRequest: DtoShieldSubscriptionRequest, options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).addShieldSubscription(shieldSubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edits (enables or disables) file smart subscription for the form
     * @summary Edits (enables or disables) file smart subscription for the form
     * @param {DtoEditSubscriptionRequest} shieldSubscriptionEditRequest Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public editBundleSubscription(shieldSubscriptionEditRequest: DtoEditSubscriptionRequest, options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).editBundleSubscription(shieldSubscriptionEditRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edits (enables or disables) shield subscription for the form
     * @summary Edits (enables or disables) shield subscription for the form
     * @param {DtoEditSubscriptionRequest} shieldSubscriptionEditRequest Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public editShieldSubscription(shieldSubscriptionEditRequest: DtoEditSubscriptionRequest, options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).editShieldSubscription(shieldSubscriptionEditRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get bundle subscription for the form
     * @summary Get bundle subscription for the form
     * @param {string} subscriptionId SubscriptionId of the Shield subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public getBundleStatus(subscriptionId: string, options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).getBundleStatus(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets selected form Shield subscription status
     * @summary Gets selected form Shield subscription status
     * @param {string} subscriptionId subscriptionId of the Shield subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public getShieldStatus(subscriptionId: string, options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).getShieldStatus(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns list of the payment profiles available for the user
     * @summary Returns list of the payment profiles available for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public getSubscriptionStatuses(options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).getSubscriptionStatuses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns if user has expired subscriptions and product of the expired subscription
     * @summary Returns if user has expired subscriptions and product of the expired subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public hasExpiredSubscriptions(options?: RawAxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).hasExpiredSubscriptions(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Call should be made whenever user reaches one of the checkout pages
         * @summary Call should be made whenever user reaches one of the checkout pages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reachedCheckout: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/visits/checkout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Call should be made whenever user reaches one of the pricing pages
         * @summary Call should be made whenever user reaches one of the pricing pages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reachedPricing: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/visits/pricing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Call should be made whenever user reaches one of the checkout pages
         * @summary Call should be made whenever user reaches one of the checkout pages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reachedCheckout(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reachedCheckout(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.reachedCheckout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Call should be made whenever user reaches one of the pricing pages
         * @summary Call should be made whenever user reaches one of the pricing pages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reachedPricing(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reachedPricing(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.reachedPricing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Call should be made whenever user reaches one of the checkout pages
         * @summary Call should be made whenever user reaches one of the checkout pages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reachedCheckout(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.reachedCheckout(options).then((request) => request(axios, basePath));
        },
        /**
         * Call should be made whenever user reaches one of the pricing pages
         * @summary Call should be made whenever user reaches one of the pricing pages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reachedPricing(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.reachedPricing(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Call should be made whenever user reaches one of the checkout pages
     * @summary Call should be made whenever user reaches one of the checkout pages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public reachedCheckout(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).reachedCheckout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Call should be made whenever user reaches one of the pricing pages
     * @summary Call should be made whenever user reaches one of the pricing pages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public reachedPricing(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).reachedPricing(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserProfileApi - axios parameter creator
 * @export
 */
export const UserProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Exchange coupon token to the coupon id that should be passed to the payment methods
         * @summary Exchange coupon token to the coupon id that should be passed to the payment methods
         * @param {string} couponCode CouponID provided via email or by other ways
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyCoupon: async (couponCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'couponCode' is not null or undefined
            assertParamExists('applyCoupon', 'couponCode', couponCode)
            const localVarPath = `/users/coupon/{couponCode}`
                .replace(`{${"couponCode"}}`, encodeURIComponent(String(couponCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * User finalizes email change request by passing token he got over the email
         * @summary User finalizes email change request by passing token he got over the email
         * @param {DtoChangeEmailRequest} changeEmailRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserEmail: async (changeEmailRequest: DtoChangeEmailRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeEmailRequest' is not null or undefined
            assertParamExists('changeUserEmail', 'changeEmailRequest', changeEmailRequest)
            const localVarPath = `/users/change_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {DtoChangeEmailIntentRequest} changeEmailIntent Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserMailIntent: async (changeEmailIntent: DtoChangeEmailIntentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeEmailIntent' is not null or undefined
            assertParamExists('changeUserMailIntent', 'changeEmailIntent', changeEmailIntent)
            const localVarPath = `/users/change_email_intent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeEmailIntent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * user changes his password
         * @summary User changes password
         * @param {DtoChangePasswordRequest} changePasswordRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword: async (changePasswordRequest: DtoChangePasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordRequest' is not null or undefined
            assertParamExists('changeUserPassword', 'changePasswordRequest', changePasswordRequest)
            const localVarPath = `/users/change_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * user changes his phone
         * @summary User changes own phone
         * @param {DtoChangePhoneRequest} changePhoneRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPhone: async (changePhoneRequest: DtoChangePhoneRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePhoneRequest' is not null or undefined
            assertParamExists('changeUserPhone', 'changePhoneRequest', changePhoneRequest)
            const localVarPath = `/users/change_phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePhoneRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {DtoChangeUsernameRequest} changeEmailIntent Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUsername: async (changeEmailIntent: DtoChangeUsernameRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeEmailIntent' is not null or undefined
            assertParamExists('changeUsername', 'changeEmailIntent', changeEmailIntent)
            const localVarPath = `/users/change_username`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeEmailIntent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Configures account that has no password to have one
         * @summary Configures account that has no password to have one
         * @param {UserSetPasswordRequest} changePasswordRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurePassword: async (changePasswordRequest: UserSetPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordRequest' is not null or undefined
            assertParamExists('configurePassword', 'changePasswordRequest', changePasswordRequest)
            const localVarPath = `/users/configure_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * User should consent to our terms and conditions right after social registration. Non consented user has not \'client\' scope
         * @summary Consent after social login if user just registered
         * @param {DtoConsentRequest} changeEmailIntent Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consentTermsAndConditions: async (changeEmailIntent: DtoConsentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeEmailIntent' is not null or undefined
            assertParamExists('consentTermsAndConditions', 'changeEmailIntent', changeEmailIntent)
            const localVarPath = `/users/consent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeEmailIntent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {string} [q] Query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSearch: async (q?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utils/contact-search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {UserContactUsRequest} changeEmailIntent Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactUs: async (changeEmailIntent: UserContactUsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeEmailIntent' is not null or undefined
            assertParamExists('contactUs', 'changeEmailIntent', changeEmailIntent)
            const localVarPath = `/users/contact-us`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeEmailIntent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resets user password using token received by email
         * @summary Resets user password using token received by email
         * @param {DtoForgotPasswordRequest} forgotPasswordRequest Creates an intent to change user\&#39;s password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (forgotPasswordRequest: DtoForgotPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordRequest' is not null or undefined
            assertParamExists('forgotPassword', 'forgotPasswordRequest', forgotPasswordRequest)
            const localVarPath = `/oauth/forgot_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates request to reset password and get recovery link via email
         * @summary User submits his intent to reset password via email
         * @param {DtoForgotPasswordIntent} forgotPasswordIntent Creates an intent to change user\&#39;s password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPasswordIntent: async (forgotPasswordIntent: DtoForgotPasswordIntent, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordIntent' is not null or undefined
            assertParamExists('forgotPasswordIntent', 'forgotPasswordIntent', forgotPasswordIntent)
            const localVarPath = `/oauth/forgot_password_intent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordIntent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores Redtrack click id in cache
         * @summary Stores Redtrack click id in cache
         * @param {string} couponId CouponID provided by code to id exchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponDetails: async (couponId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'couponId' is not null or undefined
            assertParamExists('getCouponDetails', 'couponId', couponId)
            const localVarPath = `/users/coupon`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)

            if (couponId !== undefined) {
                localVarQueryParameter['coupon_id'] = couponId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets User Settings
         * @summary Gets User Settings
         * @param {string} [funnel] Funnel To Serve.
         * @param {string} [formId] Form id to use for funnel operations
         * @param {Array<string>} [cartProducts] Products that are already in the cart
         * @param {string} [rtkclid] Redtrack click id
         * @param {Array<string>} [purchases] Products that user already have purchased
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (funnel?: string, formId?: string, cartProducts?: Array<string>, rtkclid?: string, purchases?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)

            if (funnel !== undefined) {
                localVarQueryParameter['funnel'] = funnel;
            }

            if (formId !== undefined) {
                localVarQueryParameter['form_id'] = formId;
            }

            if (cartProducts) {
                localVarQueryParameter['cart_products'] = cartProducts.join(COLLECTION_FORMATS.csv);
            }

            if (rtkclid !== undefined) {
                localVarQueryParameter['rtkclid'] = rtkclid;
            }

            if (purchases) {
                localVarQueryParameter['purchases'] = purchases.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets current user profile
         * @summary Gets current user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserProductFocusSetRequest} setFocusRequest Sets or resets product focus for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetProductFocus: async (setFocusRequest: UserProductFocusSetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setFocusRequest' is not null or undefined
            assertParamExists('resetProductFocus', 'setFocusRequest', setFocusRequest)
            const localVarPath = `/users/focus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setFocusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates traffis source info for better tracking
         * @summary Updates info connected to traffic source
         * @param {UserTrafficSourceRequest} personalFormStep6Request Traffic source information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTrafficSource: async (personalFormStep6Request: UserTrafficSourceRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personalFormStep6Request' is not null or undefined
            assertParamExists('setTrafficSource', 'personalFormStep6Request', personalFormStep6Request)
            const localVarPath = `/users/traffic-source`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personalFormStep6Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Saves user rating
         * @summary Saves user rating
         * @param {string} [rating] Rating user left on the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserRating: async (rating?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/rate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)

            if (rating !== undefined) {
                localVarQueryParameter['rating'] = rating;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores Redtrack click id in cache
         * @summary Stores Redtrack click id in cache
         * @param {DtoRedtrackClickIdStoreRequest} redtrackClickIdStoreRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeRedtrackData: async (redtrackClickIdStoreRequest: DtoRedtrackClickIdStoreRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'redtrackClickIdStoreRequest' is not null or undefined
            assertParamExists('storeRedtrackData', 'redtrackClickIdStoreRequest', redtrackClickIdStoreRequest)
            const localVarPath = `/users/redtrack/store`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(redtrackClickIdStoreRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileApi - functional programming interface
 * @export
 */
export const UserProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * Exchange coupon token to the coupon id that should be passed to the payment methods
         * @summary Exchange coupon token to the coupon id that should be passed to the payment methods
         * @param {string} couponCode CouponID provided via email or by other ways
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyCoupon(couponCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoCouponDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyCoupon(couponCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.applyCoupon']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * User finalizes email change request by passing token he got over the email
         * @summary User finalizes email change request by passing token he got over the email
         * @param {DtoChangeEmailRequest} changeEmailRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserEmail(changeEmailRequest: DtoChangeEmailRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserEmail(changeEmailRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.changeUserEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {DtoChangeEmailIntentRequest} changeEmailIntent Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserMailIntent(changeEmailIntent: DtoChangeEmailIntentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserMailIntent(changeEmailIntent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.changeUserMailIntent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * user changes his password
         * @summary User changes password
         * @param {DtoChangePasswordRequest} changePasswordRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserPassword(changePasswordRequest: DtoChangePasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserPassword(changePasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.changeUserPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * user changes his phone
         * @summary User changes own phone
         * @param {DtoChangePhoneRequest} changePhoneRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserPhone(changePhoneRequest: DtoChangePhoneRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoUserProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserPhone(changePhoneRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.changeUserPhone']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {DtoChangeUsernameRequest} changeEmailIntent Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUsername(changeEmailIntent: DtoChangeUsernameRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUsername(changeEmailIntent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.changeUsername']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Configures account that has no password to have one
         * @summary Configures account that has no password to have one
         * @param {UserSetPasswordRequest} changePasswordRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurePassword(changePasswordRequest: UserSetPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurePassword(changePasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.configurePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * User should consent to our terms and conditions right after social registration. Non consented user has not \'client\' scope
         * @summary Consent after social login if user just registered
         * @param {DtoConsentRequest} changeEmailIntent Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consentTermsAndConditions(changeEmailIntent: DtoConsentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consentTermsAndConditions(changeEmailIntent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.consentTermsAndConditions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {string} [q] Query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactSearch(q?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UtilsArticle>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactSearch(q, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.contactSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {UserContactUsRequest} changeEmailIntent Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactUs(changeEmailIntent: UserContactUsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserContactUsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactUs(changeEmailIntent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.contactUs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Resets user password using token received by email
         * @summary Resets user password using token received by email
         * @param {DtoForgotPasswordRequest} forgotPasswordRequest Creates an intent to change user\&#39;s password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(forgotPasswordRequest: DtoForgotPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoRefreshTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(forgotPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.forgotPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates request to reset password and get recovery link via email
         * @summary User submits his intent to reset password via email
         * @param {DtoForgotPasswordIntent} forgotPasswordIntent Creates an intent to change user\&#39;s password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPasswordIntent(forgotPasswordIntent: DtoForgotPasswordIntent, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPasswordIntent(forgotPasswordIntent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.forgotPasswordIntent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores Redtrack click id in cache
         * @summary Stores Redtrack click id in cache
         * @param {string} couponId CouponID provided by code to id exchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCouponDetails(couponId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoCouponDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCouponDetails(couponId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.getCouponDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets User Settings
         * @summary Gets User Settings
         * @param {string} [funnel] Funnel To Serve.
         * @param {string} [formId] Form id to use for funnel operations
         * @param {Array<string>} [cartProducts] Products that are already in the cart
         * @param {string} [rtkclid] Redtrack click id
         * @param {Array<string>} [purchases] Products that user already have purchased
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(funnel?: string, formId?: string, cartProducts?: Array<string>, rtkclid?: string, purchases?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoUserSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(funnel, formId, cartProducts, rtkclid, purchases, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.getSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets current user profile
         * @summary Gets current user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProfile(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoUserProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfile(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.getUserProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserProductFocusSetRequest} setFocusRequest Sets or resets product focus for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetProductFocus(setFocusRequest: UserProductFocusSetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetProductFocus(setFocusRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.resetProductFocus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates traffis source info for better tracking
         * @summary Updates info connected to traffic source
         * @param {UserTrafficSourceRequest} personalFormStep6Request Traffic source information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTrafficSource(personalFormStep6Request: UserTrafficSourceRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTrafficSource(personalFormStep6Request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.setTrafficSource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Saves user rating
         * @summary Saves user rating
         * @param {string} [rating] Rating user left on the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserRating(rating?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserRating(rating, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.setUserRating']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stores Redtrack click id in cache
         * @summary Stores Redtrack click id in cache
         * @param {DtoRedtrackClickIdStoreRequest} redtrackClickIdStoreRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeRedtrackData(redtrackClickIdStoreRequest: DtoRedtrackClickIdStoreRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeRedtrackData(redtrackClickIdStoreRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.storeRedtrackData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserProfileApi - factory interface
 * @export
 */
export const UserProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileApiFp(configuration)
    return {
        /**
         * Exchange coupon token to the coupon id that should be passed to the payment methods
         * @summary Exchange coupon token to the coupon id that should be passed to the payment methods
         * @param {string} couponCode CouponID provided via email or by other ways
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyCoupon(couponCode: string, options?: RawAxiosRequestConfig): AxiosPromise<DtoCouponDetails> {
            return localVarFp.applyCoupon(couponCode, options).then((request) => request(axios, basePath));
        },
        /**
         * User finalizes email change request by passing token he got over the email
         * @summary User finalizes email change request by passing token he got over the email
         * @param {DtoChangeEmailRequest} changeEmailRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserEmail(changeEmailRequest: DtoChangeEmailRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtoLoginResponse> {
            return localVarFp.changeUserEmail(changeEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {DtoChangeEmailIntentRequest} changeEmailIntent Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserMailIntent(changeEmailIntent: DtoChangeEmailIntentRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.changeUserMailIntent(changeEmailIntent, options).then((request) => request(axios, basePath));
        },
        /**
         * user changes his password
         * @summary User changes password
         * @param {DtoChangePasswordRequest} changePasswordRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword(changePasswordRequest: DtoChangePasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.changeUserPassword(changePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * user changes his phone
         * @summary User changes own phone
         * @param {DtoChangePhoneRequest} changePhoneRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPhone(changePhoneRequest: DtoChangePhoneRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtoUserProfile> {
            return localVarFp.changeUserPhone(changePhoneRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {DtoChangeUsernameRequest} changeEmailIntent Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUsername(changeEmailIntent: DtoChangeUsernameRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.changeUsername(changeEmailIntent, options).then((request) => request(axios, basePath));
        },
        /**
         * Configures account that has no password to have one
         * @summary Configures account that has no password to have one
         * @param {UserSetPasswordRequest} changePasswordRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurePassword(changePasswordRequest: UserSetPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.configurePassword(changePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * User should consent to our terms and conditions right after social registration. Non consented user has not \'client\' scope
         * @summary Consent after social login if user just registered
         * @param {DtoConsentRequest} changeEmailIntent Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consentTermsAndConditions(changeEmailIntent: DtoConsentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtoLoginResponse> {
            return localVarFp.consentTermsAndConditions(changeEmailIntent, options).then((request) => request(axios, basePath));
        },
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {string} [q] Query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSearch(q?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<UtilsArticle>> {
            return localVarFp.contactSearch(q, options).then((request) => request(axios, basePath));
        },
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {UserContactUsRequest} changeEmailIntent Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactUs(changeEmailIntent: UserContactUsRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserContactUsResponse> {
            return localVarFp.contactUs(changeEmailIntent, options).then((request) => request(axios, basePath));
        },
        /**
         * Resets user password using token received by email
         * @summary Resets user password using token received by email
         * @param {DtoForgotPasswordRequest} forgotPasswordRequest Creates an intent to change user\&#39;s password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPasswordRequest: DtoForgotPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtoRefreshTokenResponse> {
            return localVarFp.forgotPassword(forgotPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates request to reset password and get recovery link via email
         * @summary User submits his intent to reset password via email
         * @param {DtoForgotPasswordIntent} forgotPasswordIntent Creates an intent to change user\&#39;s password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPasswordIntent(forgotPasswordIntent: DtoForgotPasswordIntent, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forgotPasswordIntent(forgotPasswordIntent, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores Redtrack click id in cache
         * @summary Stores Redtrack click id in cache
         * @param {string} couponId CouponID provided by code to id exchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponDetails(couponId: string, options?: RawAxiosRequestConfig): AxiosPromise<DtoCouponDetails> {
            return localVarFp.getCouponDetails(couponId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets User Settings
         * @summary Gets User Settings
         * @param {string} [funnel] Funnel To Serve.
         * @param {string} [formId] Form id to use for funnel operations
         * @param {Array<string>} [cartProducts] Products that are already in the cart
         * @param {string} [rtkclid] Redtrack click id
         * @param {Array<string>} [purchases] Products that user already have purchased
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(funnel?: string, formId?: string, cartProducts?: Array<string>, rtkclid?: string, purchases?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<DtoUserSettingsResponse> {
            return localVarFp.getSettings(funnel, formId, cartProducts, rtkclid, purchases, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets current user profile
         * @summary Gets current user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile(options?: RawAxiosRequestConfig): AxiosPromise<DtoUserProfile> {
            return localVarFp.getUserProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserProductFocusSetRequest} setFocusRequest Sets or resets product focus for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetProductFocus(setFocusRequest: UserProductFocusSetRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.resetProductFocus(setFocusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates traffis source info for better tracking
         * @summary Updates info connected to traffic source
         * @param {UserTrafficSourceRequest} personalFormStep6Request Traffic source information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTrafficSource(personalFormStep6Request: UserTrafficSourceRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.setTrafficSource(personalFormStep6Request, options).then((request) => request(axios, basePath));
        },
        /**
         * Saves user rating
         * @summary Saves user rating
         * @param {string} [rating] Rating user left on the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserRating(rating?: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.setUserRating(rating, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores Redtrack click id in cache
         * @summary Stores Redtrack click id in cache
         * @param {DtoRedtrackClickIdStoreRequest} redtrackClickIdStoreRequest Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeRedtrackData(redtrackClickIdStoreRequest: DtoRedtrackClickIdStoreRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.storeRedtrackData(redtrackClickIdStoreRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileApi - object-oriented interface
 * @export
 * @class UserProfileApi
 * @extends {BaseAPI}
 */
export class UserProfileApi extends BaseAPI {
    /**
     * Exchange coupon token to the coupon id that should be passed to the payment methods
     * @summary Exchange coupon token to the coupon id that should be passed to the payment methods
     * @param {string} couponCode CouponID provided via email or by other ways
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public applyCoupon(couponCode: string, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).applyCoupon(couponCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * User finalizes email change request by passing token he got over the email
     * @summary User finalizes email change request by passing token he got over the email
     * @param {DtoChangeEmailRequest} changeEmailRequest Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public changeUserEmail(changeEmailRequest: DtoChangeEmailRequest, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).changeUserEmail(changeEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * user submits request to change email. He should confirm it by email by clicking the link
     * @summary User submits request to change email
     * @param {DtoChangeEmailIntentRequest} changeEmailIntent Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public changeUserMailIntent(changeEmailIntent: DtoChangeEmailIntentRequest, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).changeUserMailIntent(changeEmailIntent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * user changes his password
     * @summary User changes password
     * @param {DtoChangePasswordRequest} changePasswordRequest Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public changeUserPassword(changePasswordRequest: DtoChangePasswordRequest, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).changeUserPassword(changePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * user changes his phone
     * @summary User changes own phone
     * @param {DtoChangePhoneRequest} changePhoneRequest Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public changeUserPhone(changePhoneRequest: DtoChangePhoneRequest, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).changeUserPhone(changePhoneRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * user submits request to change email. He should confirm it by email by clicking the link
     * @summary User submits request to change email
     * @param {DtoChangeUsernameRequest} changeEmailIntent Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public changeUsername(changeEmailIntent: DtoChangeUsernameRequest, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).changeUsername(changeEmailIntent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Configures account that has no password to have one
     * @summary Configures account that has no password to have one
     * @param {UserSetPasswordRequest} changePasswordRequest Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public configurePassword(changePasswordRequest: UserSetPasswordRequest, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).configurePassword(changePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * User should consent to our terms and conditions right after social registration. Non consented user has not \'client\' scope
     * @summary Consent after social login if user just registered
     * @param {DtoConsentRequest} changeEmailIntent Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public consentTermsAndConditions(changeEmailIntent: DtoConsentRequest, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).consentTermsAndConditions(changeEmailIntent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * user submits request to change email. He should confirm it by email by clicking the link
     * @summary User submits request to change email
     * @param {string} [q] Query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public contactSearch(q?: string, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).contactSearch(q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * user submits request to change email. He should confirm it by email by clicking the link
     * @summary User submits request to change email
     * @param {UserContactUsRequest} changeEmailIntent Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public contactUs(changeEmailIntent: UserContactUsRequest, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).contactUs(changeEmailIntent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Resets user password using token received by email
     * @summary Resets user password using token received by email
     * @param {DtoForgotPasswordRequest} forgotPasswordRequest Creates an intent to change user\&#39;s password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public forgotPassword(forgotPasswordRequest: DtoForgotPasswordRequest, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).forgotPassword(forgotPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates request to reset password and get recovery link via email
     * @summary User submits his intent to reset password via email
     * @param {DtoForgotPasswordIntent} forgotPasswordIntent Creates an intent to change user\&#39;s password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public forgotPasswordIntent(forgotPasswordIntent: DtoForgotPasswordIntent, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).forgotPasswordIntent(forgotPasswordIntent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores Redtrack click id in cache
     * @summary Stores Redtrack click id in cache
     * @param {string} couponId CouponID provided by code to id exchange
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public getCouponDetails(couponId: string, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).getCouponDetails(couponId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets User Settings
     * @summary Gets User Settings
     * @param {string} [funnel] Funnel To Serve.
     * @param {string} [formId] Form id to use for funnel operations
     * @param {Array<string>} [cartProducts] Products that are already in the cart
     * @param {string} [rtkclid] Redtrack click id
     * @param {Array<string>} [purchases] Products that user already have purchased
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public getSettings(funnel?: string, formId?: string, cartProducts?: Array<string>, rtkclid?: string, purchases?: Array<string>, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).getSettings(funnel, formId, cartProducts, rtkclid, purchases, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets current user profile
     * @summary Gets current user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public getUserProfile(options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).getUserProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserProductFocusSetRequest} setFocusRequest Sets or resets product focus for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public resetProductFocus(setFocusRequest: UserProductFocusSetRequest, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).resetProductFocus(setFocusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates traffis source info for better tracking
     * @summary Updates info connected to traffic source
     * @param {UserTrafficSourceRequest} personalFormStep6Request Traffic source information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public setTrafficSource(personalFormStep6Request: UserTrafficSourceRequest, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).setTrafficSource(personalFormStep6Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Saves user rating
     * @summary Saves user rating
     * @param {string} [rating] Rating user left on the page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public setUserRating(rating?: string, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).setUserRating(rating, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores Redtrack click id in cache
     * @summary Stores Redtrack click id in cache
     * @param {DtoRedtrackClickIdStoreRequest} redtrackClickIdStoreRequest Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public storeRedtrackData(redtrackClickIdStoreRequest: DtoRedtrackClickIdStoreRequest, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).storeRedtrackData(redtrackClickIdStoreRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserUtilsApi - axios parameter creator
 * @export
 */
export const UserUtilsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {string} formId Name of the company to get code for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAutoExtend: async (formId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('cancelAutoExtend', 'formId', formId)
            const localVarPath = `/utils/form/:subscriptionId/auto-extend/cancel`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {string} companyName Name of the company to get code for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyNameToBusinessCode: async (companyName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyName' is not null or undefined
            assertParamExists('companyNameToBusinessCode', 'companyName', companyName)
            const localVarPath = `/utils/business_code/{companyName}`
                .replace(`{${"companyName"}}`, encodeURIComponent(String(companyName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Expand short url to the full form
         * @summary Expands short url to the full
         * @param {string} urlCode Code to extract url from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expandURL: async (urlCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'urlCode' is not null or undefined
            assertParamExists('expandURL', 'urlCode', urlCode)
            const localVarPath = `/utils/go/{urlCode}`
                .replace(`{${"urlCode"}}`, encodeURIComponent(String(urlCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets ip of the requester
         * @summary Gets ip of the requester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientIp: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utils/ip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {string} userId Ontraport User Id to check for the payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ontraportPurchaseWebhook: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('ontraportPurchaseWebhook', 'userId', userId)
            const localVarPath = `/webhook/ontraport/purchase/:userId`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {DtoEmailValidationRequest} emailValidationRequest Email to validate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEmail: async (emailValidationRequest: DtoEmailValidationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailValidationRequest' is not null or undefined
            assertParamExists('validateEmail', 'emailValidationRequest', emailValidationRequest)
            const localVarPath = `/utils/validate/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailValidationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {DtoPasswordValidationRequest} passwordValidationRequest Validate password in combination with email username etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePassword: async (passwordValidationRequest: DtoPasswordValidationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordValidationRequest' is not null or undefined
            assertParamExists('validatePassword', 'passwordValidationRequest', passwordValidationRequest)
            const localVarPath = `/utils/validate/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordValidationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks if user passed valid phone number
         * @summary Checks if user passed valid phone number
         * @param {UtilsPhoneValidationRequest} passwordValidationRequest Request with the phone number to validate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePhone: async (passwordValidationRequest: UtilsPhoneValidationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordValidationRequest' is not null or undefined
            assertParamExists('validatePhone', 'passwordValidationRequest', passwordValidationRequest)
            const localVarPath = `/utils/validate/phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordValidationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {string} zip Zip to check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateZip: async (zip: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zip' is not null or undefined
            assertParamExists('validateZip', 'zip', zip)
            const localVarPath = `/utils/validate/zip/{zip}`
                .replace(`{${"zip"}}`, encodeURIComponent(String(zip)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserUtilsApi - functional programming interface
 * @export
 */
export const UserUtilsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserUtilsApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {string} formId Name of the company to get code for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelAutoExtend(formId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelAutoExtend(formId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserUtilsApi.cancelAutoExtend']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {string} companyName Name of the company to get code for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyNameToBusinessCode(companyName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoBusinessCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyNameToBusinessCode(companyName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserUtilsApi.companyNameToBusinessCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Expand short url to the full form
         * @summary Expands short url to the full
         * @param {string} urlCode Code to extract url from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expandURL(urlCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expandURL(urlCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserUtilsApi.expandURL']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets ip of the requester
         * @summary Gets ip of the requester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientIp(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientIp(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserUtilsApi.getClientIp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {string} userId Ontraport User Id to check for the payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ontraportPurchaseWebhook(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoZipResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ontraportPurchaseWebhook(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserUtilsApi.ontraportPurchaseWebhook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {DtoEmailValidationRequest} emailValidationRequest Email to validate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateEmail(emailValidationRequest: DtoEmailValidationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateEmail(emailValidationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserUtilsApi.validateEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {DtoPasswordValidationRequest} passwordValidationRequest Validate password in combination with email username etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePassword(passwordValidationRequest: DtoPasswordValidationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validatePassword(passwordValidationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserUtilsApi.validatePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Checks if user passed valid phone number
         * @summary Checks if user passed valid phone number
         * @param {UtilsPhoneValidationRequest} passwordValidationRequest Request with the phone number to validate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePhone(passwordValidationRequest: UtilsPhoneValidationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validatePhone(passwordValidationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserUtilsApi.validatePhone']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {string} zip Zip to check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateZip(zip: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtoZipResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateZip(zip, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserUtilsApi.validateZip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserUtilsApi - factory interface
 * @export
 */
export const UserUtilsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserUtilsApiFp(configuration)
    return {
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {string} formId Name of the company to get code for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAutoExtend(formId: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.cancelAutoExtend(formId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {string} companyName Name of the company to get code for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyNameToBusinessCode(companyName: string, options?: RawAxiosRequestConfig): AxiosPromise<DtoBusinessCodeResponse> {
            return localVarFp.companyNameToBusinessCode(companyName, options).then((request) => request(axios, basePath));
        },
        /**
         * Expand short url to the full form
         * @summary Expands short url to the full
         * @param {string} urlCode Code to extract url from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expandURL(urlCode: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.expandURL(urlCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets ip of the requester
         * @summary Gets ip of the requester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientIp(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.getClientIp(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {string} userId Ontraport User Id to check for the payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ontraportPurchaseWebhook(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<DtoZipResponse> {
            return localVarFp.ontraportPurchaseWebhook(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {DtoEmailValidationRequest} emailValidationRequest Email to validate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEmail(emailValidationRequest: DtoEmailValidationRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.validateEmail(emailValidationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {DtoPasswordValidationRequest} passwordValidationRequest Validate password in combination with email username etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePassword(passwordValidationRequest: DtoPasswordValidationRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.validatePassword(passwordValidationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Checks if user passed valid phone number
         * @summary Checks if user passed valid phone number
         * @param {UtilsPhoneValidationRequest} passwordValidationRequest Request with the phone number to validate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePhone(passwordValidationRequest: UtilsPhoneValidationRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.validatePhone(passwordValidationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets signed UserId for the new user
         * @summary Gets signed UserId for the new user
         * @param {string} zip Zip to check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateZip(zip: string, options?: RawAxiosRequestConfig): AxiosPromise<DtoZipResponse> {
            return localVarFp.validateZip(zip, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserUtilsApi - object-oriented interface
 * @export
 * @class UserUtilsApi
 * @extends {BaseAPI}
 */
export class UserUtilsApi extends BaseAPI {
    /**
     * Gets signed UserId for the new user
     * @summary Gets signed UserId for the new user
     * @param {string} formId Name of the company to get code for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserUtilsApi
     */
    public cancelAutoExtend(formId: string, options?: RawAxiosRequestConfig) {
        return UserUtilsApiFp(this.configuration).cancelAutoExtend(formId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets signed UserId for the new user
     * @summary Gets signed UserId for the new user
     * @param {string} companyName Name of the company to get code for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserUtilsApi
     */
    public companyNameToBusinessCode(companyName: string, options?: RawAxiosRequestConfig) {
        return UserUtilsApiFp(this.configuration).companyNameToBusinessCode(companyName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Expand short url to the full form
     * @summary Expands short url to the full
     * @param {string} urlCode Code to extract url from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserUtilsApi
     */
    public expandURL(urlCode: string, options?: RawAxiosRequestConfig) {
        return UserUtilsApiFp(this.configuration).expandURL(urlCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets ip of the requester
     * @summary Gets ip of the requester
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserUtilsApi
     */
    public getClientIp(options?: RawAxiosRequestConfig) {
        return UserUtilsApiFp(this.configuration).getClientIp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets signed UserId for the new user
     * @summary Gets signed UserId for the new user
     * @param {string} userId Ontraport User Id to check for the payment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserUtilsApi
     */
    public ontraportPurchaseWebhook(userId: string, options?: RawAxiosRequestConfig) {
        return UserUtilsApiFp(this.configuration).ontraportPurchaseWebhook(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets signed UserId for the new user
     * @summary Gets signed UserId for the new user
     * @param {DtoEmailValidationRequest} emailValidationRequest Email to validate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserUtilsApi
     */
    public validateEmail(emailValidationRequest: DtoEmailValidationRequest, options?: RawAxiosRequestConfig) {
        return UserUtilsApiFp(this.configuration).validateEmail(emailValidationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets signed UserId for the new user
     * @summary Gets signed UserId for the new user
     * @param {DtoPasswordValidationRequest} passwordValidationRequest Validate password in combination with email username etc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserUtilsApi
     */
    public validatePassword(passwordValidationRequest: DtoPasswordValidationRequest, options?: RawAxiosRequestConfig) {
        return UserUtilsApiFp(this.configuration).validatePassword(passwordValidationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Checks if user passed valid phone number
     * @summary Checks if user passed valid phone number
     * @param {UtilsPhoneValidationRequest} passwordValidationRequest Request with the phone number to validate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserUtilsApi
     */
    public validatePhone(passwordValidationRequest: UtilsPhoneValidationRequest, options?: RawAxiosRequestConfig) {
        return UserUtilsApiFp(this.configuration).validatePhone(passwordValidationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets signed UserId for the new user
     * @summary Gets signed UserId for the new user
     * @param {string} zip Zip to check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserUtilsApi
     */
    public validateZip(zip: string, options?: RawAxiosRequestConfig) {
        return UserUtilsApiFp(this.configuration).validateZip(zip, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilsApi - axios parameter creator
 * @export
 */
export const UtilsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {UtilsContactUsRequest} data Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        anonymousContactUs: async (data: UtilsContactUsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('anonymousContactUs', 'data', data)
            const localVarPath = `/utils/contact-us`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets information about form filing deadlines for this year and all previous
         * @summary Gets information about form filing deadlines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourses: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utils/courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets information about form filing deadlines for this year and all previous
         * @summary Gets information about form filing deadlines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeadlines: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utils/deadlines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets users deduction answers
         * @summary Gets users deduction answers
         * @param {string} [email] email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeductionAnswers: async (email?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utils/deductions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets information about form filing deadlines for this year and all previous
         * @summary Gets information about form filing deadlines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeductions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utils/deduction-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets info about products we offer for the user to purchase
         * @summary Gets info about products we offer for the user to purchase
         * @param {string} [coupon] Coupon conde to apply to the products prices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsShort: async (coupon?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utils/products-short`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (coupon !== undefined) {
                localVarQueryParameter['coupon'] = coupon;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets information about all possible tax preparers
         * @summary Gets information about tax preparers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxPreparers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utils/tax-preparers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes preconfigured users that have social auth
         * @summary Removes preconfigured users that have social auth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetTestSocial: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utils/tests/reset-social`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stored user\'s responses on deductions questions
         * @summary Stores user\'s responses on deductions questions
         * @param {UtilsDeductionAnswers} answers User deduction answers
         * @param {string} [email] email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeDeductions: async (answers: UtilsDeductionAnswers, email?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'answers' is not null or undefined
            assertParamExists('storeDeductions', 'answers', answers)
            const localVarPath = `/utils/deductions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(answers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {UploadContactUsFileRequest} uploadContactUsFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadContactUsFile: async (uploadContactUsFileRequest: UploadContactUsFileRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadContactUsFileRequest' is not null or undefined
            assertParamExists('uploadContactUsFile', 'uploadContactUsFileRequest', uploadContactUsFileRequest)
            const localVarPath = `/utils/attachment-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadContactUsFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilsApi - functional programming interface
 * @export
 */
export const UtilsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilsApiAxiosParamCreator(configuration)
    return {
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {UtilsContactUsRequest} data Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async anonymousContactUs(data: UtilsContactUsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsContactUsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.anonymousContactUs(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilsApi.anonymousContactUs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets information about form filing deadlines for this year and all previous
         * @summary Gets information about form filing deadlines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourses(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UtilsCourse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourses(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilsApi.getCourses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets information about form filing deadlines for this year and all previous
         * @summary Gets information about form filing deadlines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeadlines(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: DtoDeadlineResponse; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeadlines(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilsApi.getDeadlines']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets users deduction answers
         * @summary Gets users deduction answers
         * @param {string} [email] email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeductionAnswers(email?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsDeductionAnswers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeductionAnswers(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilsApi.getDeductionAnswers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets information about form filing deadlines for this year and all previous
         * @summary Gets information about form filing deadlines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeductions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<V1DeductionAnswer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeductions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilsApi.getDeductions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets info about products we offer for the user to purchase
         * @summary Gets info about products we offer for the user to purchase
         * @param {string} [coupon] Coupon conde to apply to the products prices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsShort(coupon?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UtilsProductShort>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsShort(coupon, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilsApi.getProductsShort']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets information about all possible tax preparers
         * @summary Gets information about tax preparers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaxPreparers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UtilsTaxPreparer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaxPreparers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilsApi.getTaxPreparers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes preconfigured users that have social auth
         * @summary Removes preconfigured users that have social auth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetTestSocial(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetTestSocial(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilsApi.resetTestSocial']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stored user\'s responses on deductions questions
         * @summary Stores user\'s responses on deductions questions
         * @param {UtilsDeductionAnswers} answers User deduction answers
         * @param {string} [email] email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeDeductions(answers: UtilsDeductionAnswers, email?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsDeductionAnswers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeDeductions(answers, email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilsApi.storeDeductions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {UploadContactUsFileRequest} uploadContactUsFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadContactUsFile(uploadContactUsFileRequest: UploadContactUsFileRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsContactUsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadContactUsFile(uploadContactUsFileRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilsApi.uploadContactUsFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilsApi - factory interface
 * @export
 */
export const UtilsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilsApiFp(configuration)
    return {
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {UtilsContactUsRequest} data Creates an intent to change user\&#39;s email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        anonymousContactUs(data: UtilsContactUsRequest, options?: RawAxiosRequestConfig): AxiosPromise<UtilsContactUsResponse> {
            return localVarFp.anonymousContactUs(data, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets information about form filing deadlines for this year and all previous
         * @summary Gets information about form filing deadlines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourses(options?: RawAxiosRequestConfig): AxiosPromise<Array<UtilsCourse>> {
            return localVarFp.getCourses(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets information about form filing deadlines for this year and all previous
         * @summary Gets information about form filing deadlines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeadlines(options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: DtoDeadlineResponse; }> {
            return localVarFp.getDeadlines(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets users deduction answers
         * @summary Gets users deduction answers
         * @param {string} [email] email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeductionAnswers(email?: string, options?: RawAxiosRequestConfig): AxiosPromise<UtilsDeductionAnswers> {
            return localVarFp.getDeductionAnswers(email, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets information about form filing deadlines for this year and all previous
         * @summary Gets information about form filing deadlines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeductions(options?: RawAxiosRequestConfig): AxiosPromise<Array<V1DeductionAnswer>> {
            return localVarFp.getDeductions(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets info about products we offer for the user to purchase
         * @summary Gets info about products we offer for the user to purchase
         * @param {string} [coupon] Coupon conde to apply to the products prices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsShort(coupon?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<UtilsProductShort>> {
            return localVarFp.getProductsShort(coupon, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets information about all possible tax preparers
         * @summary Gets information about tax preparers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxPreparers(options?: RawAxiosRequestConfig): AxiosPromise<Array<UtilsTaxPreparer>> {
            return localVarFp.getTaxPreparers(options).then((request) => request(axios, basePath));
        },
        /**
         * Removes preconfigured users that have social auth
         * @summary Removes preconfigured users that have social auth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetTestSocial(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.resetTestSocial(options).then((request) => request(axios, basePath));
        },
        /**
         * Stored user\'s responses on deductions questions
         * @summary Stores user\'s responses on deductions questions
         * @param {UtilsDeductionAnswers} answers User deduction answers
         * @param {string} [email] email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeDeductions(answers: UtilsDeductionAnswers, email?: string, options?: RawAxiosRequestConfig): AxiosPromise<UtilsDeductionAnswers> {
            return localVarFp.storeDeductions(answers, email, options).then((request) => request(axios, basePath));
        },
        /**
         * user submits request to change email. He should confirm it by email by clicking the link
         * @summary User submits request to change email
         * @param {UploadContactUsFileRequest} uploadContactUsFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadContactUsFile(uploadContactUsFileRequest: UploadContactUsFileRequest, options?: RawAxiosRequestConfig): AxiosPromise<UtilsContactUsResponse> {
            return localVarFp.uploadContactUsFile(uploadContactUsFileRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilsApi - object-oriented interface
 * @export
 * @class UtilsApi
 * @extends {BaseAPI}
 */
export class UtilsApi extends BaseAPI {
    /**
     * user submits request to change email. He should confirm it by email by clicking the link
     * @summary User submits request to change email
     * @param {UtilsContactUsRequest} data Creates an intent to change user\&#39;s email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApi
     */
    public anonymousContactUs(data: UtilsContactUsRequest, options?: RawAxiosRequestConfig) {
        return UtilsApiFp(this.configuration).anonymousContactUs(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets information about form filing deadlines for this year and all previous
     * @summary Gets information about form filing deadlines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApi
     */
    public getCourses(options?: RawAxiosRequestConfig) {
        return UtilsApiFp(this.configuration).getCourses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets information about form filing deadlines for this year and all previous
     * @summary Gets information about form filing deadlines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApi
     */
    public getDeadlines(options?: RawAxiosRequestConfig) {
        return UtilsApiFp(this.configuration).getDeadlines(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets users deduction answers
     * @summary Gets users deduction answers
     * @param {string} [email] email of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApi
     */
    public getDeductionAnswers(email?: string, options?: RawAxiosRequestConfig) {
        return UtilsApiFp(this.configuration).getDeductionAnswers(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets information about form filing deadlines for this year and all previous
     * @summary Gets information about form filing deadlines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApi
     */
    public getDeductions(options?: RawAxiosRequestConfig) {
        return UtilsApiFp(this.configuration).getDeductions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets info about products we offer for the user to purchase
     * @summary Gets info about products we offer for the user to purchase
     * @param {string} [coupon] Coupon conde to apply to the products prices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApi
     */
    public getProductsShort(coupon?: string, options?: RawAxiosRequestConfig) {
        return UtilsApiFp(this.configuration).getProductsShort(coupon, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets information about all possible tax preparers
     * @summary Gets information about tax preparers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApi
     */
    public getTaxPreparers(options?: RawAxiosRequestConfig) {
        return UtilsApiFp(this.configuration).getTaxPreparers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes preconfigured users that have social auth
     * @summary Removes preconfigured users that have social auth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApi
     */
    public resetTestSocial(options?: RawAxiosRequestConfig) {
        return UtilsApiFp(this.configuration).resetTestSocial(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stored user\'s responses on deductions questions
     * @summary Stores user\'s responses on deductions questions
     * @param {UtilsDeductionAnswers} answers User deduction answers
     * @param {string} [email] email of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApi
     */
    public storeDeductions(answers: UtilsDeductionAnswers, email?: string, options?: RawAxiosRequestConfig) {
        return UtilsApiFp(this.configuration).storeDeductions(answers, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * user submits request to change email. He should confirm it by email by clicking the link
     * @summary User submits request to change email
     * @param {UploadContactUsFileRequest} uploadContactUsFileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApi
     */
    public uploadContactUsFile(uploadContactUsFileRequest: UploadContactUsFileRequest, options?: RawAxiosRequestConfig) {
        return UtilsApiFp(this.configuration).uploadContactUsFile(uploadContactUsFileRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VaultApi - axios parameter creator
 * @export
 */
export const VaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets Resource Vault sections
         * @summary Gets Resource Vault sections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceVaultRecords: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vault/records`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets Resource Vault sections
         * @summary Gets Resource Vault sections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceVaultSections: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vault/sections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Implicit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Implicit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VaultApi - functional programming interface
 * @export
 */
export const VaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets Resource Vault sections
         * @summary Gets Resource Vault sections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResourceVaultRecords(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResourcesResourceVaultRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResourceVaultRecords(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VaultApi.getResourceVaultRecords']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets Resource Vault sections
         * @summary Gets Resource Vault sections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResourceVaultSections(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResourcesResourceVaultSection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResourceVaultSections(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VaultApi.getResourceVaultSections']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VaultApi - factory interface
 * @export
 */
export const VaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VaultApiFp(configuration)
    return {
        /**
         * Gets Resource Vault sections
         * @summary Gets Resource Vault sections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceVaultRecords(options?: RawAxiosRequestConfig): AxiosPromise<Array<ResourcesResourceVaultRecord>> {
            return localVarFp.getResourceVaultRecords(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets Resource Vault sections
         * @summary Gets Resource Vault sections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceVaultSections(options?: RawAxiosRequestConfig): AxiosPromise<Array<ResourcesResourceVaultSection>> {
            return localVarFp.getResourceVaultSections(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VaultApi - object-oriented interface
 * @export
 * @class VaultApi
 * @extends {BaseAPI}
 */
export class VaultApi extends BaseAPI {
    /**
     * Gets Resource Vault sections
     * @summary Gets Resource Vault sections
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VaultApi
     */
    public getResourceVaultRecords(options?: RawAxiosRequestConfig) {
        return VaultApiFp(this.configuration).getResourceVaultRecords(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets Resource Vault sections
     * @summary Gets Resource Vault sections
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VaultApi
     */
    public getResourceVaultSections(options?: RawAxiosRequestConfig) {
        return VaultApiFp(this.configuration).getResourceVaultSections(options).then((request) => request(this.axios, this.basePath));
    }
}



