import { BRAND } from "@/config/constants";
import { FLOW, ROUTE_NAMES } from "@/util/routes/route-names";

export const TAX_SPECIFIC_ROUTES = [
  {
    path: "/",
    name: ROUTE_NAMES.landing,
    component: () => import("@/views/landing.vue"),
    meta: {
      title: `${BRAND} - Landing`,
      layout: "full"
    }
  },
  {
    path: "/legal/:page",
    name: ROUTE_NAMES.legal,
    component: () => import("@/views/legal.vue"),
    meta: {
      title: `${BRAND} - Legal`
    }
  },
  {
    path: "/contact-us",
    name: ROUTE_NAMES.contactUs,
    component: () => import("@/views/contact-us.vue"),
    meta: {
      title: `${BRAND} - Contact Us`
    }
  },
  {
    path: "/tax-return-preparation/do-it-yourself",
    name: ROUTE_NAMES.doItYourself,
    component: () => import("@/views/diy/landing.vue"),
    meta: {
      title: `${BRAND} - Do it yourself`,
      flow: FLOW.diyReturn,
      layout: "full"
    }
  },
  {
    path: "/tax-return-preparation/done-for-you",
    name: ROUTE_NAMES.doneForYou,
    component: () => import("@/views/dfy/landing.vue"),
    meta: {
      title: `${BRAND} - Done for you`,
      layout: "full",
      container: "big",
      flow: FLOW.dfyReturn
    }
  },
  {
    path: "/tax-return-preparation/done-for-you-v2",
    name: ROUTE_NAMES.doneForYouV2,
    component: () => import("@/views/dfy/landing-v2.vue"),
    meta: {
      title: `${BRAND} - Done for you`,
      flow: FLOW.dfyReturn,
      layout: "full"
    }
  },
  {
    path: "/tax-return-preparation/5-common-mistakes",
    name: ROUTE_NAMES.commonMistakes,
    component: () => import("@/views/common-mistakes.vue"),
    meta: {
      title: `${BRAND} - Don't make these common mistakes when filing your Tax Return`,
      flow: FLOW.diyReturn,
      layout: "full"
    }
  },
  {
    path: "/tax-extension-personal",
    name: ROUTE_NAMES.taxExtensionPersonal,
    component: () => import("@/views/landing.vue"),
    meta: {
      title: `${BRAND} - Landing`,
      layout: "full"
    }
  },
  {
    path: "/faq",
    name: ROUTE_NAMES.faq,
    component: () => import("@/views/faq.vue"),
    meta: {
      title: `${BRAND} - FAQs`
    }
  }
];
