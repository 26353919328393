import { FYTAPI } from "@/api";
import type {
  FytLoginRequest,
  FytRegistrationRequest,
  FytSecondFactorSelectRequest,
  FytSecondFactorVerifyRequest
} from "@/services/api";
import { getLogger } from "@/util/logger";

const log = getLogger("API: FYT");

export const fytAccCreate = async (request: FytRegistrationRequest) => {
  log.time("Creating FYT Account");

  const { data } = await FYTAPI.fytAccCreate(request);

  log.timeEnd("Creating FYT Account");

  return data;
};

export const fytAccExists = async () => {
  log.time("Checking if FYT Account exist");

  const { data } = await FYTAPI.fytAccExists();

  log.timeEnd("Checking if FYT Account exist");

  return data;
};

export const fytFactorSelect = async (request: FytSecondFactorSelectRequest) => {
  log.time("Selecting second FYT factors");

  const { data } = await FYTAPI.fytFactorSelect(request);

  log.timeEnd("Selecting second FYT factors");

  return data;
};

export const fytFactorVerify = async (request: FytSecondFactorVerifyRequest) => {
  log.time("Selecting second FYT factors");

  const { data } = await FYTAPI.fytFactorVerify(request);

  log.timeEnd("Selecting second FYT factors");

  return data;
};

export const fytLogin = async (request: FytLoginRequest) => {
  log.time("Login into FYT account");

  const { data } = await FYTAPI.fytLogin(request);

  log.timeEnd("Login into FYT account");

  return data;
};
