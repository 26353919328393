<template>
  <b-modal
    ref="couponModal"
    v-model="modalState"
    size="md"
    :no-footer="hideFooter"
    :no-close-on-backdrop="false"
    @hidden="onClose ? onClose() : cancelCallback()">
    <template v-if="title" #title
      ><div style="font-size: 110%">{{ title }}</div></template
    >
    <template #default>
      <div class="text-center">
        <slot></slot>
      </div>
    </template>
    <template #footer>
      <div>
        <b-button variant="primary" @click="onSuccess"> Continue </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CouponModal",
  props: {
    discount: {
      type: String,
      default: ""
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    cancelButtonText: {
      type: String,
      default: "Cancel"
    },
    onCancel: {
      type: Function
    },
    okButtonText: {
      type: String,
      default: "Ok"
    },
    onSuccess: {
      type: Function as PropType<() => unknown>,
      default: null
    },
    onClose: {
      type: Function,
      default: () => void 0
    }
  },
  data() {
    return {
      modalState: this.isOpen
    };
  },
  watch: {
    isOpen(newVal: boolean) {
      this.modalState = newVal;
    }
  },
  methods: {
    cancelCallback() {
      if (this.onCancel) this.onCancel();
    },
    okCallback() {
      this.onSuccess();
    }
  }
});
</script>
