import type { NavigationGuardNext, RouteLocationNormalized, Router } from "vue-router";

import { useAppEngine } from "@/composables/use-app-engine";
import { cookieNames, TEN_MINUTES_IN_MS } from "@/config/constants";
import { isRouteConsent } from "@/router";
import { useAuthStore } from "@/store/auth.store";
import { useCartStore } from "@/store/cart.store";
import { useAppStateStore } from "@/store/state.store";
import { resetStores } from "@/store/utils";
import { getCookie } from "@/util/cookies";
import type { IdleTimeout } from "@/util/idle-timeout";
import idleTimeout from "@/util/idle-timeout";
import { getLogger } from "@/util/logger";
import { FLOW, ROUTE_NAMES } from "@/util/routes/route-names";

const log = getLogger("Session");
let idle: IdleTimeout | null = null;

export const isRefreshTokenUp = (): boolean => {
  const sesExpAtTimestamp = getCookie<number>(cookieNames.authTokenExp);
  log.info("Session expires in: ", sesExpAtTimestamp);

  if (sesExpAtTimestamp) {
    const dt = new Date(sesExpAtTimestamp);

    if (dt.getTime() > new Date().getTime()) {
      log.info("Refresh token should still be up");
      return true;
    }
  }

  log.info("No Refresh token is available");
  return false;
};

const recoverSession = async (): Promise<boolean> => {
  const authStore = useAuthStore();

  try {
    if (isRefreshTokenUp()) {
      log.info("Session should still be active, trying to refresh");

      await authStore.refreshToken();
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

const watchSession = async (router: Router) => {
  if (!router) return;

  log.info("Store init start...");

  const curRoute = router.currentRoute;
  const authStore = useAuthStore();
  const appStateStore = useAppStateStore();
  const isActiveSession: boolean = await recoverSession();

  if (curRoute.value.meta?.requiresAuth === true && !isActiveSession) {
    resetStores();

    if (curRoute.value.meta.flow === FLOW.dfyReturn) {
      router.replace({ name: ROUTE_NAMES.dfyAuthenticate });

      return;
    }

    if (curRoute.value.meta.flow === FLOW.diyReturn) {
      router.replace({ name: ROUTE_NAMES.diyAuthenticate });
      return;
    }

    router.replace({ name: ROUTE_NAMES.login });

    return;
  }

  idle = idleTimeout(
    () => {
      log.info("User is inactive for 10 minutes! Will show modal if required");
      authStore.idleTimeout();
    },
    {
      element: document as unknown as HTMLElement,
      timeout: TEN_MINUTES_IN_MS,
      loop: false
    }
  );

  window.setInterval(() => {
    if (idle && !idle.idle && isRefreshTokenUp()) {
      log.info("User still is not idle. Refreshing token...");

      try {
        authStore.refreshToken();
      } catch {
        log.info("Cannot refresh session");
      }
    }
  }, TEN_MINUTES_IN_MS);

  // If user makes tab active, silenly recover session if POSSIBLE
  // If not, just showing session expired screen
  // TODO: need separate IO <13 and Safari tests
  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      log.info("window is visiable now");

      if (idle) idle.reset();

      recoverSession().then((isSessionActive) => {
        if (isSessionActive) return;

        const pathname = window.location.pathname;
        const cartStore = useCartStore();
        const productsShort = [...cartStore.productsShort];

        resetStores();
        //Saving short products field since it is not user-specific

        cartStore.setProductsShort(productsShort);

        if (pathname !== "/session-expired" && router.currentRoute.value.meta?.requiresAuth === true) {
          router.replace({ name: ROUTE_NAMES.sessionExpired });
        }
      });
    }
  });

  router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const { name } = to;
    const { isFileDirectEngine } = useAppEngine();

    // Refresh page for filedirect if page does not exist to go to static page made in other repo
    if (isFileDirectEngine && !name) {
      window.location.href = to.fullPath;
    }

    if (!appStateStore.isStateLoaded) {
      appStateStore.initState();
    }

    const authStore = useAuthStore();

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      window.OneSignal = null;

      if (appStateStore.isStateLoaded && authStore.isAuthorized) {
        const scopes = authStore.tokenData?.scopes ?? [];

        if (scopes.includes("consent") && !isRouteConsent(from)) {
          return next({ path: "/registration/consent" });
        }
      }
    }

    if (!to.path.includes("personal")) {
      window.localStorage.removeItem("prev-funnel-page");
    }

    setPageMetadataForRoute(to);

    return next();
  });

  router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const authStore = useAuthStore();
    const appStateStore = useAppStateStore();

    if (from.query.purchases !== to.query.purchases && authStore.authToken) {
      appStateStore.getSettings();
    }
  });
};

export const initWatchSession = (router: Router) => {
  if (idle) return;

  router.isReady().then(() => {
    watchSession(router);
    setPageMetadataForRoute(router.currentRoute.value);
  });
};

export const setPageMetadataForRoute = (route: RouteLocationNormalized) => {
  const { title, description, canonical }: { title?: string; description?: string; canonical?: string } = route.meta;

  const standartTitle = import.meta.env.VITE_BRAND;
  const standartDescription = import.meta.env.VITE_META_DESCRIPTION;

  const descriptionElement = document.querySelector('head meta[name="description"]');
  const canonicalElement = document.querySelector('head link[rel="canonical"]');
  descriptionElement?.setAttribute("content", description ?? standartDescription);

  if (canonical) canonicalElement?.setAttribute("href", `${window.location.origin}/${canonical}`);
  document.title = title ?? standartTitle;
};
