import { TZDateMini } from "@date-fns/tz";
import type { Scope } from "@sentry/vue";
import * as Sentry from "@sentry/vue";

import { Engine, SupportHref } from "@/config/constants";
import type { ExtForm } from "@/store/types";
import { getQueryParameter } from "@/util/query-string";

export const parseCardHolderName = (fullName: string): { firstName: string; lastName: string } => {
  fullName = fullName.trim();
  const parts = fullName.split(/\s+/);
  if (parts.length === 0) {
    return { firstName: "", lastName: "" };
  } else if (parts.length === 1) {
    return { firstName: parts[0], lastName: "" };
  } else {
    // Consider everything after the first space as the last name
    const firstName = parts[0];
    const lastName = parts.slice(1).join(" ");
    return { firstName, lastName };
  }
};

export const getEngineSource = (): string => {
  return window.location.hostname.replace("www.", "");
};

export const getSiteBrandFromConfig = (): string => {
  const brand = import.meta.env.VITE_BRAND;

  if (!brand) return getSiteHost();

  return brand.toLowerCase();
};

export const getEngineFromConfig = (): Engine => {
  const brand = import.meta.env.VITE_ENGINE || Engine.FileSmart;

  return brand.toLowerCase() as Engine;
};

export const getCurrentEngine = (): Engine => {
  const engine = getEngineFromConfig();

  if (Object.values(Engine).includes(engine)) {
    return engine;
  }

  return Engine.FileSmart;
};

export const isLoginRedirectNeeded = (): boolean => {
  return (
    getEngineFromConfig() === Engine.FileSmart &&
    !["d.file-tax.net", "d.filesmart.tax", "localhost:8080"].includes(window.location.host)
  );
};

export const getBrandFromConfig = (): string => {
  return getEngineFromConfig() === Engine.FileSmart ? "FileSmart" : "e.file-tax.net";
};

export const getSupportHrefFromConfig = (): SupportHref => {
  if (import.meta.env.MODE !== "production" || ["d.file-tax.net", "d.filesmart.tax"].includes(window.location.host)) {
    return SupportHref.Stage;
  }

  const brand = getEngineFromConfig() === Engine.FileSmart ? "FileSmart" : "FileTax";

  return SupportHref[brand];
};

export const getHelpdeskURL = (): string => {
  return import.meta.env.VITE_HELPDESK_URL;
};

export const getSiteHost = (hostName?: string): string => {
  const host = hostName ?? window.location.host;

  switch (host) {
    case "filedirect.net":
      return "filedirect.net";
    case "extendtaxreturn.com":
      return "extendtaxreturn.com";
    case "extendirsreturn.com":
      return "extendirsreturn.com";
    case "irs-extension.online":
      return "irs-extension.online";
    case "irs-extension.net":
      return "irs-extension.net";
    case "irs-extension.com":
      return "irs-extension.com";
    case "filesmart.tax":
      return "filesmart.tax";
    case "start.efiletax.online":
    case "efiletax.online":
      return "efiletax.online";
    case "irsextension.online":
    case "test.irsextension.online":
      return "irsextension.online";
    case "stage.file-tax.net":
      return "stage.file-tax.net";
    case "e.filesmart.tax":
    case "d.filesmart.tax":
      return "e.filesmart.tax";
    case "e.file-tax.net":
    case "localhost":
    case "d.file-tax.net":
    default:
      return "e.file-tax.net";
  }
};

export const openWindowForPDFDownload = (url: string): Window | null => {
  const newWindow = window.open(url, "_blank");
  return newWindow;
};

export enum Semaphore {
  No = 1,
  Loading,
  Yes,
  Failure
}

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const badSymbols: string[] = "([{.,-@#$\"|%^'&*!;`~:}])".split("");
const addressAbreviatons = Object.freeze({
  boulevard: "blvd",
  circle: "cir",
  avenue: "ave",
  lane: "ln",
  drive: "dr",
  street: "st",
  road: "rd",
  route: "rte",
  court: "ct",
  apartment: "apt",
  "apt.": "apt",
  north: "N",
  south: "S"
});

export const CleanAddress = (address: string) => {
  if (!address || address === "") {
    return address;
  }

  //Removing bad symbols
  badSymbols.forEach((s) => {
    address = address.replace(new RegExp("\\" + s, "g"), "");
  });

  //replacing long spaces with just one
  address = address.replace(/\s+/g, " ");

  //Removing trailing spaces
  address = address.trim();

  //Replacing abreviations
  if (address.length >= 35) {
    Object.keys(addressAbreviatons).forEach((key: string) => {
      address = address.replace(new RegExp(key, "g"), addressAbreviatons[key]);
    });
  }

  return address;
};

export const ProcessQueryStringParams = () => {
  const trackingId = getQueryParameter("trackingId");
  //const logosEnabled = getQueryParameter('l');
  const source = getQueryParameter("source");
  //const color = getQueryParameter('c');
  const time = getQueryParameter("t");

  if (trackingId && trackingId !== "") {
    //authStore.setTrackingId(trackingId);
  }
  if (source && source !== "") {
    //authStore.setSource(source);
  }
  if (time && +time === 18) {
    //appStateStore.setTime(true);
  }
};

export const captureErrorWithContext = (error: Error, contextData: Record<string, any>): void => {
  Sentry.withScope((scope: Scope) => {
    Object.entries(contextData).forEach(([key, value]) => {
      scope.setExtra(key, value);
    });
    Sentry.captureException(error);
  });
};
function GetNameLineImpl(first1: string, last1: string, first2: string, last2: string, jointly: boolean): string {
  if (jointly) {
    if (last2 === last1) {
      return `${first1} & ${first2}<${last1}`;
    }
    return `${first1}<${last1}<& ${first2} ${last2}`;
  }
  const value = first1.trim() + "<" + last1.trim();
  return value;
}

function TrimSimbols(str: string) {
  let v = str
    .toUpperCase()
    .replace(".", "")
    .replace("2ND", "")
    .replace("3RD", "")
    .replace("4TH", "")
    .replace("5TH", "")
    .replace("6TH", "")
    .replace("3RD", "")
    .replace(",", "")
    .replace('"', "")
    .replace("^", "")
    .replace("~", "")
    .replace("@", "")
    .replace("#", "")
    .replace("$", "")
    .replace("&", "")
    .replace("*", "")
    .replace("(", "")
    .replace(")", "")
    .trim();

  v = v.replace(/[^\u0000-\u007F]+/gi, "");
  v = v.replace(/\(.+\)/gi, "");
  v = v.replace(/[ ]+/, " ");
  return v.trim();
}

export const GetNameLine = (f1: string, l1: string, f2?: string, l2?: string, jointly: boolean = false) => {
  let first1 = TrimSimbols(f1);
  let last1 = TrimSimbols(l1);
  let first2 = "";
  let last2 = "";
  if (jointly && l2 && f2) {
    first2 = TrimSimbols(f2);
    last2 = TrimSimbols(l2);
  }

  let nameline = GetNameLineImpl(first1, last1, first2, last2, jointly);
  while (nameline.length > 35) {
    // rule a
    if (first2.includes(" ") && first2.split(" ")[1].length > 1) {
      first2 = first2.split(" ")[0] + " " + first2.split(" ")[1][0].toString().toUpperCase();
      nameline = GetNameLineImpl(first1, last1, first2, last2, jointly);
      continue;
    }

    // rule a
    if (first1.includes(" ") && first1.split(" ")[1].length > 1) {
      first1 = first1.split(" ")[0] + " " + first1.split(" ")[1][0].toString().toUpperCase();
      nameline = GetNameLineImpl(first1, last1, first2, last2, jointly);
      continue;
    }

    //rule b
    if (first2.includes(" ")) {
      first2 = first2.split(" ")[0];
      nameline = GetNameLineImpl(first1, last1, first2, last2, jointly);
      continue;
    }

    //rule c
    if (first1.includes(" ")) {
      first1 = first1.split(" ")[0];
      nameline = GetNameLineImpl(first1, last1, first2, last2, jointly);
      continue;
    }

    //rule d
    if (first2.length > 1) {
      first2 = first2[0].toString().toUpperCase();
      nameline = GetNameLineImpl(first1, last1, first2, last2, jointly);
      continue;
    }

    //rule e
    if (first1.length > 1) {
      first1 = first1[0].toString().toUpperCase();
      nameline = GetNameLineImpl(first1, last1, first2, last2, jointly);
      continue;
    }

    if (last2.length > 4) {
      last2 = last2.substring(0, last2.length - 1);
      nameline = GetNameLineImpl(first1, last1, first2, last2, jointly);
      continue;
    }

    if (last1.length > 4) {
      last1 = last1.substring(0, last1.length - 1);
      nameline = GetNameLineImpl(first1, last1, first2, last2, jointly);
      continue;
    }

    return nameline;
  }
  return nameline;
};

export function isMilitary(city: string, state: string) {
  const militaryStates = "AA;AE;AP";
  const militaryCities = "APO;DPO;FPO";

  if (city && militaryCities.includes(city.toUpperCase())) {
    return true;
  }

  if (state && militaryStates.includes(state.toUpperCase())) {
    return true;
  }

  return false;
}

export const isValidMilitary = (city: string, state: string, zip: string, dbCity: string, dbState: string): boolean => {
  if (dbCity && dbState) {
    if (city.toLowerCase() === dbCity.toLowerCase() && state.toLowerCase() === dbState.toLowerCase()) {
      return true;
    }
  }

  return false;
};

export const findDefaultForm = (forms: ExtForm[]): ExtForm | undefined => {
  let form: ExtForm | undefined;

  forms.sort((a: ExtForm, b: ExtForm): number => {
    return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
  });

  form = forms.find((el: ExtForm) => {
    return el.is_form_complete && !el.is_payed;
  });

  if (form) {
    return form;
  }

  form = forms.find((el: ExtForm) => {
    return !el.is_form_complete;
  });

  if (form) {
    return form;
  }

  form = forms.find((el: ExtForm) => {
    return el.id;
  });

  if (form) {
    return form;
  }
};

export const nullToEmptyString = (v: string | null | undefined): string => {
  if (!v) {
    return "";
  }

  if (v && v !== "" && v !== undefined && v !== "undefined") {
    return v.toString();
  }

  return "";
};

export const isJwtToken = (token: string): boolean => {
  if (!token || typeof token !== "string") {
    return false;
  }

  const tokenParts = token.split(".");
  if (tokenParts.length !== 3) {
    return false;
  }

  try {
    const base64Url = tokenParts[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const payload = JSON.parse(jsonPayload);
    return !!payload;
  } catch {
    return false;
  }
};

export const sanitizeUrl = (url: string): string | null => {
  const allowedDomains = ["filesmart.tax", "e.filesmart.tax", "localhost", "e.file-tax.net"];
  try {
    const originalUrl = new URL(url, window.location.origin);

    if (
      (!originalUrl.hostname || allowedDomains.includes(originalUrl.hostname)) &&
      originalUrl.protocol === window.location.protocol
    ) {
      return originalUrl.pathname + originalUrl.search + originalUrl.hash;
    } else if (originalUrl.protocol === "http:" || originalUrl.protocol === "https:") {
      return originalUrl.pathname + originalUrl.search + originalUrl.hash;
    } else {
      return null;
    }
  } catch {
    return null;
  }
};

export const addSearchParamToUrl = (rawRedirectUrl: string, param: string, value: string) => {
  try {
    const uri = new URL(rawRedirectUrl, window.location.origin);
    const searchParams = new URLSearchParams(uri.search);
    searchParams.set(param, value);
    uri.search = searchParams.toString();
    return uri.toString();
  } catch (e) {
    console.error("failed to add search param to url", rawRedirectUrl, param, value, e);
    throw e;
  }
};

export const encodeSearchParam = (param: string, value: string) => {
  try {
    const searchParams = new URLSearchParams();
    searchParams.set(param, value);
    return searchParams.toString();
  } catch (e) {
    console.error("failed to add search param to url", param, value, e);
    throw e;
  }
};

export const getSecondLevelDomain = (): string | never => {
  const defaultSecondLevelDomain = "file-tax.net";

  try {
    const url = new URL(`https://${import.meta.env.VITE_BRAND.toLowerCase()}`);
    const hostParts = url.hostname.split(".");
    if (hostParts.length === 1) {
      throw new Error();
    }
    return hostParts.slice(-2).join(".");
  } catch (e) {
    Sentry.captureMessage(
      `Error getting VITE_BRAND from env. It must be a correct url. ${e instanceof Error ? e.message : String(e)}`
    );
    return defaultSecondLevelDomain;
  }
};

export const isSafari = (): boolean => {
  return typeof window !== "undefined" && "WebkitAppearance" in document.documentElement.style && !("chrome" in window);
};

export const getLocalDateFromString = (date?: string): Date => {
  const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = new TZDateMini(date ? new Date(date) : new Date(), timeZone);

  return zonedDate;
};
